/**
 * Constants class for application wide constants
 */

import moment from 'moment';

// correlation header added as UUID value, to track the request lifecycle from UI to response
export const CORRELATION_ID_HEADER = 'X-Correlation-Id';
// The Encryption key used for AES encryption
export const APIKEY = 'apikey';
// The IV value used for AES encryption in request payload
export const IV = 'iv';
// key used to encrypt keys on local
export const LOCALENCRYPTIONKEY = '0ic5KgpZtI';
export const EK = 'ek';
export const DEFAULT_ASYNC_STATE = {
  isPending: false,
  isFulfilled: false,
  isRejected: false,
};

export const getTimeZoneForUser = () => {
  let name = localStorage.getItem('userTimeZone')?.split('-')[0] || '';
  let result: any = name
    ? LOCALIZATION_TIMEZONES.filter((option: any) =>
      option.name.toLowerCase().startsWith(name?.trim().toLowerCase())
    )
    : {};
  let response = DEFAULT_USER_TIMEZONE;
  if (result && result[0]) {
    response = result[0].value;
  }
  return response;
};

export const getUserTimezoneDate = (date?: string, format?: string) => {
  return date ? moment.utc(date).format(format || DATETIME_FORMAT) : '';
};

export const AZURE_IMAGE_API =
  'https://webcontentaccount.blob.core.windows.net/websitestaticcontents';

export const DEFAULT_USER_TIMEZONE = 'US/Central';

export const LOCALIZATION_TIMEZONES = [
  { name: 'Pacific Time', value: 'US/Pacific' },
  { name: 'Arizona Time', value: 'US/Arizona' },
  { name: 'Mountain Time', value: 'US/Mountain' },
  { name: 'Central Time', value: 'US/Central' },
  { name: 'Eastern Time', value: 'US/Eastern' },
  { name: 'Hawaii Time', value: 'US/Hawaii' },
  { name: 'Alaska Time', value: 'US/Alaska' },
];

export const TRANSACTIONS = [
  { key: 'Sale', value: 'Sale' },
  { key: 'Auth', value: 'Authorize' },
  { key: 'Refund', value: 'Refund' },
  { key: 'Capture', value: 'Capture' },
  { key: 'Void', value: 'Void' },
  { key: 'Pay', value: 'Pay' },
  { key: 'Take', value: 'Take' },
];

export const CARD_NETWORKS = [
  { key: 'Visa', value: 'Visa' },
  { key: 'Discover', value: 'Discover' },
  { key: 'Amex', value: 'AMEX' },
  { key: 'MasterCard', value: 'MasterCard' },
  { key: 'Diners', value: 'Diners Club' },
  { key: 'JCB', value: 'JCB' },
  { key: 'ChinaUnionPay', value: 'China Union Pay(CUP)' },
];

export const CREATED_STATUS = [
  { key: 'Approved', value: 'Approved' },
  { key: 'Declined', value: 'Declined' },
];

export const DATE_RANGES = [
  { key: '1', value: 'Last 30 days' },
  { key: '2', value: 'Last 7 days' },
  { key: '3', value: 'Last Month' },
  { key: '4', value: 'Last 3 months' },
  { key: '5', value: 'This Year' },
  // { key: '6', value: 'Select Date Period' },
];

export const VT_DEFAULT_DATA = {
  money: {
    value: '',
    currency: {
      name: 'usd',
      label: 'USD',
      symbol: '$',
      decimalSeparator: '.',
      thousandSeparator: ',',
    },
  },
  originalAmount: '',
  paymentInfo: {
    transactionType: '',
    paymentMethod: '',
    cardInfo: { number: '', expDate: '', cvv: '', trackData: '' },
    bankAccount: { accountNumber: '', routingNumber: '' },
    transactionId: '',
  },
  billingInfo: {
    billingEmail: '',
    billingPhone: '+1',
    billingFullName: '',
    billingAddress1: '',
    billingAddress2: '',
    billingCountry: 'United States',
    billingState: '',
    billingZipCode: '',
    billingCity: '',
  },
  shippingInfo: {
    shippingAddress1: '',
    shippingAddress2: '',
    shippingCountry: 'United States',
    shippingEmail: '',
    shippingZipCode: '',
    shippingState: '',
    shippingPhone: '+1',
    shippingFullName: '',
    shippingCity: '',
  },
};

export const CHECK_TRANSACTION_TYPES = [
  { key: 'Debit', value: 'debit' },
  { key: 'Cedit', value: 'credit' },
];
export const US_STATES: { [key: string]: string } = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const TERMINAL_TRANSACTIONS = [
  { key: 'Sale', value: 'Sale' },
  { key: 'Auth', value: 'Authorize' },
  { key: 'Refund', value: 'Refund' },
  { key: 'Capture', value: 'Capture' },
];

export const CHILD_TRANSACTIONS_TYPES = ['capture', 'refund', 'void'];

export const CountryOptions = [
  { key: 'United States', value: 'United States' },
];

export const CASE_CURRENT_MONTH = 1;
export const CASE_LAST_WEEK = 2;
export const CASE_PREVIOUS_MONTH = 3;
export const CASE_QUARTER = 4;
export const CASE_YEAR = 5;

export const DATETIME_FORMAT = 'MM/DD/YYYY hh:mm:ss A';

export const DATE1_FORMAT = 'MM/DD/YYYY';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const TIME_FORMAT = 'hh:mm:ss A';

export const CUSTOM_PERIOD_KEY = 6;

export enum TRANSACTION_STATUSES {
  Initiated = 'initiated',
  Declined = 'declined',
  Settled = 'settled',
  Refunded = 'refunded',
  Captured = 'captured',
}

export enum TRANSACTION_TYPES {
  Sale = 'sale',
  Auth = 'auth',
  Capture = 'capture',
  Refund = 'refund',
  Void = 'void',
  Pay = 'pay',
  Take = 'take',
}

export const IS_LOCKED = 'is locked';

export const STATIC_TIMEZONES = [
  {
    name: `Pacific Time - UTC-7`,
    value: `Pacific Time - UTC-7`,
  },
  {
    name: `Arizona Time - UTC-7`,
    value: `Arizona Time - UTC-7`,
  },
  {
    name: `Mountain Time - UTC-6`,
    value: `Mountain Time - UTC-6`,
  },
  {
    name: `Central Time - UTC-5`,
    value: `Central Time - UTC-5`,
  },
  {
    name: `Eastern Time - UTC-4`,
    value: `Eastern Time - UTC-4`,
  },
  {
    name: `Hawaii Time - UTC-10`,
    value: `Hawaii Time - UTC-10`,
  },
  {
    name: `Alaska Time - UTC-8`,
    value: `Alaska Time - UTC-8`,
  },
];

export const LOCALIZATION_TIMEZONES_ABBREVIATION = [
  { name: 'Pacific Time', value: 'PST' },
  { name: 'Arizona Time', value: 'MST' },
  { name: 'Mountain Time', value: 'MT' },
  { name: 'Central Time', value: 'CST' },
  { name: 'Eastern Time', value: 'EST' },
  { name: 'Hawaii Time', value: 'HST' },
  { name: 'Alaska Time', value: 'AKST' },
];

export const getTimeZoneAbbreviation = () => {
  let name = localStorage.getItem('userTimeZone')?.split('-')[0] || '';
  let result: any = name
    ? LOCALIZATION_TIMEZONES_ABBREVIATION.filter((option) =>
      option.name.toLowerCase().startsWith(name?.trim().toLowerCase())
    )
    : {};
  if (result && result[0]) {
    return result[0].value;
  }
};

export const getHostSettlementTimeOptions = () => {
  return [
    {
      value: '22:00',
      key: getUserTimezoneDate('0000-01-01 10:00 PM ', 'hh:mm A'),
    },
    {
      key: getUserTimezoneDate('0000-01-01 11:00 PM ', 'hh:mm A'),
      value: '23:00',
    },
    {
      key: getUserTimezoneDate('0000-01-01 12:00 AM ', 'hh:mm A'),
      value: '00:00',
    },
    {
      key: getUserTimezoneDate('0000-01-01 1:00 AM ', 'hh:mm A'),
      value: '01:00',
    },
    {
      key: getUserTimezoneDate('0000-01-01 2:00 AM ', 'hh:mm A'),
      value: '02:00',
    },
    {
      key: getUserTimezoneDate('0000-01-01 3:00 AM ', 'hh:mm A'),
      value: '03:00',
    },
    {
      key: getUserTimezoneDate('0000-01-01 4:00 AM ', 'hh:mm A'),
      value: '04:00',
    },
    {
      key: getUserTimezoneDate('0000-01-01 5:00 AM ', 'hh:mm A') + ' ',
      value: '05:00',
    },
  ];
};
export const DEFAULT_SETTLEMENT_TIME = {
  value: getUserTimezoneDate('0000-01-01 11:00 PM UTC', 'hh:mm A'),
  key: '23:00',
};

export const INDUSTRY_TYPE_OPTIONS = [
  { key: 1, value: 'Direct Marketing (D)' },
  { key: 2, value: 'Restaurant (F)' },
  { key: 3, value: 'Grocery Store (G)' },
  { key: 4, value: 'Hotel (H)' },
  { key: 5, value: 'Retail (R)' },
];

export const MERCHANT_TYPE_OPTIONS = [
  { key: 1, value: 'Card Not Present' },
  { key: 2, value: 'MOTO' },
  { key: 3, value: 'Restaurant' },
  { key: 4, value: 'Retail' },
];

export const OWNERSHIP_TYPE_OPTIONS = [
  { key: 1, value: 'Corporation' },
  { key: 2, value: 'Sole Proprietor' },
  { key: 3, value: 'LLC' },
  { key: 4, value: 'Non-Profit' },
  { key: 5, value: 'Government' },
  { key: 6, value: 'Partnership' },
  { key: 7, value: 'Other' },
];

export const NUMBER_OF_EMPLOYESS_OPTIONS = [
  { key: '0 -10', value: '0 -10' },
  { key: '11 – 50', value: '11 – 50' },
  { key: '51 – 100', value: '51 – 100' },
  { key: '101 - Over', value: '101 - Over' },
];

export const PRINCIPAL_OWERNER_TYPE_OPTIONS = [
  { key: 1, value: 'Chief Executive Officer (CEO)' },
  { key: 2, value: 'Chief Operating Officer (COO)' },
  { key: 3, value: 'Chief Financial Officer (CFO)' },
  { key: 4, value: 'Manager' },
  { key: 5, value: 'Owner' },
  { key: 6, value: 'President' },
  { key: 7, value: 'Vice-President' },
];

/**
 * Default user activity timeout - 10 minutes
 */
export const ACTIVITY_TIMEOUT = 510;

/**
 * Logout user automatically in - 90 seconds
 */
export const ACTIVITY_AUTO_LOGOUT = 90;

export const VGS_HOST_DESCRIPTION =
  'VGS (verygoodsecurity) replaces sensitive data with non-sensitive "tokens" reducing your liability and making it easier to achieve PCI compliance.';
export const TSYS_HOST_DESCRIPTION =
  'TSYS (Total System Services, Inc.) provides payment processing services, merchant services and related payment services.';
export const FISERV_HOST_DESCRIPTION =
  'Fiserv provides credit card processing services for payment convenience to your cardholders through comprehensive transaction processing and settlement services.';
export const SHAZAM_HOST_DESCRIPTION =
  'Shazam provides debit processing services allowing institutions to meet cardholder demands.';

export const AMEX_OPT_BLUE = [
  {
    key: 'True',
    value: true,
  },
  {
    key: 'False',
    value: false,
  },
];

export const TSYS_INDUSTRY_TYPE_OPTIONS = [
  { value: 'F', key: 'Restaurant (F)' },
  { value: 'R', key: 'Retail (R)' },
  { value: 'H', key: 'Hotel-Lodging (H)' },
];

export const HOST_COUNTRY_OPTIONS = [{ key: 'United States', value: '840' }];

export const OFFSET_TRANSACTION_ENABLED = [
  {
    key: 'Yes',
    value: 'Yes',
  },
  {
    key: 'No',
    value: 'No',
  },
];


export const PERMISSION_ROLES : any [] = [{ id: 1, name: "System Admin", description: 'System Admin oversees running and maintaining the processing platform.' },
{ id: 2, name: "Mrechant", description: 'Merchant is the owner of the business and processing account.' },
{ id: 3, name: "Support", description: 'Support answers the merchants requests and aids the processing platform.' },
{ id: 4, name: "View Only", description: 'View Only can only see users, transactions, and merchants.' },
{ id: 5, name: "Agent", description: 'Agent can create merchant applications and support their clients.' }];
