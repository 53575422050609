/**
 * Reducer class for Authorisation
 */
import { Client } from '../../../types/api';
import { ReducerAction, ReducerState } from '../../../types/common';

import {
  AUTH_LOGIN_ACTION,
  AUTH_LOGOUT_ACTION,
  AUTH_SET_USER_CLIENT_ACTION,
  AUTH_GET_CODE,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_SEND_VERIFICATION,
  AUTH_VERIFY_CODE,
  AUTH_UPDATE_PASSWORD,
  AUTH_RESET_PASSWORD_UPDATE,
  TWO_FACTOR_STATUS,
  AUTH_SEND_VERIFICATION_OTP,
  AUTH_VERIFY_OTP_CODE,
  CLIENT_OBJECT_STORAGE,
} from '../../actions/types';

const passwordUpdateStatuses = {
  verificationSendSuccess: false,
  codeVerificationSuccess: false,
  passwordUpdateSuccess: false,
  reason: '',
  intermediaryToken: '',
  updatePasswordError: '',
};

const userPayloadString: string | null = localStorage.getItem('userPayload');
const userLoggedInString: string | null = localStorage.getItem('isLoggedIn');

const client: Client  = (() => {
  const rehydrate: string | null = localStorage.getItem(CLIENT_OBJECT_STORAGE);
  return rehydrate !== null && rehydrate !== '' ? JSON.parse(rehydrate) : {
    Crypto: { } };
})();

const isLoggedIn =
  client !== null && userLoggedInString !== null
    ? (JSON.parse(userLoggedInString as string) as boolean)
    : false;

let userPayload =
  userPayloadString && userPayloadString !== ''
    ? JSON.parse(userPayloadString)
    : {};

export const defaultState = {
  error: {},
  userData: userPayload && userPayload.userData ? userPayload.userData : {},
  isLoggedIn: isLoggedIn,
  is2faEnabled: false,
  crypto: client.Crypto,
  reason: '',
  verificationOTP: false,
  otp: '',
  email: '',
  phoneNumber: '',
  userId: '',
  clientId: client.Id,

  // UpdatePassword
  verificationSendSuccess: true,
  codeVerificationSuccess: true,
  passwordUpdateSuccess: false,
  intermediaryToken: '',
  updatePasswordError: '',
};

/**
 * Auth reducer
 */
const AuthReducer = (
  state: ReducerState = defaultState,
  action: ReducerAction
) => {
  const { type, payload } = action;
  switch (type) {
    case AUTH_LOGIN_ACTION:
      const { reason, token, crypto, userData, data = {}, ...rest } = payload;
      return {
        ...state,
        userData: userData,
        ...data,
        ...rest,
        crypto: crypto,
        reason: reason,
      };
    case AUTH_LOGOUT_ACTION:
      return {
        ...state,
        userData: {},
        isLoggedIn: false,
        reason: '',
        crypto: {},
        is2faEnabled: false,
      };
    case AUTH_SET_USER_CLIENT_ACTION:
      return {
        ...state,
        clientId: (payload as Client).Id,
        crypto: (payload as Client).Crypto,
      };
    case AUTH_GET_CODE:
      return { ...state, ...payload };
    case AUTH_SEND_VERIFICATION:
      return { ...state, ...payload };
    case AUTH_SEND_VERIFICATION_OTP:
      return { ...state, ...payload };
    case AUTH_VERIFY_CODE:
      return { ...state, ...payload };
    case AUTH_VERIFY_OTP_CODE:
      return { ...state, ...payload };
    case AUTH_UPDATE_PASSWORD:
      return { ...state, ...payload };
    case AUTH_RESET_PASSWORD_UPDATE:
      return {
        ...state,
        ...passwordUpdateStatuses,
      };
    case AUTH_ERROR:
      return { ...state, error: payload };
    case AUTH_SUCCESS:
      return { ...state, ...payload };
    case TWO_FACTOR_STATUS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default AuthReducer;
