/**
 * Validation schema for tsys host form
 * Validation is done using YUP
 */
import * as Yup from 'yup';
import {
  requiredFieldValidationRule,
  requiredTextFieldValidationRule,
} from '../../../../Forms/validationRules';

export const validationSchema = () =>
  Yup.object().shape({
    MID: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('MID');
      return validationSchema;
    }),

    Bin: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Bin');
      return validationSchema;
    }),

    AmexNumber: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Amex Number');
      return validationSchema;
    }),

    AmexOptBlue: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Amex Opt Blue');
      return validationSchema;
    }),

    Association: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Association');
      return validationSchema;
    }),

    Industry: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Industry');
      return validationSchema;
    }),

    MCC: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('MCC');
      return validationSchema;
    }),

    BankNumber: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Bank Number');
      return validationSchema;
    }),

    DiscoverNumber: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Discover Number');
      return validationSchema;
    }),

    Name: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Name');
      return validationSchema;
    }),

    DescriptorPhone: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Phone Number');
      return validationSchema;
    }),

    DescriptorCity: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('City');
      return validationSchema;
    }),

    DescriptorState: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('State');
      return validationSchema;
    }),

    DescriptorPostal: Yup.lazy((val: string) => {
      let validationSchema = requiredFieldValidationRule('Zip Code');
      return validationSchema;
    }),

    // descriptorCountry:Yup.string().trim().required(`Country is Required`),

    AgentBankNumber: Yup.lazy((val: string) => {
      let validationSchema =
        requiredTextFieldValidationRule('Agent Bank Number');
      return validationSchema;
    }),

    AgentChainNumber: Yup.lazy((val: string) => {
      let validationSchema =
        requiredTextFieldValidationRule('Agent Chain Number');
      return validationSchema;
    }),

    StoreNumber: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Store Number');
      return validationSchema;
    }),

    MVV: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('MVV');
      return validationSchema;
    }),

    TerminalId: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Terminal Id');
      return validationSchema;
    }),

    TerminalNumber: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Terminal Number');
      return validationSchema;
    }),

    VNumber: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('V Number');
      return validationSchema;
    }),

    URL: Yup.lazy((val: string) => {
      let validationSchema = requiredFieldValidationRule('URL');
      return validationSchema;
    }),
  });
