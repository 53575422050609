/**
 * Reporting transactions
 */

import {
  REPORTING_FETCH_TRANSACTION_BY_ID_PENDING,
  REPORTING_FETCH_TRANSACTION_BY_ID_FULFILLED,
  REPORTING_FETCH_TRANSACTION_BY_ID_REJECTED,
  REPORTING_TRANSACTIONS_RESET,
  //  REPORTING_TRANSACTIONS_RESET,
} from '../../actions/types';

const initialState = {
  isPending: false,
  isFulfilled: false,
  isRejected: false,
  message: {
    count: 0,
    Results: [],  // very not cute that the API varies the casing of Results vs results.  grrrrr
    results: []
  },
  // Add other initial state properties here
};

const fetchTransactionsByIdReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case REPORTING_FETCH_TRANSACTION_BY_ID_PENDING:
      return {
        ...state,
        isPending: true,
      };
    case REPORTING_FETCH_TRANSACTION_BY_ID_FULFILLED:
      return {
        ...state,
        ...action.payload,
        isFulfilled: true,
        isPending: false,
      };
    case REPORTING_FETCH_TRANSACTION_BY_ID_REJECTED:
      return {
        ...state,
        ...action.payload,
        isRejected: true,
        isPending: false,
      };
    case REPORTING_TRANSACTIONS_RESET:
      return initialState;
    default:
      return state;
  }
};

export default fetchTransactionsByIdReducer;
