import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import Dropzone from './DropZone';
import cuid from 'cuid';
import './DocumentSection.scss';
import './DropZone.scss';
import { Add, Close } from '@mui/icons-material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ImageGrid from './ImageGrid';

const DocumentSection = () : JSX.Element => {
  const [images, setImages] = useState<any>([]);
  const [showFileUploadArea, setShowFileUploadArea] = useState(false);

  const onDrop = useCallback((acceptedFiles:any) => {
    acceptedFiles.map((file: any) => {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        setImages((prevState: any) => [
          ...prevState,
          { id: cuid(), src: e.target.result },
        ]);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const renderUploadArea = () => (
    <Box
      sx={{ background: ' #F7F7F7', borderRadius: '20px', padding: '3% 5%' }}
    >
      <Button
        variant="outlined"
        className="NewUserButtonStyle"
        onClick={() => {
          setShowFileUploadArea(true);
        }}
      >
        {/* <Add fontSize="small" /> */}
        <SaveAltIcon fontSize="small" sx={{ marginRight: '0.5rem' }} />
        Attach Files
      </Button>
    </Box>
  );

  const renderFileUploadAndDrag = () => (
    <Box
      width="100%"
      sx={{
        backgroundColor: ' #F7F7F7',
        borderRadius: '20px',
        padding: '1rem',
        marginBottom: '2rem',
      }}
    >
      <Box className="InnerContainer" width="500px">
        <Box marginBottom="1rem">
          <Typography fontSize="16px">Upload Your Attachments</Typography>
          <Typography fontSize="14px" color="#B4B7C3">
            Supported Documents: PDF, JPG, PNG, DOC, CSV, XLS
          </Typography>
        </Box>
        <IconButton
          className="CloseIconBox"
          onClick={() => {
            setShowFileUploadArea(false);
          }}
        >
          <Close />
        </IconButton>
        <Dropzone onDrop={onDrop} accept={'image/*'} open={() => {}} />
        {/* <ImageGrid images={images} /> */}
      </Box>
    </Box>
  );

  const renderDocumentsArea = () => {
    return (
      <Box height="auto">
        <Box>{!showFileUploadArea && renderUploadArea()}</Box>
        <Box>{showFileUploadArea && renderFileUploadAndDrag()}</Box>
      </Box>
    );
  };

  return (
    <Box>
      <Box padding="1rem">
        <Box>
          <Typography fontSize="16px" fontWeight="bold">
            Documents
          </Typography>
        </Box>
        <Box style={{ paddingBottom: '3%' }}>
          <Typography fontSize="14px" color="#B4B7C3">
            Upload Merchant Documents: Maximum Upload File Size: 25 Mb
          </Typography>
        </Box>
        {renderDocumentsArea()}
      </Box>

      <Divider />

      <Box padding="1rem">
        <Box sx={{ marginTop: '1rem' }}>
          <Typography fontSize="16px" fontWeight="bold">
            General Notes
          </Typography>
        </Box>
        <Box style={{ paddingBottom: '2%' }}>
          <Typography fontSize="14px" color="#B4B7C3">
            Add your notes
          </Typography>
        </Box>

        <TextField
          className="TextArea"
          sx={{ width: '100%' }}
          placeholder="Add Notes…"
          multiline
          rows={6}
          maxRows={10}
        />
      </Box>
    </Box>
  );
};

export default DocumentSection;
