import { combineReducers } from '../index';
import appReducer from './app';
import authReducer from './auth';
import client from './client';
import reportingReducer from './reporting';
import userReducer from './users';
import virtualTerminalReducer from './virtualTerminal';

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  reporting: reportingReducer,
  user: userReducer,
  virtualTerminal: virtualTerminalReducer,
  client: client,
});
