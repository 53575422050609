/***
 * Change password class
 * Used to reset user password on forgot password
 * or on account locked due to password expired
 */
import React, { MouseEvent, useEffect, useState, ReactElement } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';

import { useStore } from 'lnox';
import ValidationPopover from '../../Common/ValidationPopover';
import { passwordValidationRule } from '../validationRules';
import validationSchema from './validationSchema';

import { ReactComponent as Validation } from '../../../assets/Validation.svg';

import { thunkChangePassword } from '../../../store/actions/authActions';

interface ChangePasswordFormProps {
  onBackToLogin: (event: MouseEvent) => void;
  isForgotPassword: boolean;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  onBackToLogin,
  isForgotPassword = false,
}) => {
  const {
    dispatch,
    state: {
      app: { statusMessages = [] },
      auth: { userId, passwordUpdateSuccess, intermediaryToken },
    },
  } = useStore();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const initialValues = { password: '', confirmPassword: '' };
  const [passwordValues, setPasswordValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const [confirmPasswordValues, setConfirmPasswordValues] = React.useState({
    confirmPassword: '',
    showConfirmPassword: false,
  });

  useEffect(() => {
    if (statusMessages) {
      statusMessages?.map((message: any, index: number) => {
        return setErrorMessage('Email or Phone and Password Do Not Match'); //message.text);
      });
    }
  });

  const renderBackToLogin = () => (
    <Box mt={1} className="center-align">
      <Link underline="hover" href="#" onClick={onBackToLogin}>
        Login
      </Link>
    </Box>
  );

  const handleChangePassword = (formValues: any) => {
    console.log('formValues', formValues, intermediaryToken);
    let password = formValues.password;
    dispatch(thunkChangePassword(userId, intermediaryToken, password));
  };

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setConfirmPasswordValues({
      ...confirmPasswordValues,
      showConfirmPassword: !confirmPasswordValues.showConfirmPassword,
    });
  };

  const handleMouseDownConfirmPassword = (event: any) => {
    event.preventDefault();
  };

  const displayHelperText = () => {
    return (
      errorMessage && (
        <Box display="inline-flex">
          <Box className="ErrorSpanStyle">
            <Validation />
          </Box>
          <Typography className="ErrorTextSpan">{errorMessage}</Typography>
        </Box>
      )
    );
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleChangePassword(values);
    },
  });

  const renderPasswordInputForm = () => {
    return (
      <Box className="ChangePasswordBox">
        <form onSubmit={formik.handleSubmit}>
          <div className="PasswordBox">
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              type={passwordValues?.showPassword ? 'text' : 'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.password && formik.errors.password ? true : false
              }
              helperText={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : ' '
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {passwordValues?.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="PasswordBox">
            <TextField
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              type={
                confirmPasswordValues?.showConfirmPassword ? 'text' : 'password'
              }
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                    >
                      {confirmPasswordValues?.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {displayHelperText()}

          {isForgotPassword && renderBackToLogin()}
          <div className="PasswordBox">
            <Button color="primary" variant="contained" fullWidth type="submit">
              RESET PASSWORD
            </Button>
          </div>
        </form>
      </Box>
    );
  };

  if (passwordUpdateSuccess === true) {
    return (
      <Box className="AccountLockedBox">
        <Typography variant="h6" className="OtpHeader">
          Password Updated Successfully
        </Typography>
        <Typography color={'#7C919D'}>
          You can now return to login with your new password.
        </Typography>
        {renderBackToLogin()}
      </Box>
    );
  }

  return (
    <Box className="AccountLockedBox">
      <Typography fontWeight="bolder" fontSize="large">
        {isForgotPassword ? 'Reset Your Password' : 'Password Expired'}
      </Typography>
      {!isForgotPassword && (
        <Typography display="block" color="#7C919D">
          Due to security reasons, you are required to change your password
          every 90 days
        </Typography>
      )}
      {renderPasswordInputForm()}
    </Box>
  );
};

export default ChangePasswordForm;
