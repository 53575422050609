import typeToReducer from 'type-to-reducer';

import { DEFAULT_ASYNC_STATE } from '../../constants';
import { CLIENT_APPLICATION_ID } from '../../actions/types';
import { ReducerAction, ReducerState } from '../../../types/common';

const DEFAULT_STATE = {
  ...DEFAULT_ASYNC_STATE,
  error: false,
  message: { results: [] },
};

export default typeToReducer(
  {
    [CLIENT_APPLICATION_ID]: {
      PENDING: () => ({
        ...DEFAULT_STATE,
        isPending: true,
      }),

      FULFILLED: (
        state: ReducerState | undefined,
        action: ReducerAction | any
      ) => {
        const { payload } = action;

        return {
          ...state,
          ...DEFAULT_STATE,
          ...payload,
          isFulfilled: true,
          isPending: false,
        };
      },

      REJECTED: (
        state: ReducerState | undefined,
        action: ReducerAction | any
      ) => {
        const { payload } = action;
        return {
          ...state,
          ...DEFAULT_STATE,
          ...payload,
          isRejected: true,
          isPending: false,
        };
      },
    },
  },
  DEFAULT_STATE
);
