import {
  DATETIME_FORMAT,
  DEFAULT_SETTLEMENT_TIME,
  getHostSettlementTimeOptions,
  US_STATES,
} from '../store/constants';
import { Client } from '../types/api';
import { SelectOption } from '../types/common';
import moment from 'moment';

/**
 * Helper Claas
 * For adding the common helper methods
 */
export const isEmptyObject = (data: object) =>
  Object.keys(data)?.length === 0 && data.constructor === Object;

/**
 * Generates random unique id.
 * It's a pretty basic id so do not use it in critical places
 */
export const randomUniqId = (prefix?: string) =>
  `${prefix || ''}${Math.random().toString(36).substr(2).toUpperCase()}`;

export const randomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Generates random unique id with special characters and lowercase.
 * It's a pretty strong id so consider use it in critical places
 */
export const randomUniqStrongerId = (prefix?: string) => {
  // example set of special chars as a string in no particular order
  const s = '~!@#$%^&*()-_=+[]{}|;:,.<>/?';
  const character = s.substr(Math.floor(s.length * Math.random()), 1);

  const str =
    Math.random().toString(36).substr(7).toUpperCase() +
    Math.random().toString(36).substr(7);
  const randomPosition = randomInteger(0, str.length);
  return `${prefix || ''}${str?.substring(
    0,
    randomPosition
  )}${character}${str?.substring(randomPosition, str.length)}`;
};

export const fecthEnvConfig = async () => {
  let data = await (await fetch('./config.json')).json();
  return data || {};
};
/**
 * Generates a valid array with USA states for select options
 */
export const usStatesSelectOptions = () =>
  Object.entries(US_STATES).reduce((res: Array<SelectOption>, [key, value]) => {
    res.push({ key: key, value });
    return res;
  }, []);

export const concatFirstString = (word: string) =>
  word ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : word;
export const dateFormatter = (date: any, format: any) => {
  return moment(new Date(date)).format(format);
};

export const fetchBatchSettlementTime = (key?: string) => {
  let response = key
    ? getHostSettlementTimeOptions().filter((option) =>
        option?.key.toLowerCase().startsWith(key?.trim().toLowerCase())
      )
    : DEFAULT_SETTLEMENT_TIME;
  return Array.isArray(response) && response.length
    ? response[0]?.value
    : DEFAULT_SETTLEMENT_TIME.value;
};

export const toTitleCase = (value: string) => {
  return value?.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
};



export const searchObjectForString = (searchTerm: string, sourceObject: Record<string, unknown> | unknown[]): boolean => {
  const isPrimitive = (test: any): test is string | number | boolean => {
    return test !== Object(test);
  };
  
  const toString = (value: any): string => {
    if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'number' || typeof value === 'boolean') {
      return String(value);
    } else {
      return JSON.stringify(value);
    }
  };

  if (Array.isArray(sourceObject)) {
    for (let i = 0; i < sourceObject.length; i++) {
      if (searchObjectForString(searchTerm, sourceObject[i])) {
        return true;
      }
    }
  } else if (typeof sourceObject === 'object' && sourceObject !== null) {
    for (const key in sourceObject) {
      if (Object.prototype.hasOwnProperty.call(sourceObject, key)) {
        const val = sourceObject[key];
        if (val !== null && !isPrimitive(val) && typeof val === 'object') {
          if (searchObjectForString(searchTerm, val as Record<string, unknown>)) {
            return true;
          }
        } else {
          let stringValue = toString(val);
          if( key.toLowerCase().includes("date") || key.toLowerCase().includes("lastaccess") || key.toLowerCase().includes("passwordexpiryat") ) {
            stringValue = moment(stringValue).format(DATETIME_FORMAT);
          }
          if (typeof stringValue === 'string' && stringValue.toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
          }
        }
      }
    }
  } else if (typeof sourceObject === 'string') {
    const stringValue = toString(sourceObject);
    return stringValue.toLowerCase().includes(searchTerm.toLowerCase());
  }
  
  return false;
};


export function showStringsAtLength( value: string, maxLength: number ) : string {
  if (value.length <= maxLength) {
    // If the string is shorter than the maxLength, return the entire string.
    return value;
  } else {
    // Otherwise, return a substring of the string that is maxLength characters long.
    return value.substring(0, maxLength) + "...";
  }
}




export interface ClientNode {
  client: Client;
  title: string;
  children: ClientNode[];
}

export const convertClientToClientNodes = (clients: Client[]): ClientNode[] => {
  // Sort the clients array by Id
  clients.sort((a, b) => a.Id - b.Id);

  const nodes: { [id: number]: ClientNode } = {};
  const roots: ClientNode[] = [];

  for (const client of clients) {
    nodes[client.Id] = {
      client,
      title: client.Name,
      children: [],
    };
  }

  for (const client of clients) {
    const node = nodes[client.Id];
    if (client.ParentId === null || client.ParentId === undefined) {
      roots.push(node);
    } else if (client.ParentId in nodes) {
      const parentNode = nodes[client.ParentId];
      parentNode.children.push(node);
    }
  }

  return roots;
};


export function findClientNodeSubTree(clientId: number, clientNodes: ClientNode[]): ClientNode | null {
  for (const node of clientNodes) {
    if (node.client.Id === clientId) {
      return node; // Found the node with matching clientId
    }
    const subTree = findClientNodeSubTree(clientId, node.children);
    if (subTree !== null) {
      return subTree; // Found the node in the sub tree
    }
  }
  return null; // Node not found in the current branch
}
