import { combineReducers } from '../../index';
import fetchUsers from './fetchAllUsers';
import fetchUsersById from './fetchById';
import createUser from './createUser';
import updateUser from './updateUser';
import fetchUserProfile from './profile/fetchUserProfile';
import udpateUserProfile from './profile/updateUserProfile';

export default combineReducers({
  user: combineReducers({
    fetch: fetchUsers,
    fetchById: fetchUsersById,
    create: createUser,
    update: updateUser,
    profile: combineReducers({
      fetch: fetchUserProfile,
      update: udpateUserProfile,
    }),
  }),
});
