import { combineReducers } from '../../index';
import fetchTransactions from './fetchTransactions';
import fetchTransactionsByID from './fetchTransactionByID';
import fetchBatchesReducer from './fetchBatches';
import closeBatchReducer from './closeBatch';
import fetchBatchTransactionsReducer from './fetchBatchTransactions';

export default combineReducers({
  transactions: combineReducers({
    fetch: fetchTransactions,
    fetchByID: fetchTransactionsByID,
  }),
  batches: combineReducers({
    fetch: fetchBatchesReducer,
    close: closeBatchReducer,
    transactions: fetchBatchTransactionsReducer,
  }),
});
