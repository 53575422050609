/**
 * Validation schema for shazam host form
 * Validation is done using YUP
 */
import * as Yup from 'yup';
import {
  requiredFieldValidationRule,
  requiredTextFieldValidationRule,
  reuiredSelectFieldValidationRule,
} from '../../../../Forms/validationRules';

export const validationSchema = () =>
  Yup.object().shape({
    ClientId: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Client ID');
      return validationSchema;
    }),
    ClientSecret: Yup.lazy((val: string) => {
      let validationSchema = requiredFieldValidationRule('ClientSecret');
      return validationSchema;
    }),
    MCC: Yup.lazy((val: string) => {
      let validationSchema = requiredFieldValidationRule(
        'Merchant Category Code'
      );
      return validationSchema;
    }),
    AccountId: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Account ID');
      return validationSchema;
    }),
    AccountName: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Account Name');
      return validationSchema;
    }),
    AccountType: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Account Type');
      return validationSchema;
    }),
    Url: Yup.lazy((val: string) => {
      let validationSchema = requiredFieldValidationRule('URL');
      return validationSchema;
    }),
    IsOffsetTrnx: Yup.lazy((val: string) => {
      let validationSchema = reuiredSelectFieldValidationRule(
        'Offset Transactions'
      );
      return validationSchema;
    }),
  });
