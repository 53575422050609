import { TextField, InputAdornment, Menu, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DATE_FORMAT, DATE_RANGES } from '../../store/constants';
import { SelectOption } from '../../types/common';
import DateRangePickerInput from '../../components/Common/DateRangePickerInput';
import './TransactionPeriodSelect.scss';

export interface PeriodSelectProps {
  name?: string;
  options?: Array<SelectOption>;
  startDate?: string,
  endDate?: string,
  onSelect: (arg1: string, starDate: string, endDate: string) => void;
}

export interface PeriodRangeProps {
  start: string;
  end: string;
}

export const defaultPeriodRange = {
  start: moment().subtract(30, 'day').toISOString(),
  end: moment().toISOString(),
};

const TransactionPeriodSelect: React.FC<PeriodSelectProps> = ({ onSelect, startDate, endDate }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [period, setPeriod] = React.useState(DATE_RANGES[0].value);
  const [periodRange, setPeriodRange] =
    useState<PeriodRangeProps>((startDate && endDate ) ? { start: startDate, end: endDate } : defaultPeriodRange);

  useEffect(() => {
    const { start, end } = periodRange;
    const format = 'MM/DD/YYYY';
    setPeriod(
      `${moment(start).format(format)} — ${moment(end).format(format)}`
    );
  }, [periodRange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPeriod(event.target.value);
  };

  const makeQuery = (startDate: any, endDate: any) => {
    return `createdAt>=${moment(startDate).format(
      DATE_FORMAT
    )},createdAt<=${moment(endDate).format(DATE_FORMAT)}`;
  };

  const handleClickOptionButton = (option: any) => {
    const { startDate, endDate } = option;
    
    onSelect(makeQuery(startDate, endDate), startDate.toISOString(), endDate.toISOString());
    setPeriodRange({
      start: startDate.toISOString(),
      end: endDate.toISOString(),
    });
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TextField
        className="DateSearch"
        id="customized-button-batch"
        aria-controls={open ? 'customized-menu-batch' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        value={period}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ cursor: 'pointer' }} position="end">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </InputAdornment>
          ),
        }}
      />
      <Menu
        id="customized-menu-batch"
        MenuListProps={{
          'aria-labelledby': 'customized-button-batch',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="DateFilterMenu"
      >
        <Box>
          <DateRangePickerInput
            showDateRangePicker={open ? true : false}
            onSelect={handleClickOptionButton}
            selectedDateRange={{
              startDate: periodRange?.start,
              endDate: periodRange?.end,
            }}
          ></DateRangePickerInput>
        </Box>
      </Menu>
    </>
  );
};

export default TransactionPeriodSelect;
