import {
  Box,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useCurrency from '../../../Hooks/useCurrency';
import { Currency, MoneyInputProps } from '../../../types/common';
import './MoneyInput.scss';
const alphabeticRegex = /^[A-Za-z]*$/;
export const DEFAULT_CURRENCY: Currency = {
  name: 'usd',
  symbol: '$',
  label: 'USD',
  decimalSeparator: '.',
  thousandSeparator: ',',
};

const MoneyInput: React.FC<MoneyInputProps> = ({
  onChange,
  value = '',
  disabled = false,
  maxLength = undefined,
  transactionType = '',
}) => {
  const { toCurrency } = useCurrency();
  const [currency] = useState<Currency>(DEFAULT_CURRENCY);
  const { decimalSeparator, symbol, thousandSeparator } = currency;
  const [moneyAmount, setMoneyAmount] = useState<string>(
    toCurrency(value?.value, decimalSeparator, symbol, thousandSeparator)
  );
  const [isVoidTransaction, setIsVoidTransaction] = useState<boolean>(false);

  useEffect(() => {
    const { decimalSeparator, symbol, thousandSeparator } = currency;
    setMoneyAmount(
      toCurrency(value?.value, decimalSeparator, symbol, thousandSeparator)
    );
  }, [value, currency, toCurrency]);

  const onInputChange = (e: any) => {
    const { value } = e.target;
    if (!alphabeticRegex.test(value)) {
      const currentValue = toCurrency(
        value,
        decimalSeparator,
        symbol,
        thousandSeparator
      );
      setMoneyAmount(currentValue);
      onChange &&
        onChange({
          ...e,
          target: { value: { value: currentValue, currency } },
        });
    }
  };

  useEffect(() => {
    if (transactionType && transactionType.toLowerCase() === 'void') {
      setIsVoidTransaction(true);
    } else {
      setIsVoidTransaction(false);
    }
  }, [transactionType]);

  return (
    <Box className="MoneyInputBoxStyle">
      <TextField
        id="Amount"
        name="amount"
        label="Amount"
        className="MoneyInputTextFieldStyle"
        onChange={onInputChange}
        value={moneyAmount}
        disabled={disabled || isVoidTransaction}
        inputProps={{
          // added this to limit amount value to 12 digits
          // 16 is for the formatting values in money input as $,.
          maxLength: 16,
        }}
        InputProps={{
          style: { fontSize: 30, padding: 0 },
          endAdornment: (
            <InputAdornment position="end">
              <Select
                id="CurrencyLabel"
                value="USD"
                className="CurrencyLabelStyle"
                variant="filled"
                disabled
              >
                <MenuItem value="USD">USD</MenuItem>
              </Select>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default MoneyInput;
