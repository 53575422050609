/*
 Transaction table on trasnsaction tab under The reporting page
*/
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './TransactionTable.scss';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import useCurrency from '../../Hooks/useCurrency';
import { DATETIME_FORMAT } from '../../store/constants';
import { Hidden } from '@mui/material';

interface TransactionTabelProps {
  onViewDetailsClick: (row: any) => void;
  transactions?: Array<any>;
  handleOnMore: () => void;
  isBatchDetails?: boolean;
}

const TransactionTable: React.FC<TransactionTabelProps> = ({
  onViewDetailsClick,
  transactions = [],
  handleOnMore,
  isBatchDetails,
}) => {
  const { toCurrency } = useCurrency();

  const renderDefaultMessage = () => (
    <TableRow>
      <TableCell colSpan={10} align="center">
        No Data To Show
      </TableCell>
    </TableRow>
  );

  const renderTableHeader = () => (
    <TableHead className="TransactionTableHeaderRow">
      <TableRow>
        <TableCell className="TransactionTableHeader">Date/Time</TableCell>
        <TableCell className="TransactionTableHeader">Customer</TableCell>
        <TableCell className="TransactionTableHeader">Trans Type</TableCell>
        <TableCell className="TransactionTableHeader">Response</TableCell>
        <TableCell className="TransactionTableHeader">Last 4</TableCell>
        <TableCell className="TransactionTableHeader">Network</TableCell>
        <TableCell className="TransactionTableHeader">Amount</TableCell>
        <TableCell className="TransactionTableHeader">Merchant DBA</TableCell>
      </TableRow>
    </TableHead>
  );

  const renderBatchTableHeader = () => (
    <TableHead className="TransactionTableHeaderRow">
      <TableRow>
        <TableCell className="TransactionTableHeader">Date/Time</TableCell>
        <TableCell className="TransactionTableHeader">Trans Type</TableCell>
        <TableCell className="TransactionTableHeader">Network</TableCell>
        <TableCell className="TransactionTableHeader">Last 4</TableCell>
        <TableCell className="TransactionTableHeader">Response</TableCell>
        <TableCell className="TransactionTableHeader">Amount</TableCell>
      </TableRow>
    </TableHead>
  );

  const renderTableBody = () => (
    <TableBody className="TransactionTableBody">
      {transactions?.length > 0
        ? renderTransactionRows()
        : renderDefaultMessage()}
    </TableBody>
  );

  const renderTransactionRows = () =>
    transactions?.map((row) => {
      return !isBatchDetails ? (
        <TableRow
          key={Math.random()}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          className="TransactionTableRowStyle"
          onClick={() => onViewDetailsClick(row)}
        >
          <TableCell>
            {row?.DateTime ? moment(row.DateTime).format(DATETIME_FORMAT) : '-'}
          </TableCell>
          <TableCell>{row?.Customer?.trim() ? row.Customer : '-'}</TableCell>
          <TableCell>
            {row?.TypeOfTransaction ? row.TypeOfTransaction : '-'}
          </TableCell>
          <TableCell>{row?.Response ? row.Response : '-'}</TableCell>
          <TableCell>
            {row?.CardLast4Digit ? row.CardLast4Digit : '-'}
          </TableCell>
          <TableCell>{row?.CardBrand ? row.CardBrand : '-'}</TableCell>
          <TableCell align="right">
            {row?.AmountTotal
              ? toCurrency(
                Number(row.AmountTotal).toFixed(2).toString(),
                '.',
                '$',
                ','
              )
              : '-'}
          </TableCell>
          <TableCell>{row?.ClientName ? row.ClientName : '-'}</TableCell>
        </TableRow>
      ) : (
        <TableRow
          key={Math.random()}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          className="TransactionTableRowStyle"
          onClick={() => onViewDetailsClick(row)}
        >
          <TableCell>
            {row?.TransactionDateTime
              ? moment(row.TransactionDateTime).format(DATETIME_FORMAT)
              : '-'}
          </TableCell>
          <TableCell>
            {row?.TransactionType ? row.TransactionType : '-'}
          </TableCell>
          <TableCell>{row?.CardBrand ? row.CardBrand : '-'}</TableCell>
          <TableCell>
            {row?.CardLast4Digit ? row.CardLast4Digit : '-'}
          </TableCell>
          <TableCell>{row?.ResponseType ? row.ResponseType : '-'}</TableCell>
          <TableCell align="right">
            {row?.Amount
              ? toCurrency(
                Number(row.Amount).toFixed(2).toString(),
                '.',
                '$',
                ','
              )
              : '-'}
          </TableCell>
        </TableRow>
      );
    });
  return (
    <>
      <InfiniteScroll
        dataLength={(transactions) ? transactions.length : 0}
        next={handleOnMore}
        //To put endMessage and loader to the top.
        hasMore={false}
        loader={<></>}
        scrollableTarget="tableID"
      >
        <TableContainer
          id={'tableID'}
          
          className={
            isBatchDetails === true ? 'TransactionBatchTable' : 'TransactionTable'
          }
          component={Paper}
        >
          <Table stickyHeader aria-label="simple table">
            {isBatchDetails ? renderBatchTableHeader() : renderTableHeader()}
            {renderTableBody()}
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  );
};

export default TransactionTable;
