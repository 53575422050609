/**
 * Business Info
 */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import AddIcon from '@mui/icons-material/Add';
import './BusinessInfo.scss';
import { validationSchema } from './validationSchema';
import { useFormik } from 'formik';
import {
  CountryOptions,
  INDUSTRY_TYPE_OPTIONS,
  MERCHANT_TYPE_OPTIONS,
  NUMBER_OF_EMPLOYESS_OPTIONS,
  OWNERSHIP_TYPE_OPTIONS,
} from '../../store/constants';
import useDidMountEffect from '../../Hooks/useDidMountEffect';
import { usStatesSelectOptions } from '../../libs/helpers';

interface BusinessInfoFormValues {
  MerchantLegalName: string;
}

const BusinessInfo: React.FC<any> = ({}) => {
  const [stateList, setStateList] = useState<Array<object>>([{}]);

  useDidMountEffect(() => {
    fetchStateList();
  });

  const fetchStateList = () => {
    setStateList(usStatesSelectOptions());
  };

  const [passwordValues, setPasswordValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const initialValues: BusinessInfoFormValues = { MerchantLegalName: '' };

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema(),
    onSubmit: (fromValues: any) => {
      //onChange && onChange({ ...fromValues });
    },
    validateOnChange: true,
  });
  const renderBusinessInfoForm = () => (
    <Box>
      <form onChange={() => {}} onSubmit={() => {}}>
        <Box className="SectionBox">
          <Typography
            fontWeight="bold"
            fontSize="large"
            className="HeadingText"
          >
            Business Information
          </Typography>
          <Box className="UserRow">
            <FormControl fullWidth>
              <TextField
                id={'MerchantLegalName'}
                name={'MerchantLegalName'}
                label="Merchant Legal Name"
                className="FirstNameStyle"
                disabled={false}
                value={formik.values.MerchantLegalName}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('MerchantLegalName', value);
                  formik.setFieldTouched('MerchantLegalName');
                }}
                onBlur={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('MerchantLegalName', value);
                  formik.setFieldTouched('MerchantLegalName');
                }}
                error={
                  formik.touched.MerchantLegalName &&
                  formik.errors.MerchantLegalName
                    ? true
                    : false
                }
              />
              {formik.touched.MerchantLegalName &&
                formik.errors.MerchantLegalName && (
                  <FormHelperText className="FormHelperTextStyle">
                    {formik.touched.MerchantLegalName &&
                    formik.errors.MerchantLegalName
                      ? formik.errors.MerchantLegalName
                      : ''}
                  </FormHelperText>
                )}
            </FormControl>
            <FormControl id={'LastName'} fullWidth>
              <TextField
                id={'Merchant_Name_DBA'}
                name={'Merchant_Name_DBA'}
                label="Merchant Name (DBA or Trade Name)"
                className="LastNameStyle"
                disabled={false}
                value={formik.values.Merchant_Name_DBA}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('Merchant_Name_DBA', value);
                  formik.setFieldTouched('Merchant_Name_DBA');
                }}
                onBlur={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('Merchant_Name_DBA', value);
                  formik.setFieldTouched('Merchant_Name_DBA');
                }}
              />
            </FormControl>
          </Box>
          <Box className="UserRow">
            <FormControl fullWidth className="SelectFormControlStyle">
              <InputLabel id={'Ownership_Type'}>Ownership Type</InputLabel>
              <Select
                label={'Ownership Type'}
                labelId={'Ownership_Type'}
                id={'Ownership_Type'}
                name={'Ownership_Type'}
                value={formik.values.Ownership_Type}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('Ownership_Type', value);
                  formik.setFieldTouched('Ownership_Type');
                }}
                onBlur={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('Ownership_Type', value);
                  formik.setFieldTouched('Ownership_Type');
                }}
              >
                {OWNERSHIP_TYPE_OPTIONS.map((item: any) => {
                  return (
                    <MenuItem key={item.key} value={item.value}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl id={'Federal_Tax_ID'} fullWidth>
              <TextField
                id={'Federal_Tax_ID'}
                name={'Federal_Tax_ID'}
                label="Federal Tax ID (TIN)"
                disabled={false}
                type={passwordValues?.showPassword ? 'text' : 'password'}
                value={formik.values.Federal_Tax_ID}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  let zipCode = value;
                  zipCode = zipCode.replace(/[^\dA-Z]/g, '');
                  if (
                    zipCode == zipCode.replace(/[^\d ]/g, '') &&
                    zipCode.length < 10
                  ) {
                    if (zipCode.length > 2) {
                      zipCode = value
                        .replace(/[^\dA-Z]/g, '')
                        .replace(/(.{2})/g, '$1_')
                        .trim();
                    }
                    formik.setFieldValue('Federal_Tax_ID', zipCode);
                  }
                }}
                InputProps={{
                  autoComplete: 'new-password',
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {passwordValues?.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>
          <Box className="UserRow">
            <FormControl fullWidth className="SelectFormControlStyle">
              <InputLabel id={'Number_of_Employees'}>
                Number of Employees
              </InputLabel>
              <Select
                label={'Number of Employees'}
                labelId={'Number_of_Employees'}
                id={'Number_of_Employees'}
                name={'Number_of_Employees'}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('Number_of_Employees', value);
                  formik.setFieldTouched('Number_of_Employees');
                }}
                onBlur={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('Number_of_Employees', value);
                  formik.setFieldTouched('Number_of_Employees');
                }}
              >
                {NUMBER_OF_EMPLOYESS_OPTIONS.map((item: any) => {
                  return (
                    <MenuItem key={item.key} value={item.value}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl id={'Date_Established'} fullWidth>
              <TextField
                id="Date_Established"
                label="Date Established"
                type="date"
                defaultValue=""
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Box>
          <Box className="UserRow">
            <FormControl fullWidth className="SelectFormControlStyle">
              <InputLabel id={'State_of_Incorporation'}>
                State of Incorporation
              </InputLabel>
              <Select
                label={'State of Incorporation'}
                labelId={'State_of_Incorporation'}
                id={'State_of_Incorporation'}
                name={'State_of_Incorporation'}
                onChange={(e: any) => {}}
                onBlur={(e: any) => {}}
              >
                {stateList.map((item: any) => {
                  return (
                    <MenuItem key={item.key} value={item.value}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl id={'Company_Website'} fullWidth>
              <TextField
                id={'Company_Website'}
                name={'Company_Website'}
                label="Company's Website"
                disabled={false}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('Company_Website', value);
                  formik.setFieldTouched('Company_Website');
                }}
                onBlur={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('Company_Website', value);
                  formik.setFieldTouched('Company_Website');
                }}
                error={
                  formik.touched.Company_Website &&
                  formik.errors.Company_Website
                    ? true
                    : false
                }
              />
              {formik.touched.Company_Website &&
                formik.errors.Company_Website && (
                  <FormHelperText className="FormHelperTextStyle">
                    {formik.touched.Company_Website &&
                    formik.errors.Company_Website
                      ? formik.errors.Company_Website
                      : ''}
                  </FormHelperText>
                )}
            </FormControl>
          </Box>
          <Box className="UserRow">
            <FormControl fullWidth>
              <PhoneInput
                specialLabel="Phone Number"
                inputProps={{
                  name: 'PhoneNumber',
                }}
                country={'us'}
                placeholder={'Phone Number'}
                countryCodeEditable={false}
                onChange={(phone) => {}}
                containerStyle={{
                  width: '100%',
                }}
                inputStyle={{
                  width: '48.5%',
                  height: '95%',
                  marginRight: '5%',
                  borderRadius: '10px',
                }}
              />
            </FormControl>
          </Box>
        </Box>
        <Divider variant="fullWidth" />
        <Box className="SectionBox">
          <Typography
            fontWeight="bold"
            fontSize="large"
            className="HeadingText"
          >
            Merchant Category Code
          </Typography>
          <Typography
            fontWeight="normal"
            fontSize="small"
            className="SubHeadingText"
          >
            If Industry/Merchant Type not found, please enter Category Code
            manually.
          </Typography>
          <Box className="UserRow">
            <FormControl fullWidth className="SelectFormControlStyle">
              <InputLabel id={'Industry_Type'}>Industry Type</InputLabel>
              <Select
                label={'Industry Type'}
                labelId={'Industry_Type'}
                id={'Industry_Type'}
                name={'Industry_Type'}
                onChange={(e: any) => {}}
                onBlur={(e: any) => {}}
              >
                {INDUSTRY_TYPE_OPTIONS.map((item: any) => {
                  return (
                    <MenuItem key={item.key} value={item.value}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl id={'Merchant_Type'} fullWidth>
              <InputLabel id={'Industry_Type'}>Merchant Type</InputLabel>
              <Select
                label={'Merchant Type'}
                labelId={'Merchant Type'}
                id={'Merchant Type'}
                name={'Merchant Type'}
                onChange={(e: any) => {}}
                onBlur={(e: any) => {}}
              >
                {MERCHANT_TYPE_OPTIONS.map((item: any) => {
                  return (
                    <MenuItem key={item.key} value={item.value}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box className="UserRow">
            <FormControl fullWidth>
              <InputLabel id={'Category_Code'}>Category Code</InputLabel>
              <Select
                label={'Category Code'}
                labelId={'Category Code'}
                id={'Category_Code'}
                name={'Category_Code'}
                onChange={(e: any) => {}}
                onBlur={(e: any) => {}}
              ></Select>
            </FormControl>
          </Box>
        </Box>
        <Divider variant="fullWidth" />
        <Box className="SectionBox">
          <Box display="inline-flex" className="FullWidth">
            <Box className="AddressSection">
              <Typography
                fontWeight="bold"
                fontSize="large"
                className="HeadingText"
              >
                Business Address (No PO Box)
              </Typography>
            </Box>
            <Box className="StyledAddAddressButton">
              <Button variant="outlined" className="ButtonText">
                <AddIcon />
                New Address
              </Button>
            </Box>
          </Box>
          <Box className="UserRow">
            <FormControl fullWidth>
              <TextField
                id={'Street_Address'}
                name={'Street_Address'}
                label="Street Address"
                className="FirstNameStyle"
                disabled={false}
                onChange={(e: any) => {}}
                onBlur={(e: any) => {}}
              />
            </FormControl>
            <FormControl id={'Street_Address1'} fullWidth>
              <TextField
                id={'Street_Address1'}
                name={'Street_Address1'}
                label="Apt., Building, Suite, Floor"
                className="LastNameStyle"
                disabled={false}
                onChange={(e: any) => {}}
                onBlur={(e: any) => {}}
              />
            </FormControl>
          </Box>
          <Box className="UserRow">
            <FormControl id={'City'} fullWidth>
              <TextField
                id={'City'}
                name={'City'}
                label="City"
                disabled={false}
                onChange={(e: any) => {}}
                onBlur={(e: any) => {}}
              />
            </FormControl>
            <FormControl
              id={'State'}
              fullWidth
              className="AddressSecondColoumn"
            >
              <InputLabel id={'State'}>State</InputLabel>
              <Select
                label={'State'}
                labelId={'State'}
                id={'State'}
                name={'State'}
                onChange={(e: any) => {}}
                onBlur={(e: any) => {}}
              >
                {stateList.map((item: any) => {
                  return (
                    <MenuItem key={item.key} value={item.value}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box className="UserRow">
            <FormControl id={'Zip_Code'} fullWidth>
              <TextField
                id={'Zip_Code'}
                name={'Zip_Code'}
                label="Zip Code"
                disabled={false}
                onChange={(e: any) => {}}
                onBlur={(e: any) => {}}
              />
            </FormControl>
            <FormControl
              id={'Country'}
              fullWidth
              className="AddressSecondColoumn"
            >
              <InputLabel id={'Country'}>Country</InputLabel>
              <Select
                label={'Country'}
                labelId={'Country'}
                id={'Country'}
                name={'Country'}
                onChange={(e: any) => {}}
                onBlur={(e: any) => {}}
              >
                {CountryOptions.map((item: any) => {
                  return (
                    <MenuItem key={item.key} value={item.value}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </form>
    </Box>
  );

  return <Box>{renderBusinessInfoForm()}</Box>;
};

export default BusinessInfo;
