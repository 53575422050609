import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Close } from '@mui/icons-material';
import { Box } from '@mui/system';

interface DropzoneProps {
  onDrop: any;
  accept: any;
  open: any;
}

const maxSize = 25000000;

const Dropzone: React.FC<DropzoneProps> = ({ onDrop, accept, open }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept,
    onDrop,
    validator: nameLengthValidator,
  });

  const [files, setFiles] = useState<any>([]);
  const [rejectedFile, setRejectedFile] = useState<any>([]);
  const [showNoOfFileLimit, setShowNoOfFileLimit] = useState(false);

  function nameLengthValidator(file: any) {
    if (file.size > maxSize) {
      return {
        code: 'max-file-size',
        message: `File Size is larger than 25 MB`,
      };
    }
    return null;
  }
  useEffect(() => {
    if (files && files.length > 25) {
      setShowNoOfFileLimit(true);
    }
  }, [files]);

  useEffect(() => {
    if (acceptedFiles) {
      setFiles(acceptedFiles);
    }
    if (fileRejections) {
      setRejectedFile(fileRejections);
    }
  }, [acceptedFiles, fileRejections]);

  const deleteFiles = (name: any) => {
    const restfiles = files.filter((file: any) => file.name !== name);
    setFiles(restfiles);
  };

  const deleteRejectedFiles = (name: any) => {
    const rejectedfiles = rejectedFile.filter(
      ({ file, errors }: any) => file.name !== name
    );
    setRejectedFile(rejectedfiles);
  };

  const FILES = files.map((file: any) => (
    <Box className="Files" key={file?.path}>
      <Box>
        <Typography>
          {file?.path} - {file.size} bytes
        </Typography>
      </Box>
      <Box sx={{ cursor: 'pointer' }}>
        <Close
          onClick={() => {
            deleteFiles(file.name);
          }}
        />
      </Box>
    </Box>
  ));

  const fileRejectionItems = rejectedFile.map(({ file, errors }: any) => (
    <Box className="Files" key={file?.name}>
      <Box>
        <Typography color="red">
          {file?.name} - {file.size} bytes
        </Typography>
        {errors.map((e: any) => (
          <Typography color="red">{e.message}</Typography>
        ))}
      </Box>
      <Box sx={{ cursor: 'pointer' }}>
        <Close
          onClick={() => {
            deleteRejectedFiles(file.name);
          }}
        />
      </Box>
    </Box>
  ));

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center">
          {isDragActive ? (
            <p className="dropzone-content">Release to drop the files here</p>
          ) : (
            <>
              <SaveAltIcon />
              <p className="dropzone-content">Drag and drop your files here</p>
              <p className="dropzone-content">OR</p>
            </>
          )}
          <Button
            variant="contained"
            className="SideRailfooterButtonStyle"
            onClick={open}
          >
            Browse files
          </Button>
        </div>
      </div>
      {!showNoOfFileLimit && <Box sx={{ marginTop: '1rem' }}>{FILES}</Box>}
      {!showNoOfFileLimit && <Box>{fileRejectionItems}</Box>}
      {showNoOfFileLimit && (
        <Box sx={{ marginTop: '1rem' }}>
          <Typography color="red">User can upload maximum 25 files</Typography>
        </Box>
      )}
    </div>
  );
};

export default Dropzone;
