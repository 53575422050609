import React, { MouseEvent, useEffect, useState } from 'react';
import validationSchema from './validationSchema';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useStore } from 'lnox';
import { thunkLoginAction } from '../../../store/actions/authActions';
import { useFormik } from 'formik';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ReactComponent as Validation } from '../../../assets/Validation.svg';
interface SignInFormProps {
  onForgotPassword: (event: MouseEvent) => void;
}

const SignInForm: React.FC<SignInFormProps> = ({ onForgotPassword }) => {
  const initialValues = { login: '', password: '' };
  const { dispatch, state: { app: { statusMessages = [] } = {} } = {} } =
    useStore();
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [clicked, setClicked] = useState(false)


  useEffect(() => {
    if (statusMessages) {
      statusMessages?.map((message: any, index: number) => {
        return setErrorMessage('Email or Phone and Password Do Not Match'); //message.text);
      });
    }
  });

  /**
   * Render forgot password link.
   */
  const renderForgotPassword = () => (
    <Box mt={1}>
      <Link
        style={{ padding: 6 }}
        className="Link"
        underline="hover"
        href="#"
        onClick={onForgotPassword}
      >
        Forgot Password?
      </Link>
    </Box>
  );

  const handleUserLogin = async (formValues: any) => {
    setIsSubmitting(true);
    sessionStorage.setItem('IsSessionActive', 'true');
    await dispatch(thunkLoginAction(formValues.login.toLowerCase(), formValues.password));
    setIsSubmitting(false);
  };

  const handleChange = (event: any) => {
    formik.handleChange(event)
    setClicked(true)
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const displayHelperText = () => {
    return (
      errorMessage && (
        <Box display="inline-flex">
          <Box className="ErrorSpanStyle">
            <Validation />
          </Box>
          <Typography className="ErrorTextSpan">{errorMessage}</Typography>
        </Box>
      )
    );
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleUserLogin(values);
    },
  });

  return (
    <Box className="SignInBox">
      <form onSubmit={formik.handleSubmit}>
        <div className="UserNameBox">
          <TextField
            autoComplete={"disabled"}
            fullWidth
            id="login"
            name="login"
            label="Email or Phone Number"
            value={formik.values.login}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.login && formik.errors.login ? true : false}
            helperText={
              formik.touched.login && formik.errors.login
                ? formik.errors.login
                : ' '
            }
          />
        </div>
        <div className="PasswordBox">
          <TextField
            autoComplete={"disabled"}
            fullWidth
            id="password"
            name="password"
            label="Password"
            type={(formik.touched.password || clicked) ? (values?.showPassword ? 'text' : 'password') : 'text'}
            value={formik.values.password}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.password && formik.errors.password ? true : false
            }
            helperText={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ' '
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values?.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        {displayHelperText()}
        {renderForgotPassword()}
        <div className="PasswordBox">
          <Button
            color="primary"
            sx={{ padding: '12px' }}
            disabled={isSubmitting || !formik.isValid}
            variant="contained"
            className="GetOtpBtn"
            fullWidth
            type="submit"
          >
            {isSubmitting ? (
              <CircularProgress size={24}></CircularProgress>
            ) : (
              'LOGIN'
            )}
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default SignInForm;
