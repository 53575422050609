import api from '../../libs/api';
import { StatusMessageType } from '../../types/common';
import { setStatusMessageAction } from './appActions';
import {
  USERS_FETCH_PENDING,
  USERS_FETCH_REJECTED,
  USERS_FETCH_FULFILLED,
  USERS_FETCH_BY_ID,
  USER_CREATE,
  USER_UPDATE,
} from './types';

export const thunkUsersFetchAction = (params?: any) => {
  return (dispatch: any) => {
    dispatch({
      type: USERS_FETCH_PENDING,
    });
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        api.users.fetch(params)
          .then((response) => {
            dispatch({
              type: USERS_FETCH_FULFILLED,
              payload: response,
            });
            resolve(response);
          })
          .catch((error) => {
            dispatch({
              type: USERS_FETCH_REJECTED,
              payload: error,
            });
            reject(error);
          });
      }, 1000);
    });
  };
};

export const thunkUsersFetchByIdAction =
  (params?: any) => async (dispatch: any) => {
    dispatch({
      type: USERS_FETCH_BY_ID,
      payload: api.users.fetchById(params),
    });
  };

export const thunkExportingFetchUsersAction = (params?: any) => async () => {
  return await api.users.fetch(params);
};

export const thunkCreateUserAction = (Data: any) => async (dispatch: any) => {
  const response = await dispatch({
    type: USER_CREATE,
    payload: api.users.create(Data),
  });
  if (!response?.value?.error) {
    await dispatch(
      setStatusMessageAction(
        StatusMessageType.SUCCESS,
        `User Created Successfully.`
      )
    );
  }
  return response;
};

export const thunkUpdateUserAction = (Data: any) => async (dispatch: any) => {
  const response = await dispatch({
    type: USER_UPDATE,
    payload: api.users.update(Data),
  });
  if (!response?.error) {
    await dispatch(
      setStatusMessageAction(
        StatusMessageType.SUCCESS,
        `User Updated Successfully.`
      )
    );
  }
  return response;
};
