/**
 * Reporting transactions
 */
import {
  REPORTING_FETCH_BATCH_TRANSACTIONS_FULFILLED,
  REPORTING_FETCH_BATCH_TRANSACTIONS_REJECTED,
  REPORTING_FETCH_BATCH_TRANSACTIONS_PENDING,
  REPORTING_TRANSACTIONS_RESET,
} from '../../actions/types';

const initialState = {
  isPending: false,
  isFulfilled: false,
  isRejected: false,
  error: false,
  message: {
    count: 0,
    Results: [],  // very not cute that the API varies the casing of Results vs results.  grrrrr
    results: []
  },
};

const fetchBatchTransactionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case REPORTING_FETCH_BATCH_TRANSACTIONS_PENDING:
      return {
        ...state,
        isPending: true,
      };
    case REPORTING_FETCH_BATCH_TRANSACTIONS_FULFILLED:
      return {
        ...state,
        ...action.payload,
        isFulfilled: true,
        isPending: false,
      };
    case REPORTING_FETCH_BATCH_TRANSACTIONS_REJECTED:
      return {
        ...state,
        ...action.payload,
        isRejected: true,
        isPending: false,
      };
    case REPORTING_TRANSACTIONS_RESET:
      return initialState;
    default:
      return state;
  }
};

export default fetchBatchTransactionsReducer;

