import api from '../../libs/api';
import { StatusMessageType } from '../../types/common';
import { setStatusMessageAction } from './appActions';
import {
  REPORTING_FETCH_BATCHES_REJECTED,
  REPORTING_FETCH_BATCHES_FULFILLED,
  REPORTING_FETCH_BATCHES_PENDING,
  REPORTING_FETCH_TRANSACTIONS_SUMMARY_PENDING,
  REPORTING_FETCH_TRANSACTIONS_SUMMARY_FULFILLED,
  REPORTING_FETCH_TRANSACTIONS_SUMMARY_REJECTED,
  REPORTING_FETCH_TRANSACTION_BY_ID_PENDING,
  REPORTING_FETCH_TRANSACTION_BY_ID_FULFILLED,
  REPORTING_FETCH_TRANSACTION_BY_ID_REJECTED,
  REPORTING_TRANSACTIONS_RESET,
  REPORTING_BATCH_CLOSE,
  REPORTING_BATCHES_RESET,
  REPORTING_FETCH_BATCH_TRANSACTIONS_PENDING,
  REPORTING_FETCH_BATCH_TRANSACTIONS_FULFILLED,
  REPORTING_FETCH_BATCH_TRANSACTIONS_REJECTED,
} from './types';

export const thunkReportingFetchTransactionsSummaryAction = (params?: any) => {
  return (dispatch: any) => {
    dispatch({
      type: REPORTING_FETCH_TRANSACTIONS_SUMMARY_PENDING,
    });
    return new Promise((resolve, reject) => {
      api.reporting.transactions
        .fetchSummary(params)
        .then((response) => {
          dispatch({
            type: REPORTING_FETCH_TRANSACTIONS_SUMMARY_FULFILLED,
            payload: response,
          });
          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: REPORTING_FETCH_TRANSACTIONS_SUMMARY_REJECTED,
            payload: error,
          });
          reject(error);
        });
    });
  };
};

export const thunkReportingFetchTransactionsAction = (params?: any) => {
  return (dispatch: any) => {
    dispatch({
      type: REPORTING_FETCH_TRANSACTION_BY_ID_PENDING,
    });
    return new Promise((resolve, reject) => {
      api.reporting.transactions
        .fetch(params)
        .then((response) => {
          dispatch({
            type: REPORTING_FETCH_TRANSACTION_BY_ID_FULFILLED,
            payload: response,
          });
          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: REPORTING_FETCH_TRANSACTION_BY_ID_REJECTED,
            payload: error,
          });
          reject(error);
        });
    });
  };
};

export const resetTransactionsAction = () => ({
  type: REPORTING_TRANSACTIONS_RESET,
});

export const resetBatchesAction = () => ({
  type: REPORTING_BATCHES_RESET,
});

export const thunkBatchCloseAction = (batchId: string) => {
  return (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      api.reporting.batches
        .close(batchId)
        .then(async (response: any) => {
          dispatch({
            type: REPORTING_BATCH_CLOSE,
            payload: response,
          });

          localStorage.setItem('IsFinancialTransaction', 'false');
          if (response && !response?.value?.error) {
            await dispatch(
              setStatusMessageAction(
                StatusMessageType.SUCCESS,
                response?.value?.message?.ResponseText
              )
            );

            resolve({ status: true });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const thunkReportingFetchBatchesAction = (params?: any) => {
  return (dispatch: any) => {
    dispatch({
      type: REPORTING_FETCH_BATCHES_PENDING,
    });
    return new Promise((resolve, reject) => {
      api.reporting.batches
        .fetch(params)
        .then((response) => {
          dispatch({
            type: REPORTING_FETCH_BATCHES_FULFILLED,
            payload: response,
          });
          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: REPORTING_FETCH_BATCHES_REJECTED,
            payload: error,
          });
          reject(error);
        });
    });
  };
};
  

export const thunkExportingFetchTransactionsAction =
  (params?: object) => async () => {
    return api.reporting.transactions.fetchSummary(params);
  };

export const thunkFetchBatchTransactionsAction  = (params?: any) => {
    return (dispatch: any) => {
      dispatch({
        type: REPORTING_FETCH_BATCH_TRANSACTIONS_PENDING,
      });
      return new Promise((resolve, reject) => {
        api.reporting.transactions
          .fetchSummary(params)
          .then((response) => {
            dispatch({
              type: REPORTING_FETCH_BATCH_TRANSACTIONS_FULFILLED,
              payload: response,
            });
            resolve(response);
          })
          .catch((error) => {
            dispatch({
              type: REPORTING_FETCH_BATCH_TRANSACTIONS_REJECTED,
              payload: error,
            });
            reject(error);
          });
      });
    };
  };

