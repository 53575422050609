/**
 * Validation schema for Merchant Application form
 * Validation is done using YUP
 */
import * as Yup from 'yup';
import {
  requiredTextFieldValidationRule,
  phoneNumberValidRuleFor11DigitNotRequired,
  emailValidationRule,
} from '../validationRules';

export default Yup.object().shape({
  Name: requiredTextFieldValidationRule('Merchant DBA'),
  FirstName: requiredTextFieldValidationRule('First Name'),
  LastName: requiredTextFieldValidationRule('Last Name'),
  EmailAddress: emailValidationRule('Email address', true),
});
