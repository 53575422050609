/**
 * Validation schema for signin form
 * Validation is done using YUP
 */
import * as Yup from 'yup';
import {
  passwordValidationRule,
  fieldMatchValidationRule,
} from '../validationRules';

export default Yup.object().shape({
  password: passwordValidationRule('Password'),
  confirmPassword: fieldMatchValidationRule('password'),
});
