/**
 * Accounts Page
 * Displays merchant applications, host configurations
 */
import { Add } from '@mui/icons-material';


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography,
  styled,
} from '@mui/material';

import SortableTree from 'react-sortable-tree';

import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import TreeView from '@mui/lab/TreeView';

import React, { useEffect, useState } from 'react';
import MerchantTable from '../../components/MerchantTable';
import '../../components/Layout/Layout.scss';

import './Accounts.scss';

import SideRail from '../../components/Common/SideRail';
import MerchantApplicationForm from '../../components/Forms/MerchantApplication/MerchantApplicationForm';
import { useStore } from 'lnox';
import {
  thunkCreatMerchantApplication,
  thunkCreatMerchantApplicationID,
} from '../../store/actions/onboardingActions';
import { ClientNode, convertClientToClientNodes, findClientNodeSubTree } from '../../libs/helpers';

import { alpha } from '@mui/material/styles';


const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`&.${treeItemClasses.root}`]: {
    [`& .${treeItemClasses.label}`]: {
      border: "solid grey 1px",
      borderRadius: theme.shape.borderRadius,
      marginTop: 3,
      height: "50px",
      marginBottom: 3,
      color: 'black', // to enforce the color of the label text
      display: 'flex',
      alignItems: 'center', // center text vertically
      justifyContent: 'flex-start', // align text to the left
    },
    [`& .${treeItemClasses.content}`]: {
      color: 'black',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      [`&.${treeItemClasses.focused}`]: {
        backgroundColor: 'transparent !important',
      },
      [`&.${treeItemClasses.selected}`]: {
        backgroundColor: 'transparent !important',
      }
    },
  },
}));

export enum TabIndex {
  Applications = 0,
  Underwriting = 1,
  Merchants = 2,
  Hierarchy = 3,
}


const Accounts: React.FC<{}> = () => {
  const {
    dispatch,
    state: {
      client: { clients },
      auth: {
        userData,
        clientId
      }
    },
  } = useStore();


  const {
    fetchApplicationID: {
      isFulfilled: fetchApplicationIDFulfilled,
      message: { ApplicationId },
    },
    create: {
      isPending: createApplicationIsPending,
      isFulfilled: createApplicationIsFulfilled,
      isRejected: createApplicationIsRejected,
    },
  } = clients;

  const DefaultClientAppTitle = 'New Merchant Application';
  const [activeTabIndex, setActiveTabIndex] = useState(TabIndex.Merchants);
  const [showApplicationSideRail, setShowApplicationSideRail] = useState(false);
  const [clientApplicationId, setClientApplicationId] = useState('');
  const [clientNodes, setClientNodes] = useState<ClientNode[]>([]);
  const [clientApplicationValues, setClientApplicationValues] = useState<any>(
    {}
  );
  const [clientApplicationTitle, setClientApplicationTitle] = useState(
    DefaultClientAppTitle
  );
  const [isMerchantAppFormValid, setIsMerchantAppFormValid] = useState(false);

  const initClientTreeFromSelectedClient = () =>{
    let tclientNodes: ClientNode[] = (userData && userData.Clients && userData.Clients.length > 0) ? convertClientToClientNodes(userData.Clients) : [];
    let clientRootNode = findClientNodeSubTree( clientId, tclientNodes );
    setClientNodes((clientRootNode) ? [clientRootNode] : []);
  }

  useEffect(()=>{
    initClientTreeFromSelectedClient();
  }, []);

  useEffect(()=>{
    initClientTreeFromSelectedClient();
  }, [clientId]);

  useEffect(() => {
    if (fetchApplicationIDFulfilled) {
      setClientApplicationId(ApplicationId);
    }
  }, [fetchApplicationIDFulfilled, ApplicationId]);

  useEffect(() => {
    if (createApplicationIsFulfilled && !createApplicationIsRejected) {
      setShowApplicationSideRail(false);
      setClientApplicationId('');
      setClientApplicationTitle(DefaultClientAppTitle);
    }
  }, [createApplicationIsFulfilled]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  // render account tabs
  const renderTabPanes = (index: TabIndex) => {
    switch (index) {
      case TabIndex.Merchants:
        return (
          <>
            {renderMerchantTab()}
          </>);
      case TabIndex.Hierarchy:
        return (
          <>
            {renderHierarchyTab()}
          </>);
    }
  };




  const ClientTree = ({ clientNodes }: { clientNodes: ClientNode[] }) => {

    const renderTree = (nodes: ClientNode) => (
      <StyledTreeItem nodeId={nodes.client.Id.toString()} label={nodes.client.Name}>
        {Array.isArray(nodes.children) ? nodes.children.map(node => renderTree(node)) : null}
      </StyledTreeItem>);

    if (!clientNodes || clientNodes.length < 1) {
      return <Table stickyHeader aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell colSpan={10} align="center">
              No Data To Show
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    }

    return (
      <>
        <TreeView

          aria-label="file system navigator"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{ height: "calc(100vh - 205px)", flexGrow: 1, maxWidth: '100%', overflowY: 'auto' }}
        >
          {(clientNodes && clientNodes.length > 0) ? clientNodes.map(node => renderTree(node)) : null}
        </TreeView>
      </>
    );
  };


  const renderHierarchyTab = () => {

    
    // return (<>
    //   <ClientTree clientNodes={clientNodes} />
    // </>)

    return (<div style={{ height: "calc(100vh - 205px)", flexGrow: 1, maxWidth: '100%', overflowY: 'auto' }}>
      <SortableTree
        canDrag={false}
        treeData={clientNodes}
        onChange={treeData => setClientNodes(treeData)}
      />
    </div>);
  }


  // render merchant tab pane
  const renderMerchantTab = () => {
    return <MerchantTable />;
  };

  const handleTabChange = (event: React.SyntheticEvent, index: number) => {
    setActiveTabIndex(index);
  };

  const renderApplicationTitle = () => {
    return (
      <Box width="100%" className="MerchantRailTitle">
        <Box width="100%">
          <Typography
            fontSize="x-large"
            fontWeight="bold"
            className="SideRailTitleStyle"
          >
            {clientApplicationTitle}
          </Typography>
        </Box>
        <Typography fontSize="medium" margin={{ top: 'xsmall' }}>
          Application ID: {clientApplicationId}
        </Typography>
      </Box>
    );
  };

  const handleOnChange = (values: any) => {
    setClientApplicationValues(values);
  };

  const handleMerchantAppTitleChange = (title: string) => {
    setClientApplicationTitle(
      title && title?.trim() ? title : DefaultClientAppTitle
    );
  };

  const renderApplicationForm = () => {
    return (
      <MerchantApplicationForm
        onChange={handleOnChange}
        onMerchantAppTitleChange={handleMerchantAppTitleChange}
        setIsMerchantAppFormValid={(value) => {
          setIsMerchantAppFormValid(value);
        }}
      ></MerchantApplicationForm>
    );
  };

  const handleCreateMerchantApplication = () => {
    clientApplicationValues.Id = ApplicationId;
    clientApplicationValues.IsGatewayClient = true;
    clientApplicationValues.PhoneNumber =
      '+' + clientApplicationValues.PhoneNumber;
    dispatch(thunkCreatMerchantApplication(clientApplicationValues));
  };

  const renderMerchantApplicationRail = () => {
    return (
      <SideRail
        open={showApplicationSideRail}
        title={renderApplicationTitle()}
        onClose={() => {
          setShowApplicationSideRail(false);
          setClientApplicationId('');
        }}
        footer={
          <Box className="footerClass EditButtonAlign">
            <Button
              variant="outlined"
              className="SideRailfooterButtonStyle"
              onClick={() => {
                setClientApplicationId('');
                setShowApplicationSideRail(false);
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              className="SideRailfooterButtonStyle"
              onClick={() => {
                handleCreateMerchantApplication();
              }}
              disabled={!isMerchantAppFormValid || createApplicationIsPending}
            >
              {createApplicationIsPending && (
                <CircularProgress
                  sx={{ marginRight: '15px' }}
                  size={24}
                ></CircularProgress>
              )}
              SUBMIT
            </Button>
          </Box>
        }
      >
        <Box>{renderApplicationForm()}</Box>
      </SideRail>
    );
  };

  return (
    <Grid className="ReportingPageStyle" container>
      <Grid container>
        <div className="PageHeaderStyle">
          <div>
            <Typography variant="h5" color="primary">
              Accounts
            </Typography>
          </div>
          {activeTabIndex === 0 && (
            <Box className="DownloadButtonBoxStyle">
              <Button
                variant="outlined"
                className="DownloadButtonStyle"
                onClick={() => {
                  dispatch(thunkCreatMerchantApplicationID());
                  setShowApplicationSideRail(true);
                }}
              >
                <Add fontSize="small" />
                New Application
              </Button>
            </Box>
          )}
        </div>
      </Grid>

      <Grid container style={{ width: "100%" }}>
        <Grid container direction="row" className='TabsContainer'>
          <Tabs
            className="TabsStyle"
            textColor="primary"
            value={activeTabIndex}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            {/* DO NOT DELETECOMMENTED AS NOT IMPLEMENTED
          THE TAB INDEX SHOULD BE 2 AND NOT 0 */}
            <Tab className="TabStyle" label="Applications" {...a11yProps(0)} />
            <Tab
              className="TabStyle DisplayNone"
              label="Underwriting"
              {...a11yProps(1)}
            />
            <Tab className="TabStyle" label="Merchants" {...a11yProps(2)} />
            <Tab
              className="TabStyle"
              label="Hierarchy"
              {...a11yProps(3)}
            />
          </Tabs>
        </Grid>
      </Grid>

      <Grid container direction="row">
        <div className={"ReportingMainPageDisplayArea"}>
          {renderTabPanes(activeTabIndex)}
          {renderMerchantApplicationRail()}
        </div>
      </Grid>

    </Grid>
  );
};
export default Accounts;
