/**
 * Validation schema for user profile page
 * Validation is done using YUP
 */
import * as Yup from 'yup';
import {
  requiredTextFieldValidationRule,
  reuiredSelectFieldValidationRule,
} from '../../Forms/validationRules';

export const validationSchema = (requiredFields: any) =>
  Yup.object().shape({
    FirstName: Yup.lazy(() => {
      let validationSchema = requiredTextFieldValidationRule('First Name');
      return validationSchema;
    }),
    LastName: Yup.lazy(() => {
      let validationSchema = requiredTextFieldValidationRule('Last Name');
      return validationSchema;
    }),
    Timezone: Yup.lazy(() => {
      let validationSchema = reuiredSelectFieldValidationRule('Time Zone');
      return validationSchema;
    }),
  });
