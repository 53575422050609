import {
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import useCurrency from '../../Hooks/useCurrency';
import { toTitleCase } from '../../libs/helpers';
import { DATETIME_FORMAT } from '../../store/constants';
import { BatchTableData } from '../../types/Transactions';
import './BatchTable.scss';

interface BatchTableProps {
  batches?: Array<BatchTableData>;
  handleOnMore: () => void;
  onViewDetailsClick: (row: BatchTableData) => void;
}

const BatchTable: React.FC<BatchTableProps> = ({
  batches = [],
  handleOnMore,
  onViewDetailsClick,
}) => {
  const { toCurrency } = useCurrency();

  const renderDefaultMessage = () => (
    <TableRow>
      <TableCell colSpan={10} align="center">
        No Data To Show
      </TableCell>
    </TableRow>
  );

  const renderTableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell width="8%" className="TransactionTableHeader">
          Batch ID
        </TableCell>
        <TableCell width="8%" className="TransactionTableHeader">
          Type
        </TableCell>
        <TableCell width="10%" className="TransactionTableHeader">
          Status
        </TableCell>
        <TableCell width="10%" className="TransactionTableHeader">
          Net Amount
        </TableCell>
        <TableCell className="TransactionTableHeader">Open Date</TableCell>
        <TableCell className="TransactionTableHeader">Close Date</TableCell>
        <TableCell className="TransactionTableHeader">Settle Date</TableCell>
        <TableCell width="12%" className="TransactionTableHeader">
          Merchant DBA
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const renderTableBody = () => (
    <TableBody>
      {batches?.length > 0 ? renderBatchesRows() : renderDefaultMessage()}
    </TableBody>
  );

  const formatBatchTypeName = (processer: string) => {
    if (processer && processer.toLowerCase() === 'tsys') {
      processer = processer?.toUpperCase();
    } else {
      processer = toTitleCase(processer);
    }
    return processer;
  };

  const renderBatchesRows = () =>
    batches?.map((row) => {
      return (
        <TableRow
          key={Math.random()}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          className="TransactionTableRowStyle"
          onClick={() => onViewDetailsClick(row)}
        >
          <TableCell>{row?.BatchId ? row.BatchId : '-'}</TableCell>
          <TableCell>
            {row?.Processer ? formatBatchTypeName(row.Processer) : '-'}
          </TableCell>
          <TableCell>{row?.Status ? row.Status : '-'}</TableCell>
          <TableCell align="right">
            {row?.BatchNetAmount
              ? toCurrency(
                Number(row.BatchNetAmount).toFixed(2).toString(),
                '.',
                '$',
                ','
              )
              : '-'}
          </TableCell>
          <TableCell>
            {row?.CreatedAtDateTime
              ? moment(row.CreatedAtDateTime).format(DATETIME_FORMAT)
              : '-'}
          </TableCell>
          <TableCell>
            {row?.ClosedAtDateTime
              ? moment(row.ClosedAtDateTime).format(DATETIME_FORMAT)
              : '-'}
          </TableCell>
          <TableCell>
            {row?.SettledAtDateTime
              ? moment(row.SettledAtDateTime).format(DATETIME_FORMAT)
              : '-'}
          </TableCell>
          <TableCell>{row?.ClientName ? row.ClientName : '-'}</TableCell>
        </TableRow>
      );
    });

  return (
    <>
      <InfiniteScroll
        hasMore={false}
        dataLength={batches?.length}
        next={handleOnMore}
        loader={<></>}
        scrollableTarget="batchtableID"
      >
        <TableContainer
          id={'batchtableID'}
          className="BatchTable"
          component={Paper}
        >
          <Table stickyHeader aria-label="simple table">
            {renderTableHeader()}
            {renderTableBody()}
          </Table>
        </TableContainer>
      </InfiniteScroll>

    </>

  );
};
export default BatchTable;
