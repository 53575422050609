/*
 Transaction Checkbox Filter Card
*/
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  TRANSACTIONS,
  CARD_NETWORKS,
  CREATED_STATUS,
} from '../../store/constants';

interface CheckBoxProps {
  tranTypeFilter?: any;
  cardTypeFilter?: any;
  statusFilter?: any;
  getListOfTranTypeChecked?: (value: any) => void;
  getListOfCardChecked?: (value: any) => void;
  getListOfStatusChecked?: (value: any) => void;
}

const TransactionCheckBoxFilter: React.FC<CheckBoxProps> = ({
  tranTypeFilter,
  cardTypeFilter,
  statusFilter,
  getListOfTranTypeChecked,
  getListOfCardChecked,
  getListOfStatusChecked,
}) => {
  const [tranTypeCheckedList, setTranTypeCheckedList] =
    useState(tranTypeFilter);
  const [cardCheckedList, setCardCheckedList] = useState(cardTypeFilter);
  const [statusCheckedList, setStatusCheckedList] = useState(statusFilter);

  const handleTranTypeCheckedList = ({ key, value }: any) => {
    const inArray = tranTypeCheckedList.some((item: any) => item.key === key);
    if (inArray) {
      setTranTypeCheckedList(
        tranTypeCheckedList.filter((item: any) => item.key !== key)
      );
    } else {
      setTranTypeCheckedList([...tranTypeCheckedList, { key, value }]);
    }
  };

  const handleNetworkCheckedList = ({ key, value }: any) => {
    const inArray = cardCheckedList?.some((item: any) => item.key === key);
    if (inArray) {
      setCardCheckedList(
        cardCheckedList?.filter((item: any) => item.key !== key)
      );
    } else {
      setCardCheckedList([...cardCheckedList, { key, value }]);
    }
  };

  const handleStatusCheckedList = ({ key, value }: any) => {
    const inArray = statusCheckedList?.some((item: any) => item.key === key);
    if (inArray) {
      setStatusCheckedList(
        statusCheckedList?.filter((item: any) => item.key !== key)
      );
    } else {
      setStatusCheckedList([...statusCheckedList, { key, value }]);
    }
  };

  useEffect(() => {
    getListOfTranTypeChecked && getListOfTranTypeChecked(tranTypeCheckedList);
    getListOfCardChecked && getListOfCardChecked(cardCheckedList);
    getListOfStatusChecked && getListOfStatusChecked(statusCheckedList);
  }, [
    cardCheckedList,
    getListOfCardChecked,
    getListOfStatusChecked,
    getListOfTranTypeChecked,
    statusCheckedList,
    tranTypeCheckedList,
  ]);

  return (
    <Box className="CheckboxCard">
      <Box className="InnerBox">
        <Typography>Trans Type</Typography>
        {TRANSACTIONS.map(({ key, value }: { key: string; value: string }) => (
          <FormGroup className="CheckBoxGroup" key={key}>
            <FormControlLabel
              sx={{ color: '#36454F' }}
              control={<Checkbox className="CheckBox" size="small" />}
              onChange={() => handleTranTypeCheckedList({ key, value })}
              label={value}
              name={value}
              checked={tranTypeCheckedList.some(
                (item: any) => item.value === value
              )}
            />
          </FormGroup>
        ))}
      </Box>
      <Box className="MiddleBox">
        <Typography>Card Networks</Typography>
        {CARD_NETWORKS.map(({ key, value }: { key: string; value: string }) => (
          <FormGroup className="CheckBoxGroup" key={key}>
            <FormControlLabel
              sx={{ color: '#36454F' }}
              control={<Checkbox className="CheckBox" size="small" />}
              onChange={() => handleNetworkCheckedList({ key, value })}
              label={value}
              name={value}
              checked={cardCheckedList.some(
                (item: any) => item.value === value
              )}
            />
          </FormGroup>
        ))}
      </Box>
      <Box className="RightBox">
        <Typography>Response</Typography>
        {CREATED_STATUS.map(
          ({ key, value }: { key: string; value: string }) => (
            <FormGroup className="CheckBoxGroup" key={key}>
              <FormControlLabel
                sx={{ color: '#36454F' }}
                control={<Checkbox className="CheckBox" size="small" />}
                onChange={() => handleStatusCheckedList({ key, value })}
                label={value}
                name={value}
                checked={statusCheckedList.some(
                  (item: any) => item.value === value
                )}
              />
            </FormGroup>
          )
        )}
      </Box>
    </Box>
  );
};

export default TransactionCheckBoxFilter;
