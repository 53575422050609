/**
 * Validation schema for signin form
 * Validation is done using YUP
 */
import * as Yup from 'yup';
import {
  emailValidationRule,
  phoneNumberValidationRuleFor11Digits,
} from '../validationRules';

export const validationSchema1 = Yup.object().shape({
  email: Yup.lazy((val: string) => {
    let validationSchema = emailValidationRule('Email address', true);
    return validationSchema;
  }),
});

export const validationSchema2 = Yup.object().shape({
  phoneNumber: Yup.lazy((val: string) => {
    let validationSchema = phoneNumberValidationRuleFor11Digits(
      'Phone number',
      true
    );
    return validationSchema;
  }),
});
