import exportFromJSON from 'export-from-json';
import { IOption } from 'export-from-json/dist/types/exportFromJSON';
export enum ExportType {
  csv,
  xls,
}
export class Exporter {
  constructor(
    private data: any,
    private fileName: any,
    private type: ExportType
  ) {}

  public export = () => {
    if (this.type === ExportType.csv) {
      return this.exportToCSV();
    } else if (this.type === ExportType.xls) {
      return this.exportToXLS();
    }
  };

  private exportToCSV = () => {
    let data: IOption<void> = this.data;
    let fileName = this.fileName;
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };

  private exportToXLS = () => {
    let data: IOption<void> = this.data;
    let fileName = this.fileName;
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };
}
