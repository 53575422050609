/**
 * Reporting transactions
 */
import typeToReducer from 'type-to-reducer';
import { ReducerAction, ReducerState } from '../../../../types/common';
import { EDIT_USER_PROFILE } from '../../../actions/types';
import { DEFAULT_ASYNC_STATE } from '../../../constants';

const DEFAULT_STATE = {
  ...DEFAULT_ASYNC_STATE,
  error: false,
  message: { count: 0, results: [] },
};

export default typeToReducer(
  {
    [EDIT_USER_PROFILE]: {
      PENDING: () => ({ ...DEFAULT_STATE, isPending: true }),
      FULFILLED: (
        state: ReducerState | undefined,
        action: ReducerAction | any
      ) => {
        const { payload } = action;
        return {
          ...state,
          ...DEFAULT_STATE,
          ...payload,
          isFulfilled: true,
          isPending: false,
        };
      },
      REJECTED: (
        state: ReducerState | undefined,
        action: ReducerAction | any
      ) => {
        const { payload } = action;
        return {
          ...state,
          ...DEFAULT_STATE,
          ...payload,
          isRejected: true,
          isPending: false,
        };
      },
    },
  },
  DEFAULT_STATE
);
