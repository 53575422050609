/**
 * Auth provider class
 * Brings auth context to the app.
 * Easy to react login, logout, refresh methods
 * And 'auth' section of global state
 */
import React, { createContext, ReactNode } from 'react';
import { clearStatusMessagesAction } from '../../../store/actions/appActions';
import Cookies from 'universal-cookie';
import { useStore } from 'lnox';
import moment from 'moment';

interface AuthProviderProps {
  children?: ReactNode;
}

export interface AuthTokenData {
  exp: number;
  must_select_hierarchy: string;
  nbf?: number;
  userId?: string;
  hierarchyId?: string;
  sid?: string;
}

export interface AuthProviderValue {
  login: (email: string, password: string, ipAddress: string) => void;
  logout: () => void;
  refresh: () => void;
  // auth: any;
  scheduleTokenRefresh: () => void;
}

export const AuthContext = createContext({} as AuthProviderValue);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const {
    // state: {
    //   auth: { authentication },
    // },
    dispatch,
  } = useStore();

  const login = async (login: string, password: string, ipAddress: string) => {
    dispatch(clearStatusMessagesAction());
    // ToDo - add login api here
  };

  const logout = () => {
    dispatch(clearStatusMessagesAction());
    // ToDo - add logout api here
  };

  const refresh = () =>
    new Promise((resolve) => {
      // ToDo - add refresh api here
      dispatch(clearStatusMessagesAction());
      resolve({});
    });

  const extractTokenData = (token: string) => {
    const { exp } = { exp: 123 };
    return { exp };
  };

  const isTokenExpired = (exp: number) => exp < moment().unix();

  /**
   * Schedules the next auth token refresh
   */
  const scheduleTokenRefresh = () => {
    const cookies = new Cookies();
    const token = cookies.get('accessToken');

    if (token) {
      const { exp } = extractTokenData(token);

      if (isTokenExpired(exp)) {
        logout();
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        refresh,
        // auth: authentication,
        scheduleTokenRefresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
