/**
 * Users Page
 */
import React, { useEffect, useState, KeyboardEvent } from 'react';
import { Add, Close, SaveAlt } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useStore } from 'lnox';
import SideRail from '../../components/Common/SideRail';
import UserListTable from '../../components/UserListTable';
import UserRolesListTable from '../../components/UserRolesListTable';
import ViewUserDetails from '../../components/UserDetails/ViewUserDetails';
import useDidMountEffect from '../../Hooks/useDidMountEffect';
import {
  thunkUsersFetchAction,
  thunkUsersFetchByIdAction,
  thunkExportingFetchUsersAction,
  thunkCreateUserAction,
  thunkUpdateUserAction,
} from '../../store/actions/userActions';
import './Users.scss';
import "../../components/Layout/Layout.scss";
import { Exporter, ExportType } from '../../libs/ExportHelper';
import moment from 'moment';
import { DATETIME_FORMAT, PERMISSION_ROLES } from '../../store/constants';
import AddUser from '../../components/AddUser';
import { searchObjectForString } from '../../libs/helpers';

export enum TabIndex {
  Users = 0,
  Roles,
}

export enum UserDetailsTabIndex {
  General = 0,
  SessionData = 1,
  Notes = 2,
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Users: React.FC<{}> = (): JSX.Element => {

  const ADMIN_USER_EMAIL = "administrator@pacesoft.net"

  const {
    dispatch,
    state: {
      user,
      auth: { clientId },
    },
  } = useStore();

  const {
    user: {
      fetch: {
        isPending,
        isFulfilled,
        message: { AllUsers: userResults = [] },
      },
      fetchById: {
        isPending: fetchByIdIsPending,
        isFulfilled: fetchByIdIsFulfilled,
        message: { UserDetails: userDetails = {} },
      },
      create: {
        isPending: createUserIsPending,
        isFulfilled: createUserIsFulfilled,
      },
      update: {
        error: updateUserErrorStatus,
        isPending: updateUserIsPending,
        isFulfilled: updateUserIsFulfilled,
      },
    },
  } = user;

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [showUserSideRail, setShowUserSideRail] = useState(false);
  const [showBackButton, setShowBackButton] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [selectedUserRow, setSelectedUserRow] = useState<any>();
  const [userDetailsActiveTabIndex, setUserDetailsActiveTabIndex] = useState(0);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [userFormValues, setUserFormValues] = useState<any>({
    userFormStatus: true,
  });
  const [isEditUser, setIsEditUser] = useState<boolean>(false);
  const [isUserFormValid, setIsUserFormValid] = useState<boolean>(true);



  useEffect(() => {
    if (Object.keys(userDetails)?.length && selectedUserId != '') {
      setSelectedUserRow(userDetails);
      setUserFormValues(userDetails);
    }
  }, [userDetails]);

  const createUser = async () => {
    if (userFormValues?.Id) {
      let payload = {
        Id: userFormValues?.Id || '',
        FirstName: userFormValues?.FirstName || '',
        LastName: userFormValues?.LastName || '',
        Password: userFormValues?.Password || '',
        Timezone: userFormValues?.Timezone || '',
        //Email: userFormValues?.Email || '',
        //PhoneNumber: userFormValues?.PhoneNumber || '',
        Is2faEnabled: userFormValues?.Is2faEnabled || false,
        RoleClients: [],
      };
      let response = await dispatch(thunkUpdateUserAction(payload));
      if (!response?.value?.error) {
        dispatch(
          thunkUsersFetchByIdAction({
            id: selectedUserId,
          })
        );
        setIsNewUser(false);
        setUserFormValues({});
        fetchUsersList();
      }
    } else {
      let payload = {
        FirstName: userFormValues?.FirstName || '',
        LastName: userFormValues?.LastName || '',
        Password: userFormValues?.Password || '',
        Timezone: userFormValues?.Timezone || '',
        Email: userFormValues?.Email || '',
        PhoneNumber: userFormValues?.PhoneNumber?.slice(1) || '',
        Is2faEnabled: userFormValues?.Is2faEnabled || false,
        RoleClients: [{ RoleId: 1, ClientIds: [clientId] }],
      };
      let response = await dispatch(thunkCreateUserAction(payload));
      if (!response?.value?.error) {
        setIsNewUser(false);
        setUserFormValues({});
        setShowUserSideRail(false);
        fetchUsersList();
      }
    }
  };

  const fetchUsersList = (search?: any, filter?: any) => {
    if (isPending === true) {
      return;
    }

    const queryParams = {
      take: '1000',
      sortColumn: 'FirstName',
      sortOrder: '1',
      skip: '0',
      searchText: searchTerm,
    };
    dispatch(thunkUsersFetchAction(queryParams));
  };
  const handleuserDetailsTabChange = (
    event: React.SyntheticEvent,
    index: number
  ) => {
    setUserDetailsActiveTabIndex(index);
  };

  const fetchExportData = async () => {
    const response: any = dispatch(
      thunkExportingFetchUsersAction({
        searchText: searchTerm,
        sortColumn: 'FirstName',
        sortOrder: '1',
        take: '1000',
      })
    );
    return response;
  };

  const formatExportData = (results: any) => {
    let data: any = [];
    results?.forEach((transaction: any) => {
      let userData = {
        Name: `${transaction?.FirstName} ${transaction?.LastName}`,
        'Primary Email': transaction?.Email,
        Status: transaction?.IsActive ? 'Active' : 'InActive',
        'Merchant DBA': transaction?.ClientName,
        'Last Access': transaction?.LastAccess
          ? `${moment(transaction?.LastAccess).format(DATETIME_FORMAT)}`
          : '',
        'Date Added': `${moment(transaction?.DateAdded).format(
          DATETIME_FORMAT
        )}`,
      };
      data.push(userData);
    });
    return data;
  };

  const getExportFileName = () => {
    return 'USER_REPORT';
  };

  //download user button csvExport file
  const handleUsersCSVExport = async () => {
    const response: any = (await fetchExportData()) || [];
    if (
      response &&
      response.message &&
      response.message.AllUsers &&
      response.message.AllUsers.length
    ) {
      const exportType = ExportType.csv;
      const data: any = formatExportData(response.message.AllUsers) || [];
      const fileName = getExportFileName();
      new Exporter(data, fileName, exportType).export();
    }
  };


  const handleUsersPageTabChange = (
    event: React.SyntheticEvent,
    index: number
  ) => {
    setActiveTabIndex(index);
  };

  const renderTabPanes = (index: TabIndex) => {
    switch (index) {
      case TabIndex.Users:
        return (
          <>
            {renderUsersTab()}
          </>
        );
      case TabIndex.Roles:
        return (
          <>
            {renderUserRolesTab()}
          </>
        );
    }
  };

  const renderUserDetailsTitle = () => {
    return (
      <Box width="100%">
        <Box width="100%">
          <Typography
            fontSize="x-large"
            fontWeight="bold"
            className="SideRailTitleStyle"
          >
            {!isEditUser &&
              selectedUserRow &&
              selectedUserId &&
              selectedUserRow?.FirstName &&
              selectedUserRow?.LastName
              ? `${selectedUserRow?.FirstName || ''} ${selectedUserRow?.LastName || ''
              }`
              : userFormValues && userFormValues?.FirstName
                ? `${userFormValues?.FirstName || ''} ${userFormValues?.LastName || ''
                }`
                : isNewUser
                  ? 'Full Name'
                  : 'User Details'}
          </Typography>
        </Box>
        <Typography
          fontSize="medium"
          margin={{ top: 'xsmall', left: `${showBackButton ? '30px' : '0px'}` }}
        >
          {selectedUserId && !isNewUser ? `User ID: ${selectedUserId}` : ''}
        </Typography>
        <Box width="100%">
          <Tabs
            className="UserDetailsTabsStyle"
            value={userDetailsActiveTabIndex}
            onChange={handleuserDetailsTabChange}
            aria-label="basic tabs example"
          >
            <Tab className="TabStyle" label="General" {...a11yProps(0)} />
            <Tab
              className="TabStyle DisplayNone"
              label="Session Data"
              {...a11yProps(1)}
            />
            <Tab
              className="TabStyle DisplayNone"
              label="Notes"
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
      </Box>
    );
  };


  const renderUserDetails = () => {
    return (
      <SideRail
        open={showUserSideRail}
        title={renderUserDetailsTitle()}
        onClose={() => {
          setShowUserSideRail(false);
          setIsNewUser(false);
          setIsEditUser(false);
          setSelectedUserRow({});
          setUserFormValues({});
        }}
        footer={
          isNewUser ? (
            <Box className="footerClass EditButtonAlign">
              <Button
                variant="outlined"
                className="SideRailfooterButtonStyle"
                disabled={createUserIsPending || updateUserIsPending}
                onClick={() => {
                  setUserFormValues({});
                  if (isEditUser) {
                    setIsNewUser(false);
                    setIsEditUser(false);
                  } else {
                    setShowUserSideRail(false);
                    setIsNewUser(false);
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="SideRailfooterButtonStyle"
                onClick={createUser}
                disabled={
                  createUserIsPending || updateUserIsPending || isUserFormValid
                }
              >
                <SaveIcon />
                Save
              </Button>
            </Box>
          ) : (
            <Box className="footerClass EditButtonAlign">
              <Button
                variant="contained"
                className="SideRailfooterButtonStyle"
                disabled={fetchByIdIsPending && !fetchByIdIsFulfilled}
                onClick={() => {
                  setIsNewUser(true);
                  setShowUserSideRail(true);
                  setIsEditUser(true);
                }}
              >
                <EditIcon />
                Edit
              </Button>
            </Box>
          )
        }
      >
        <Box>{renderUserDetailsTabPanes(userDetailsActiveTabIndex)}</Box>
      </SideRail>
    );
  };

  const renderUserDetailsSessionTab = () => {
    return <Box>Session Details tab</Box>;
  };

  const renderUserDetailsNotesTab = () => {
    return <Box>Users notes tab</Box>;
  };

  const renderUserDetailsGeneralTab = () => {
    return isNewUser ? (
      <AddUser
        isEdit={isEditUser}
        onChange={(userValue) => {
          setUserFormValues(userValue);
        }}
        values={selectedUserRow}
        setIsUserFormValid={(value) => {
          setIsUserFormValid(value);
        }}
      />
    ) : (
      <ViewUserDetails
        selectedUserId={selectedUserId}
        selectedUserInfo={selectedUserRow}
      />
    );
  };

  const renderUserDetailsTabPanes = (index: UserDetailsTabIndex) => {
    switch (index) {
      case UserDetailsTabIndex.General:
        return (
          <>
            {renderUserDetailsGeneralTab()}
          </>
        );
      case UserDetailsTabIndex.SessionData:
        return (
          <>
            {renderUserDetailsSessionTab()}
          </>
        );
      case UserDetailsTabIndex.Notes:
        return (
          <>
            {renderUserDetailsNotesTab()}
          </>
        );
    }
  };

  const handleViewUserDetails = async (row: any) => {
    setSelectedUserRow({});
    await dispatch(
      thunkUsersFetchByIdAction({
        id: row.Id,
      })
    );
    setSelectedUserId(row.Id);
    setShowUserSideRail(true);
  };


  const renderUsersTab = () => {
    return (
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container direction={"row"}>
          <div className={"ReportingMainPageItemRowContent"}>
            {renderUserFilter()}
          </div>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container direction={"row"}>
          <div className={"ReportingMainPageItemRowBottomContent"}>
            {renderUserListTable()}
          </div>
        </Grid>
      </Grid>
    );
  };

  const renderUserRolesTab = () => {
    return (
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container direction={"row"}>
          <div className={"ReportingMainPageItemRowContent"}>
            {renderUserRolesFilter()}
          </div>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container direction={"row"}>
          <div className={"ReportingMainPageItemRowBottomContent"}>
            {renderUserRolesListTable()}
          </div>
        </Grid>
      </Grid>
    );
  };


  const renderUserListTable = () => {
    const searchWord = (searchTerm) ? searchTerm.trim() : "";

    let results: [] = [];

    if (searchWord === "") {
      results = userResults;
    } else if (userResults) {
      results = userResults.filter((transRow: any) => {
        return searchObjectForString(searchWord, transRow);
      });
    }

    return (
      <>
        {isPending && (
          <CircularProgress
            sx={{ marginLeft: '45%', marginTop: '2%' }}
            size={24}
          ></CircularProgress>
        )}
        {isPending === false && <UserListTable
          users={results.filter((item: any) => { return item.Email !== ADMIN_USER_EMAIL })}
          onViewDetailsClick={handleViewUserDetails}
        />}
      </>
    );
  };

  const renderUserFilter = () => {
    return (
      <Box className="TrasactionFilterBox">
        <TextField
          placeholder="Search"
          className="SearchBox"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          onKeyDown={(e: KeyboardEvent<any>) => {
            if (e.keyCode === 13) {
              fetchUsersList();
            }
          }}
          value={searchTerm}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="start">
                <IconButton>
                  <Close
                    color={'primary'}
                    onClick={() => {
                      setSearchTerm('');
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  };


  const renderUserRolesListTable = () => {
    const searchWord = (searchTerm) ? searchTerm.trim() : "";

    let results: any [] = [];

    if (searchWord === "") {
      results = PERMISSION_ROLES;
    } else if (PERMISSION_ROLES) {
      results = PERMISSION_ROLES.filter((transRow: any) => {
        return searchObjectForString(searchWord, transRow);
      });
    }

    return (
      <>
        {isPending && (
          <CircularProgress
            sx={{ marginLeft: '45%', marginTop: '2%' }}
            size={24}
          ></CircularProgress>
        )}
        {isPending === false && <UserRolesListTable
          roles={results}
        />}
      </>
    );
  };

  const renderUserRolesFilter = () => {
    return (
      <Box className="TrasactionFilterBox">
        <TextField
          placeholder="Search"
          className="SearchBox"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          onKeyDown={(e: KeyboardEvent<any>) => {
            if (e.keyCode === 13) {
            }
          }}
          value={searchTerm}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="start">
                <IconButton>
                  <Close
                    color={'primary'}
                    onClick={() => {
                      setSearchTerm('');
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  };

  return (
    <Grid className="UsersListPageStyle" container>
      <Grid container className={"PageHeaderStyle"}>
        <Grid item>
          <Typography variant="h5" color="primary">
            Users
          </Typography>
        </Grid>
        <Grid item
          container
          direction="row"
          justifyContent={"flex-end"} >
          <table style={{ marginTop: -40 }}>
            <tbody>
              <tr>
                {userResults && userResults.length > 0 && (
                  <td>
                    <Hidden only={['xs']}>
                      <Button
                        variant="outlined"
                        onClick={handleUsersCSVExport}
                        className="DownloadButtonStyle"
                      >
                        <SaveAlt fontSize="small" className="DownloadIconStyle" />{' '}
                        Download Report
                      </Button>
                    </Hidden>

                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                      <Button
                        variant="outlined"
                        onClick={handleUsersCSVExport}
                        className="DownloadButtonStyle"
                        style={{ maxWidth: 140 }}
                      >
                        <SaveAlt fontSize="small" className="DownloadIconStyle" />{' '}
                        Download...
                      </Button>
                    </Hidden>
                  </td>
                )}
                <td>
                  <Button
                    variant="outlined"
                    className="DownloadButtonStyle"
                    onClick={() => {
                      setIsNewUser(true);
                      setShowUserSideRail(true);
                    }}
                  >
                    <Add fontSize="small" />
                    New User
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>

      <Grid container style={{ width: "100%" }}>
        <Grid container direction="row" className='TabsContainer'>
          <Tabs
            className="TabsStyle"
            value={activeTabIndex}
            onChange={handleUsersPageTabChange}
            aria-label="basic tabs example"
          >
            <Tab className="TabStyle" label="Users" {...a11yProps(0)} />
            <Tab
              className="TabStyle"
              label="Roles"
              {...a11yProps(1)}
            />
          </Tabs>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <div style={{ width: "100%" }}>
          {renderTabPanes(activeTabIndex)}
          {renderUserDetails()}
        </div>
      </Grid>
    </Grid>
  );
};

export default Users;
