/**
 * Application common types and interfaces
 */

import { ChangeEvent } from 'react';

export interface ReducerState {
  [key: string]: any;
}

export interface ReducerAction {
  type: string;
  payload?: any;
}

export enum StatusMessageType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export interface StatusMessage {
  text: string;
  type: StatusMessageType;
  description?: string;
}

export type SelectOption = {
  key: string;
  value: string;
  title?: string;
};

export type Currency = {
  name: string;
  label: string;
  symbol: string;
  decimalSeparator: string;
  thousandSeparator: string;
};

export type MoneyInputProps = {
  id?: string;
  value?: any;
  maxLength?: number;
  onChange?: (e: ChangeEvent<any>) => void;
  disabled?: boolean;
  transactionType?: string;
};

export type MaskInputProps = {
  onChange?: any;
  name?: any;
  mask?: any;
};
