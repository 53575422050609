/**
 * Transaction Check Details
 */
import { Box, Button, CircularProgress, Fade } from '@mui/material';
import React from 'react';
import './TransactionBottomBar.scss';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';

interface TransactionBottomBarProps {
  transactionTypeLabel?: string;
  transactionAmount?: string;
  handleTerminalReset: () => void;
  handleTransactionAction: () => void;
  disabled?: boolean;
  transactionStatus?: boolean;
  isTerminalSubmitted?: boolean;
  componentToPrint?: any;
}

const TransactionBottomBar: React.FC<TransactionBottomBarProps> = ({
  transactionTypeLabel,
  transactionAmount,
  handleTerminalReset,
  handleTransactionAction,
  disabled = false,
  transactionStatus = false,
  isTerminalSubmitted = false,
  componentToPrint,
}) => {
  const renderPrintButton = () => {
    return (
      <ReactToPrint
        trigger={() => (
          <Button
            variant="contained"
            startIcon={<PrintIcon />}
            className="TransactionPrintButtonStyle"
          >
            PRINT
          </Button>
        )}
        content={() => componentToPrint}
      />
    );
  };
  return (
    <Box className="TransactionBottomBarContainer">
      <Fade in={isTerminalSubmitted} unmountOnExit className="SpinnerStyle">
        <CircularProgress size={26} />
      </Fade>
      <Box className="BottomButtonContainerStyle">
        <Button
          variant="outlined"
          className="TransactionResetButtonStyle"
          onClick={handleTerminalReset}
          disabled={isTerminalSubmitted}
        >
          {transactionStatus ? 'NEW TRANSACTION' : 'RESET'}
        </Button>
        {transactionStatus && renderPrintButton()}
        {!transactionStatus && (
          <Button
            variant="contained"
            className="TransactionButtonStyle"
            onClick={handleTransactionAction}
            disabled={disabled || isTerminalSubmitted}
          >
            {transactionAmount
              ? `${transactionTypeLabel}: ${transactionAmount}`
              : transactionTypeLabel}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default TransactionBottomBar;
