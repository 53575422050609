import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import moment from 'moment';
import SettingsRow from '../../components/Common/SettingsRow';
import useCurrency from '../../Hooks/useCurrency';
import { DATETIME_FORMAT } from '../../store/constants';
import { TransactionTableData } from '../../types/Transactions';
import SettingsBlock from '../Common/SettingsBlock';
import TransactionTable from '../TransactionTable';
import './BatchDetails.scss';

interface BatchDetailsProps {
  transactions?: Array<any>;
  batchDetails?: any;
  onViewDetailsClick: (row: TransactionTableData) => void;
  handleOnMore: () => void;
  isBatchInfoPending?: any;
}

const BatchDetails: React.FC<BatchDetailsProps> = ({
  transactions = [],
  batchDetails,
  onViewDetailsClick,
  handleOnMore,
  isBatchInfoPending,
}) => {
  const { toCurrency } = useCurrency();

  const getTransTypeCount = (type: string) => {
    if (type === 'void') {
      return transactions?.filter(
        (x) =>
          x.TransactionType?.toLowerCase() === type &&
          x.ResponseType?.toLowerCase() === 'approved' &&
          !['reversal', 'reversed'].includes(x.LastAction?.toLowerCase())
      ).length;
    } else if (type === 'reversal') {
      return transactions?.filter(
        (x) =>
          ['reversal', 'reversed'].includes(x.LastAction?.toLowerCase()) &&
          x.ResponseType?.toLowerCase() === 'approved'
      ).length;
    } else {
      return transactions?.filter(
        (x) =>
          x.TransactionType?.toLowerCase() === type &&
          x.ResponseType?.toLowerCase() === 'approved'
      ).length;
    }
  };

  const getNetworkTypeCount = (type: string) => {
    if (type === 'others') {
      return transactions?.filter(
        (x) =>
          !['visa', 'mastercard', 'amex', 'discover'].includes(
            x.Network?.toLowerCase()
          ) && x.ResponseType?.toLowerCase() === 'approved'
      ).length;
    } else {
      return transactions?.filter(
        (x) =>
          x.Network?.toLowerCase() === type &&
          x.ResponseType?.toLowerCase() === 'approved'
      ).length;
    }
  };

  const getTransTypeAmount = (type: string) => {
    if (transactions) {
      let amount: any;
      if (type === 'void') {
        amount = transactions
          ?.filter(
            (x) =>
              x.TransactionType?.toLowerCase() === type &&
              x.ResponseType?.toLowerCase() === 'approved' &&
              !['reversal', 'reversed'].includes(x.LastAction?.toLowerCase())
          )
          .reduce((a, b) => +a + +b.Amount, 0);
      } else if (type === 'reversal') {
        amount = transactions
          ?.filter(
            (x) =>
              ['reversal', 'reversed'].includes(x.LastAction?.toLowerCase()) &&
              x.ResponseType?.toLowerCase() === 'approved'
          )
          .reduce((a, b) => +a + +b.Amount, 0);
      } else {
        amount = transactions
          ?.filter(
            (x) =>
              x.TransactionType?.toLowerCase() === type &&
              x.ResponseType?.toLowerCase() === 'approved'
          )
          .reduce((a, b) => +a + +b.Amount, 0);
      }
      return amount;
    }
  };

  const getNetworkTypeAmount = (type: string) => {
    if (transactions) {
      let amount = 0;
      if (type === 'others') {
        amount = transactions
          ?.filter(
            (x) =>
              !['visa', 'mastercard', 'amex', 'discover'].includes(
                x.Network?.toLowerCase()
              ) && x.ResponseType?.toLowerCase() === 'approved'
          )
          .reduce((a, b) => +a + +b.Amount, 0);
      } else {
        amount = transactions
          ?.filter(
            (x) =>
              x.Network?.toLowerCase() === type &&
              x.ResponseType?.toLowerCase() === 'approved'
          )
          .reduce((a, b) => +a + +b.Amount, 0);
      }
      return amount;
    }
  };

  const getGainsAmount = () => {
    let amount = 0;
    switch (true) {
      case batchDetails.Processer?.toString().toLowerCase() === 'shazam':
        if (transactions) {
          let allTransactions =
            transactions?.filter(
              (x) =>
                ['take'].includes(x.TransactionType?.toLowerCase()) &&
                x.ResponseType?.toLowerCase() === 'approved' &&
                parseInt(x.Amount) > 0
            ) || [];
          amount = allTransactions.reduce((a, b) => +a + +b.Amount, 0);
        }
        return amount;
      default:
        if (transactions) {
          let allTransactions =
            transactions?.filter(
              (x) =>
                ['sale', 'capture'].includes(
                  x.TransactionType?.toLowerCase()
                ) &&
                x.ResponseType?.toLowerCase() === 'approved' &&
                parseInt(x.Amount) > 0
            ) || [];

          let voidTransactions =
            transactions?.filter(
              (x) =>
                ['void'].includes(x.TransactionType?.toLowerCase()) &&
                x.ResponseType?.toLowerCase() === 'approved' &&
                !['reversal', 'reversed'].includes(x.LastAction?.toLowerCase())
            ) || [];

          let refundTransactions =
            transactions?.filter(
              (x) =>
                ['refund'].includes(x.TransactionType?.toLowerCase()) &&
                x.ResponseType?.toLowerCase() === 'approved'
            ) || [];

          let voidedRefundTransactions =
            voidTransactions.filter((x) =>
              x.ReferenceGUID
                ? refundTransactions
                    .map((y) => y.TransactionId?.toString())
                    .includes(x.ReferenceGUID?.toString())
                : []
            ) || [];
          voidTransactions = voidTransactions.filter((x) =>
            voidedRefundTransactions
              .map((y) => y.TransactionId?.toString())
              .includes(x.TransactionId?.toString())
          );
          allTransactions = allTransactions.concat(voidTransactions);

          amount = allTransactions.reduce((a, b) => +a + +b.Amount, 0);
        }
        return amount;
    }
  };

  const getLossesAmount = () => {
    let amount = 0;
    switch (true) {
      case batchDetails.Processer?.toString().toLowerCase() === 'shazam':
        if (transactions) {
          let allTransactions =
            transactions?.filter(
              (x) =>
                ['pay'].includes(x.TransactionType?.toLowerCase()) &&
                x.ResponseType?.toLowerCase() === 'approved' &&
                parseInt(x.Amount) > 0
            ) || [];

          amount = allTransactions.reduce((a, b) => +a + +b.Amount, 0);
        }
        return amount;
      default:
        if (transactions) {
          let voidTransactions =
            transactions?.filter(
              (x) =>
                ['void'].includes(x.TransactionType?.toLowerCase()) &&
                x.ResponseType?.toLowerCase() === 'approved' &&
                !['reversal', 'reversed'].includes(x.LastAction?.toLowerCase())
            ) || [];
          let refundTransactions =
            transactions?.filter(
              (x) =>
                ['refund'].includes(x.TransactionType?.toLowerCase()) &&
                x.ResponseType?.toLowerCase() === 'approved'
            ) || [];

          let voidedRefundTransactions =
            voidTransactions.filter((x) =>
              x.ReferenceGUID
                ? refundTransactions
                    .map((y) => y.TransactionId?.toString())
                    .includes(x.ReferenceGUID?.toString())
                : []
            ) || [];
          // refundTransactions = refundTransactions.filter(x => !voidedRefundTransactions.map(y => y.referenceGUID).includes(x.transactionId));
          voidTransactions = voidTransactions.filter(
            (x) =>
              !voidedRefundTransactions
                .map((y) => y.TransactionId?.toString())
                .includes(x.TransactionId?.toString())
          );
          let allTransactions = voidTransactions.concat(refundTransactions);
          amount = allTransactions.reduce((a, b) => +a + +b.Amount, 0);
        }
        return amount;
    }
  };

  return (
    <>
      <Accordion
        sx={{
          '&:Before': {
            display: 'none',
          },
        }}
        className="AccordionStyle"
        defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight="bold">Batch Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SettingsRow title="Net Amount">
            <Box className="TotalAmount">
              <Typography className="TotalAmountNumberStyle">
                {toCurrency(
                  Number(batchDetails?.BatchNetAmount).toFixed(2).toString(),
                  '.',
                  '$',
                  ','
                )}
              </Typography>
            </Box>
          </SettingsRow>
          <Box className="RowStyle">
            <Box width="48%">
              <SettingsRow title="Gains">
                <Typography align="right">
                  {toCurrency(Number(getGainsAmount()).toFixed(2).toString())}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="48%">
              <SettingsRow title="Losses">
                <Typography align="right">
                  {toCurrency(
                    Number(getLossesAmount()).toFixed(2).toString(),
                    '.',
                    '$',
                    ','
                  )}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="RowStyle">
            <Box width="28.5%">
              <SettingsRow title="Type">
                <Typography>{batchDetails?.Processer || '-'}</Typography>
              </SettingsRow>
            </Box>
            <Box width="28.5%">
              <SettingsRow title="Status">
                <Typography>{batchDetails?.Status || '-'}</Typography>
              </SettingsRow>
            </Box>
            <Box width="33%">
              <SettingsRow title="Scheduled Batch Close Time">-</SettingsRow>
            </Box>
          </Box>
          <Box className="RowStyle">
            <Box width="28.5%">
              <SettingsRow title="Opened At">
                <Typography fontSize="14px">
                  {batchDetails?.CreatedAtDateTime
                    ? moment(batchDetails?.CreatedAtDateTime).format(
                        DATETIME_FORMAT
                      )
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="28.5%">
              <SettingsRow title="Closed At">
                <Typography fontSize="14px">
                  {batchDetails?.ClosedAtDateTime
                    ? moment(batchDetails?.ClosedAtDateTime).format(
                        DATETIME_FORMAT
                      )
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="33%">
              <SettingsRow title="Settled At">
                <Typography fontSize="14px">
                  {batchDetails?.SettledAtDateTime
                    ? moment(batchDetails?.SettledAtDateTime).format(
                        DATETIME_FORMAT
                      )
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          '&:Before': {
            display: 'none',
          },
        }}
        className="AccordionStyle"
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight="bold">Transaction Type Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="RowStyle">
            <Box width="48%">
              <SettingsRow title="Sale Details">
                <Box flexDirection="column">
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Count:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {getTransTypeCount('sale')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Amount:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {toCurrency(
                          Number(getTransTypeAmount('sale'))
                            .toFixed(2)
                            .toString(),
                          '.',
                          '$',
                          ','
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </SettingsRow>
            </Box>
            <Box width="48%">
              <SettingsRow title="Refund Details">
                <Box flexDirection="column">
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Count:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {getTransTypeCount('refund')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Amount:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {toCurrency(
                          Number(getTransTypeAmount('refund'))
                            .toFixed(2)
                            .toString(),
                          '.',
                          '$',
                          ','
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="RowStyle">
            <Box width="48%">
              <SettingsRow title="Capture Details">
                <Box flexDirection="column">
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Count:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {getTransTypeCount('capture')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Amount:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {toCurrency(
                          Number(getTransTypeAmount('capture'))
                            .toFixed(2)
                            .toString(),
                          '.',
                          '$',
                          ','
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </SettingsRow>
            </Box>
            <Box width="48%">
              <SettingsRow title="Void Details">
                <Box flexDirection="column">
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Count:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {getTransTypeCount('void')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Amount:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {toCurrency(
                          Number(getTransTypeAmount('void'))
                            .toFixed(2)
                            .toString(),
                          '.',
                          '$',
                          ','
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="RowStyle">
            <Box width="48%">
              <SettingsRow title="Auth Details">
                <Box flexDirection="column">
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Count:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {getTransTypeCount('auth')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Amount:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {toCurrency(
                          Number(getTransTypeAmount('auth'))
                            .toFixed(2)
                            .toString(),
                          '.',
                          '$',
                          ','
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </SettingsRow>
            </Box>
            <Box width="48%">
              <SettingsRow title="Reversal Details">
                <Box flexDirection="column">
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Count:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {getTransTypeCount('reversal')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Amount:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {toCurrency(
                          Number(getTransTypeAmount('reversal'))
                            .toFixed(2)
                            .toString(),
                          '.',
                          '$',
                          ','
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </SettingsRow>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          '&:Before': {
            display: 'none',
          },
        }}
        className="AccordionStyle"
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight="bold">Network Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="RowStyle">
            <Box width="48%">
              <SettingsRow title="Visa Details">
                <Box flexDirection="column">
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Count:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {getNetworkTypeCount('visa')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Amount:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {toCurrency(
                          Number(getNetworkTypeAmount('visa'))
                            .toFixed(2)
                            .toString(),
                          '.',
                          '$',
                          ','
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </SettingsRow>
            </Box>
            <Box width="48%">
              <SettingsRow title="MasterCard Details">
                <Box flexDirection="column">
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Count:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {getNetworkTypeCount('mastercard')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Amount:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {toCurrency(
                          Number(getNetworkTypeAmount('mastercard'))
                            .toFixed(2)
                            .toString(),
                          '.',
                          '$',
                          ','
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="RowStyle">
            <Box width="48%">
              <SettingsRow title="Amex Details">
                <Box flexDirection="column">
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Count:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {getNetworkTypeCount('amex')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Amount:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {toCurrency(
                          Number(getNetworkTypeAmount('amex'))
                            .toFixed(2)
                            .toString(),
                          '.',
                          '$',
                          ','
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </SettingsRow>
            </Box>
            <Box width="48%">
              <SettingsRow title="Discover Details">
                <Box flexDirection="column">
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Count:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {getNetworkTypeCount('discover')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Amount:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {toCurrency(
                          Number(getNetworkTypeAmount('discover'))
                            .toFixed(2)
                            .toString(),
                          '.',
                          '$',
                          ','
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="RowStyle">
            <Box width="48%">
              <SettingsRow title="Others Details">
                <Box flexDirection="column">
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Count:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {getNetworkTypeCount('others')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="CardRow">
                    <Box>
                      <Typography className="AmountStyle">Amount:</Typography>
                    </Box>
                    <Box>
                      <Typography className="CurrencyStyle">
                        {toCurrency(
                          Number(getNetworkTypeAmount('others'))
                            .toFixed(2)
                            .toString(),
                          '.',
                          '$',
                          ','
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </SettingsRow>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {!isBatchInfoPending ? (
        <SettingsBlock title="Batch Transaction">
          <TransactionTable
            transactions={transactions}
            onViewDetailsClick={onViewDetailsClick}
            handleOnMore={handleOnMore}
            isBatchDetails={true}
          />
        </SettingsBlock>
      ) : (
        <CircularProgress
          sx={{ marginLeft: '45%', marginTop: '2%' }}
          size={24}
        ></CircularProgress>
      )}
    </>
  );
};

export default BatchDetails;
