/**
 * Application root component
 */
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import App from './components/App/App';
import PrivateRoute from './components/Common/PrivateRoute';
import Reporting from './pages/Reporting/Reporting';
import SignIn from './pages/SignIn';
import Users from './pages/Users';
import Accounts from './pages/Accounts';
import VirtualTerminal from './pages/VirtualTerminal';
import Settings from './pages/Settings';

const Root = () => (
  <Router>
    <App>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/signin" element={<SignIn />} />
        <Route element={<SignIn />} path="*" />
        <Route
          path="/reporting"
          element={
            <PrivateRoute>
              <Reporting />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="/accounts"
          element={
            <PrivateRoute>
              <Accounts />
            </PrivateRoute>
          }
        />
        <Route path="/terminal">
          <Route
            index
            element={
              <PrivateRoute>
                <VirtualTerminal />
              </PrivateRoute>
            }
          />
          <Route
            path=":transactionId/:action"
            element={
              <PrivateRoute>
                <VirtualTerminal />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
      </Routes>
    </App>
  </Router>
);

export default Root;
