/**
 * Tokenization host form
 */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { AZURE_IMAGE_API, AMEX_OPT_BLUE } from '../../../../../store/constants';
import SelectField from '../../../../Common/SelectInput';
import ToggleSwitch from '../../../../Common/ToggleSwitch';
import './TokenizationHostForm.scss';
import { validationSchema } from './validationSchema';

interface TokenizationHostFormProps {
  values?: any;
  onConfigChange: (values: any) => void;
  isDisabled?: boolean;
  setIsTokenizationFormValid?: (value: boolean) => void;
}
const TokenizationHostForm: React.FC<TokenizationHostFormProps> = ({
  values = {},
  onConfigChange,
  isDisabled = false,
  setIsTokenizationFormValid,
}) => {
  let initialValues = {
    UserName: '',
    Password: '',
    Url: '',
    VaultId: '',
    IsTokenizationEnabled: false,
  };
  const [tokenizationHostValues, setTokenizationHostValues] =
    useState<any>(initialValues);
  const [passwordValues, setPasswordValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (fromValues: any) => {},
    validateOnChange: true,
  });

  const submitFormValues = () => {
    if (!tokenizationHostValues.IsChanged) {
      setTokenizationHostValues((prevState: any) => ({
        ...prevState,
        IsChanged: true,
      }));
    }
    onConfigChange && onConfigChange(tokenizationHostValues);
    setIsTokenizationFormValid &&
      setIsTokenizationFormValid(
        formik.errors && Object.keys(formik.errors).length ? false : true
      );
  };

  return (
    <Box className="HostContainerStyle">
      <img
        alt={`${AZURE_IMAGE_API}/hosts/vgs.png`}
        src={`${AZURE_IMAGE_API}/hosts/vgs.png`}
        className="HostImageStyle"
      />
      <Box className="StatusBoxStyle">
        <Typography fontWeight="bold">Status</Typography>
        <ToggleSwitch
          isChecked={true}
          handleOnChange={(value: any) => {
            setTokenizationHostValues((prevState: any) => ({
              ...prevState,
              IsActive: value,
            }));
            submitFormValues();
          }}
        ></ToggleSwitch>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'UserName'}
            name={'UserName'}
            label="Username"
            disabled={isDisabled}
            className="LeftControlStyle"
            value={tokenizationHostValues['UserName']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('UserName', value);
              setTokenizationHostValues((prevState: any) => ({
                ...prevState,
                UserName: value,
              }));
            }}
            onBlur={(e: any) => {
              formik.setFieldTouched('UserName');
              submitFormValues();
            }}
            error={
              formik.touched?.UserName && formik.errors?.UserName ? true : false
            }
            helperText={
              formik.touched?.UserName && formik.errors['UserName']
                ? formik.errors['UserName']
                : ''
            }
          />
        </FormControl>
        <FormControl id={'Password'} fullWidth className="FieldLabelStyle">
          <TextField
            id={'Password'}
            name={'Password'}
            label="Password"
            className="FirstNameStyle"
            disabled={false}
            value={tokenizationHostValues?.Password}
            type={passwordValues?.showPassword ? 'text' : 'password'}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Password', value);
              setTokenizationHostValues((prevState: any) => ({
                ...prevState,
                Password: value,
              }));
            }}
            onBlur={(e: any) => {
              formik.setFieldTouched('Password');
              submitFormValues();
            }}
            InputProps={{
              autoComplete: 'new-password',
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {passwordValues?.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={
              formik.touched.Password && formik.errors.Password ? true : false
            }
          />
          {formik.touched.Password && formik.errors.Password && (
            <FormHelperText className="FormHelperTextStyle">
              {formik.touched.Password && formik.errors.Password
                ? formik.errors.Password
                : ''}
            </FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box className="MarginTop" display="inline-flex">
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'VaultId'}
            name={'VaultId'}
            label="Vault ID"
            disabled={isDisabled}
            className="LeftControlStyle"
            value={tokenizationHostValues['VaultId']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('VaultId', value);
              setTokenizationHostValues((prevState: any) => ({
                ...prevState,
                VaultId: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('VaultId');
            }}
            error={
              formik.touched?.VaultId && formik.errors['VaultId'] ? true : false
            }
            helperText={
              formik.touched?.VaultId && formik.errors['VaultId']
                ? formik.errors['VaultId']
                : ''
            }
          />
        </FormControl>
        <FormControl className="FormFieldLabelStyle">
          <TextField
            id={'Url'}
            name={'Url'}
            label="URL"
            disabled={isDisabled}
            className="LeftControlStyle"
            value={tokenizationHostValues['Url']}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              formik.setFieldValue('Url', value);
              setTokenizationHostValues((prevState: any) => ({
                ...prevState,
                Url: value,
              }));
            }}
            onBlur={(e: any) => {
              submitFormValues();
              formik.setFieldTouched('Url');
            }}
            error={formik.touched?.Url && formik.errors['Url'] ? true : false}
            helperText={
              formik.touched?.Url && formik.errors['Url']
                ? formik.errors['Url']
                : ''
            }
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default TokenizationHostForm;
