// Class to add constants for types of actions
export const STORE_INIT = 'STORE_INIT';
export const APP_SET_USER = 'APP_SET_USER';
export const APP_SIDEBAR_TOGGLE = 'APP_SIDEBAR_TOGGLE';
export const APP_SET_SCREEN_TITLE = 'APP_SET_SCREEN_TITLE';
export const APP_SET_STATUS_MESSAGE = 'APP_SET_STATUS_MESSAGE';
export const APP_UPDATE_USER_SETTINGS = 'APP_UPDATE_USER_SETTINGS';
export const APP_CLEAR_STATUS_MESSAGES = 'APP_CLEAR_STATUS_MESSAGES';
export const APP_REMOVE_STATUS_MESSAGE = 'APP_REMOVE_STATUS_MESSAGE';
export const AUTH_LOGIN_ACTION = 'AUTH_LOGIN_ACTION';
export const AUTH_LOGOUT_ACTION = 'AUTH_LOGOUT_ACTION';
export const AUTH_SET_USER_CLIENT_ACTION = 'AUTH_SET_USER_CLIENT_ACTION';
export const USER_ACCOUNT_LOCKED = 'USER_ACCOUNT_LOCKED';
export const CLIENT_OBJECT_STORAGE = 'CLIENT_OBJECT_STORAGE';
export const AUTH_GET_CODE = 'AUTH_GET_CODE';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_SEND_VERIFICATION = 'AUTH_SEND_VERIFICATION';
export const AUTH_VERIFY_CODE = 'AUTH_VERIFY_CODE';
export const AUTH_UPDATE_PASSWORD = 'AUTH_UPDATE_PASSWORD';
export const AUTH_RESET_PASSWORD_UPDATE = 'AUTH_RESET_PASSWORD_UPDATE';
export const TWO_FACTOR_STATUS = 'TWO_FACTOR_STATUS';
export const AUTH_SEND_VERIFICATION_OTP = 'AUTH_SEND_VERIFICATION_OTP';
export const AUTH_VERIFY_OTP_CODE = 'AUTH_VERIFY_OTP_CODE';
export const APP_SET_USER_LOGGED_IN = 'APP_SET_USER_LOGGED_IN';

export const ADDITIONAL_VERIFICATION_REQUIRED =
  'ADDITIONAL_VERIFICATION_REQUIRED';
export const ADDITIONAL_VERIFICATION_REQUIRED_CHANGE_PASSWORD =
  'ADDITIONAL_VERIFICATION_REQUIRED_CHANGE_PASSWORD';

export const REPORTING_FETCH_TRANSACTIONS = 'REPORTING_FETCH_TRANSACTIONS';
export const REPORTING_TRANSACTIONS_RESET = 'REPORTING_TRANSACTIONS_RESET';

export const REPORTING_FETCH_TRANSACTION_BY_ID_FULFILLED =
  'REPORTING_FETCH_TRANSACTION_BY_ID_FULFILLED';
export const REPORTING_FETCH_TRANSACTION_BY_ID_REJECTED =
  'REPORTING_FETCH_TRANSACTION_BY_ID_REJECTED';
export const REPORTING_FETCH_TRANSACTION_BY_ID_PENDING =
  'REPORTING_FETCH_TRANSACTION_BY_ID_PENDING';

export const REPORTING_FETCH_TRANSACTIONS_SUMMARY_PENDING =
  'REPORTING_FETCH_TRANSACTIONS_SUMMARY_PENDING';
export const REPORTING_FETCH_TRANSACTIONS_SUMMARY_FULFILLED =
  'REPORTING_FETCH_TRANSACTIONS_SUMMARY_FULFILLED';
export const REPORTING_FETCH_TRANSACTIONS_SUMMARY_REJECTED =
  'REPORTING_FETCH_TRANSACTIONS_SUMMARY_REJECTED';

export const REPORTING_FETCH_BATCHES_PENDING = 'REPORTING_FETCH_BATCHES_PENDING';
export const REPORTING_FETCH_BATCHES_FULFILLED = 'REPORTING_FETCH_BATCHES_FULFILLED';
export const REPORTING_FETCH_BATCHES_REJECTED = 'REPORTING_FETCH_BATCHES_REJECTED';

export const REPORTING_BATCHES_RESET = 'REPORTING_BATCHES_RESET';
export const REPORTING_BATCH_CLOSE = 'REPORTING_BATCH_CLOSE';

export const REPORTING_FETCH_BATCH_TRANSACTIONS_FULFILLED =
  'REPORTING_FETCH_BATCH_TRANSACTIONS_FULFILLED';
export const REPORTING_FETCH_BATCH_TRANSACTIONS_REJECTED =
  'REPORTING_FETCH_BATCH_TRANSACTIONS_REJECTED';
export const REPORTING_FETCH_BATCH_TRANSACTIONS_PENDING =
  'REPORTING_FETCH_BATCH_TRANSACTIONS_PENDING';

// virtual terminal actions
export const VIRTUAL_TERMINAL_SALE = 'VIRTUAL_TERMINAL_SALE';
export const VIRTUAL_TERMINAL_RESET_SALE = 'VIRTUAL_TERMINAL_RESET_SALE';
export const VIRTUAL_TERMINAL_AUTHORISE = 'VIRTUAL_TERMINAL_AUTHORISE';
export const VIRTUAL_TERMINAL_RESET_AUTHORISE =
  'VIRTUAL_TERMINAL_RESET_AUTHORISE';
export const VIRTUAL_TERMINAL_REFUND = 'VIRTUAL_TERMINAL_REFUND';
export const VIRTUAL_TERMINAL_RESET_REFUND = 'VIRTUAL_TERMINAL_RESET_REFUND';
export const VIRTUAL_TERMINAL_CAPTURE = 'VIRTUAL_TERMINAL_CAPTURE';
export const VIRTUAL_TERMINAL_RESET_CAPTURE = 'VIRTUAL_TERMINAL_RESET_CAPTURE';
export const VIRTUAL_TERMINAL_VOID = 'VIRTUAL_TERMINAL_VOID';
export const VIRTUAL_TERMINAL_RESET_VOID = 'VIRTUAL_TERMINAL_RESET_VOID';
export const VIRTUAL_TERMINAL_FETCH_TRANSACTION =
  'VIRTUAL_TERMINAL_FETCH_TRANSACTION';
export const VIRTUAL_TERMINAL_RESET_TRANSACTION =
  'VIRTUAL_TERMINAL_RESET_TRANSACTION';

//User action
export const USERS_FETCH_PENDING = 'USERS_FETCH_PENDING';
export const USERS_FETCH_FULFILLED = 'USERS_FETCH_FILFILLED';
export const USERS_FETCH_REJECTED = 'USERS_FETCH_REJECTED';


export const USERS_FETCH_BY_ID = 'USERS_FETCH_BY_ID';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';

// Client actions
export const CLIENT_FETCH_LIST_PENDING = 'CLIENT_FETCH_LIST_PENDING';
export const CLIENT_FETCH_LIST_FULFILLED = 'CLIENT_FETCH_LIST_FULFILLED';
export const CLIENT_FETCH_LIST_REJECTED = 'CLIENT_FETCH_LIST_REJECTED';
export const CLIENT_LIST_RESET = 'CLIENT_LIST_RESET';
export const CLIENT_FETCH_API_KEY = 'CLIENT_FETCH_API_KEY';

//Profile Actions
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const EDIT_USER_PROFILE = 'EDIT_USER_PROFILE';
export const CLIENT_CREATE = 'CLIENT_CREATE';
export const CLIENT_APPLICATION_ID = 'CLIENT_APPLICATION_ID';

//Host Actions
export const CLIENT_CREATE_TSYS_HOST = 'CLIENT_CREATE_TSYS_HOST';
export const CLIENT_CREATE_TOKENIZATION_HOST =
  'CLIENT_CREATE_TOKENIZATION_HOST';

export const CLIENT_CREATE_SHAZAM_HOST = 'CLIENT_CREATE_SHAZAM_HOST';
