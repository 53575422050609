/**
 * Validation Rules class
 * This class if used for adding common validation rules for the application
 * Validation is done using YUP
 */

import * as Yup from 'yup';

export const requiredFieldValidationRule = (name: string) =>
  Yup.string().trim().required(`${name} is required`);

export const emailValidationRule = (name: string, omitSpaces?: boolean) => {
  let result = Yup.string().required(`${name} is required`);
  if (omitSpaces) {
    result = result.trim();
  }
  return result.email(`${name} is not valid`);
};

export const phoneNumberValidationRule = (
  name: string,
  omitSpaces?: boolean
) => {
  const result = Yup.string().required(`${name} is required`);
  if (omitSpaces) {
    result.trim();
  }
  return result.matches(
    // /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
    /^\d{10}$/,
    `${name} is not valid`
  );
};

export const phoneNumberValidationRuleFor11Digits = (
  name: string,
  omitSpaces?: boolean
) => {
  const result = Yup.string().required(`${name} is required`);
  if (omitSpaces) {
    result.trim();
  }
  return result.matches(/^\d{11}$/, `${name} is not valid`);
};

// this function is specifically for 10 digit mobile number with formatting
export const addPhoneNumberValidationRule = (
  name: string,
  omitSpaces?: boolean
) => {
  const result = Yup.string()
    .required(`${name} is required`)
    // here phone number is 10 digits,
    // country code is +1
    // and rest are special characters like (), - and space
    .min(14, `${name} value must be 10 digits`)
    .max(14, `${name} value must be 10 digits`);
  if (omitSpaces) {
    result.trim();
  }
  return result.matches(
    /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
    `${name} is not valid`
  );
};

export const passwordValidationRule = (name: string) =>
  Yup.string()
    .required(`${name} is required`)
    .min(8, `Must be at least 8 characters long`)
    .max(256, `Must be at most 256 characters long`)
    .matches(/[a-z]/, `At least one lowercase char`)
    .matches(/[A-Z]/, `At least one uppercase char`)
    .matches(/[0-9]/, `At least 1 number`)
    .matches(
      /[~!@#$%^&*()\-_=+[\]{}|;:,.<>/?]/,
      `At least 1 special character (~!@#$%^&*()-_=+[]{}|;:,.<>/|?)`
    );

export const fieldMatchValidationRule = (name: string) =>
  Yup.string()
    .required()
    .oneOf([Yup.ref(name), null], `Confirm ${name} must match ${name}`);

export const fieldMatchValidationRuleCustomeMessage = (
  matchWith: string,
  currentFieldLabel: string,
  matchWithFieldLabel: string
) =>
  Yup.string()
    .required(`${currentFieldLabel} is a required field`)
    .oneOf(
      [Yup.ref(matchWith), null],
      `${currentFieldLabel} must match ${matchWithFieldLabel}`
    );

export const customValidationRule = (
  name: string,
  regex: RegExp,
  message?: string
) =>
  Yup.string().matches(
    regex,
    message || `${name} should match the following pattern ${regex}`
  );

export const requiredCustomValidationRule = (
  name: string,
  regex: RegExp,
  message?: string
) => customValidationRule(name, regex, message).required(`${name} is required`);

export const websiteValidationRule = () =>
  Yup.string().matches(
    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
  );

export const hierarchyCodeValidationRule = (name: string) =>
  Yup.string().matches(
    /^[@a-z0-9-]*$/,
    `${name} is not valid. Allowed only: a-z, 0-9, -`
  );

// this function is specifically for 10 digit mobile number without formatting
export const phoneNoValidationRule = (name: string, omitSpaces?: boolean) => {
  const result = Yup.string()
    .required(`${name} is required`)
    // here phone number is 10 digits,
    .min(10, `${name} value must be 10 digits`)
    .max(10, `${name} value must be 10 digits`);
  if (omitSpaces) {
    result.trim();
  }
  return result.matches(
    /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
    `${name} is not valid`
  );
};
export const urlValidationWithoutProtocolRule = () =>
  Yup.string().matches(
    /^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._%~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
  );

export const urlValidationTsys = () =>
  Yup.string().matches(/(.*)[\w]+[\.]+[\w]+:(\d)/g);

export const merchantNameValidationRule = (name: string) => {
  const result = Yup.string()
    .trim()
    .required(`${name} is required`)
    .max(256, `${name} can not be greater than 256 characters`);
  return result;
};

export const merchantEmailValidationRule = (name: string) => {
  let result = Yup.string()
    .required(`${name} is required`)
    .max(256, `${name} can not be greater than 256 characters`);
  return result.email(`${name} is not valid`);
};

export const merchantFirstNameValidationRule = (name: string) => {
  const result = Yup.string()
    .trim()
    .required(`${name} is required`)
    .max(256, `${name} can not be greater than 256 characters`);
  return result;
};

export const merchantLastNameValidationRule = (name: string) => {
  const result = Yup.string()
    .trim()
    .required(`${name} is required`)
    .max(256, `${name} can not be greater than 256 characters`);
  return result;
};

export const merchantPhoneNumberValidationRule = (name: string) => {
  name = name?.replace('+1', '')?.trim();
  if (!name) {
    return 'Phone number is required';
  }
  const validationSchema = customValidationRule(
    'Phone Number',
    /^\(?[0-9]{1,4}\)?[\s-]{1}[0-9]{3,4}[\s-]{1}[0-9]{4,5}$/
  );
  return name && !validationSchema.isValidSync(name)
    ? `Phone number is invalid. Please type the number in the international format`
    : '';
};

export const merchantBusinessPhoneNumberValidationRule = (name: string) => {
  name = name?.replace('+1', '')?.trim();
  const validationSchema = customValidationRule(
    'Phone Number',
    /^\(?[0-9]{1,4}\)?[\s-]{1}[0-9]{3,4}[\s-]{1}[0-9]{4,5}$/
  );
  return name && !validationSchema.isValidSync(name)
    ? `Phone number is invalid.`
    : '';
};

export const ssnValidator = (name: string) => {
  const result = Yup.string().required(`${name} is required`);
  return result.matches(
    /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/,
    `${name} is not valid`
  );
};

export const chars50ValidationRule = (name: string) => {
  const result = Yup.string()
    .trim()
    .required(`${name} is required`)
    .max(50, `${name} can not be greater than 50 characters`);
  return result;
};

export const chars100ValidationRule = (name: string) => {
  const result = Yup.string()
    .trim()
    .required(`${name} is required`)
    .max(100, `${name} can not be greater than 100 characters`);
  return result;
};

export const chars15ValidationRule = (name: string) => {
  const result = Yup.string()
    .trim()
    .required(`${name} is required`)
    .max(15, `${name} can not be greater than 15 characters`);
  return result;
};

export const fullNameValidationRule = (name: string) => {
  const result = Yup.string();
  return result.matches(/[A-Z,a-z][\s-][A-Z,a-z]/, `${name} is not valid`);
};

export const fullNameValidationRuleRequire = (name: string) => {
  const result = Yup.string().trim().required(`${name} is required`);
  return result.matches(/[A-Z,a-z][\s-][A-Z,a-z]/, `${name} is not valid`);
};

export const phoneNumberValidRuleFor11DigitNotRequired = (
  name: string,
  omitSpaces?: boolean
) => {
  const result = Yup.string();
  if (omitSpaces) {
    result.trim();
  }
  return result.matches(/^\d{11}$/, `${name} is not valid`);
};

export const emailValidationRuleNotRequire = (
  name: string,
  omitSpaces?: boolean
) => {
  let result = Yup.string();
  if (omitSpaces) {
    result = result.trim();
  }
  return result.email(`${name} is not valid`);
};

export const requiredTextFieldValidationRule = (name: string) => {
  const result = Yup.string()
    .trim()
    .required(`${name} is required`)
    .matches(/^[0-9aA-zZ\s]+$/, `No special characters are allowed`);
  return result;
};

export const reuiredSelectFieldValidationRule = (name: string) => {
  const result = Yup.string().trim().required(`${name} is required`);
  return result;
};

export const zipCodeValidator = (name: string) => {
  const result = Yup.string().trim();
  return result.matches(/^[0-9]{5}(?:-[0-9]{4})?$/, `Zip Code is invalid.`);
};

export const merchantLegalNameValidationRule = (name: string) => {
  const result = Yup.string()
    .trim()
    .max(256, `${name} can not be greater than 256 characters`);
  return result;
};
