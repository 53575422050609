/**
 * Fiserv host form
 */
import { Box } from '@mui/material';
import React, { useState } from 'react';
import './FiservHostForm.scss';

interface FiservHostFormProps {}
const FiservHostForm: React.FC<FiservHostFormProps> = ({}) => {
  const [selectedNewHosts, setSelectedNewHosts] = useState<any>({});

  return <Box>Fiserv Form</Box>;
};

export default FiservHostForm;
