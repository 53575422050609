/**
 * Settings Page
 * Displays User profile, security and notification settings
 */
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import ProfileTab from '../../components/UserProfileTabs/ProfileTab';
import './Settings.scss';

export enum TabIndex {
  Profile = 0,
  Security = 1,
  Notifications = 2,
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const Settings: React.FC<{}> = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const renderProfileTab = () => {
    return <ProfileTab></ProfileTab>;
  };

  // render account tabs
  const renderTabPanes = (index: TabIndex) => {
    switch (index) {
      case TabIndex.Profile:
        return (
          <TabPanel value={activeTabIndex} index={activeTabIndex}>
            {renderProfileTab()}
          </TabPanel>
        );
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, index: number) => {
    setActiveTabIndex(index);
  };

  return (
    <Box className="AccountPageStyle">
      <div className="PageHeaderStyle">
        <div>
          <Typography variant="h5" color="primary">
            Profile Settings
          </Typography>
        </div>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          className="TabsStyle"
          textColor="primary"
          value={activeTabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab className="TabStyle" label="Settings" {...a11yProps(0)} />
          <Tab className="TabStyle" label="Security" {...a11yProps(1)} />
          <Tab className="TabStyle" label="Notifications" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <Box className="SettingsTabPanelStyle">
        {renderTabPanes(activeTabIndex)}
      </Box>
    </Box>
  );
};
export default Settings;
