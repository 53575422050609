import * as Yup from 'yup';
import {
  emailValidationRule,
  emailValidationRuleNotRequire,
  phoneNumberValidRuleFor11DigitNotRequired,
  requiredTextFieldValidationRule,
  zipCodeValidator,
} from '../../Forms/validationRules';

export const validateAddressForm = (makeFieldName: any, requiredFields: any) =>
  Yup.object().shape({
    [`${makeFieldName('Email')}`]: Yup.lazy((val: string) => {
      let validationSchema = requiredFields.includes(makeFieldName('Email'))
        ? emailValidationRule('Email address', true)
        : emailValidationRuleNotRequire('Email address', true);
      return validationSchema;
    }),
    [`${makeFieldName('Phone')}`]: Yup.lazy((val: string) => {
      let validationSchema = requiredFields.includes(makeFieldName('Phone'))
        ? phoneNumberValidRuleFor11DigitNotRequired('Phone number', true)
        : phoneNumberValidRuleFor11DigitNotRequired('Phone number', true);
      return validationSchema;
    }),
    // [`${makeFieldName('FullName')}`]: Yup.lazy((val: string) => {
    //   let validationSchema = requiredFields.includes(makeFieldName('FullName'))
    //     ? fullNameValidationRuleRequire('Full Name')
    //     : fullNameValidationRule('Full Name');
    //   return validationSchema;
    // }),
    [`${makeFieldName('Address1')}`]: Yup.lazy((val: string) => {
      let validationSchema = requiredFields.includes(makeFieldName('Address1'))
        ? requiredTextFieldValidationRule('Address')
        : Yup.string().matches(
          /^[a-zA-Z0-9\s,'-]*$/,
          `No special characters are allowed`
        );
      return validationSchema;
    }),
    [`${makeFieldName('Address2')}`]: Yup.lazy((val: string) => {
      let validationSchema = requiredFields.includes(makeFieldName('Address2'))
        ? requiredTextFieldValidationRule('Address')
        : Yup.string().matches(
          /^[a-zA-Z0-9\s,'-]*$/,
          `No special characters are allowed`
        );
      return validationSchema;
    }),
    [`${makeFieldName('ZipCode')}`]: Yup.lazy((val: string) => {
      let validationSchema = requiredFields.includes(makeFieldName('ZipCode'))
        ? requiredTextFieldValidationRule('Zip Code')
        : zipCodeValidator(val);
      return validationSchema;
    }),
    [`${makeFieldName('City')}`]: Yup.lazy((val: string) => {
      let validationSchema = requiredFields.includes(makeFieldName('City'))
        ? requiredTextFieldValidationRule('City')
        : Yup.string();
      return validationSchema;
    }),
    [`${makeFieldName('State')}`]: Yup.lazy((val: string) => {
      let validationSchema = requiredFields.includes(makeFieldName('State'))
        ? requiredTextFieldValidationRule('State')
        : Yup.string();
      return validationSchema;
    }),
  });
