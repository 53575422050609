import api from '../../libs/api';
import {
  CLIENT_FETCH_LIST_PENDING,
  CLIENT_FETCH_LIST_FULFILLED,
  CLIENT_FETCH_LIST_REJECTED,
  CLIENT_LIST_RESET,
  CLIENT_FETCH_API_KEY,
} from './types';

export const thunkClientFetchListAction = (params?: any) => {
  return (dispatch: any) => {
    dispatch({
      type: CLIENT_FETCH_LIST_PENDING,
    });
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        api.clients.fetch(params)
          .then((response) => {
            dispatch({
              type: CLIENT_FETCH_LIST_FULFILLED,
              payload: response,
            });
            resolve(response);
          })
          .catch((error) => {
            dispatch({
              type: CLIENT_FETCH_LIST_REJECTED,
              payload: error,
            });
            reject(error);
          });
      }, 1000);

    });
  };
};
export const resetClientListAction = () => ({
  type: CLIENT_LIST_RESET,
});

export const resetTransactionsAction = () => ({
  type: CLIENT_LIST_RESET,
});

export const thunkFetchClientApiKeyAction =
  (clientId: string) => async (dispatch: any) => {
    dispatch({
      type: CLIENT_FETCH_API_KEY,
      payload: api.clients.fetchApiKey(clientId),
    });
  };
