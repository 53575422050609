/**
 * Validation schema for signin form
 * Validation is done using YUP
 */
import * as Yup from 'yup';
import {
  requiredFieldValidationRule,
  emailValidationRule,
  phoneNumberValidationRule,
} from '../validationRules';

export default Yup.object().shape({
  login: Yup.lazy((val: string) => {
    let validationSchema;
    let NumberRegex = /^-?[\d.]+(?:e-?\d+)?$/;
    if (!val) {
      validationSchema = requiredFieldValidationRule(
        'Email address or Phone number'
      );
    } else {
      validationSchema = !NumberRegex.test(val)
        ? emailValidationRule('Email address', true)
        : phoneNumberValidationRule('Phone number', true);
    }

    return validationSchema;
  }),
  password: requiredFieldValidationRule('Password'),
});
