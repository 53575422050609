/**
 * Api provider class
 * for handling the error response from api backend
 * like unauthorised error, session timeout, common error handler
 */
import React, { createContext, ReactNode } from 'react';
import api from '../../../libs/api';
import { StatusMessageType } from '../../../types/common';

import {
  clearStatusMessagesAction,
  setStatusMessageAction,
} from '../../../store/actions/appActions';

import { useStore } from 'lnox';

export const ApiContext = createContext<{ api: any }>({ api });

export const ApiProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { dispatch } = useStore();

  api.onUnauthorizedInterceptor = () => {
    // TODO: implement code when user is unauthorised
  };

  api.onCommonErrorInterceptor = (error: any) => {
    dispatch(clearStatusMessagesAction());
    dispatch(
      setStatusMessageAction(StatusMessageType.ERROR, `${error.DisplayMessage}`)
    );
  };

  api.onTimeoutErrorInterceptor = () => {
    dispatch(clearStatusMessagesAction());
    dispatch(
      setStatusMessageAction(StatusMessageType.ERROR, `Connection timed out`)
    );
  };

  return <ApiContext.Provider value={{ api }}>{children}</ApiContext.Provider>;
};
