/**
 * Sanitization helper class
 * Used for sanitizing the request payload from user screens
 * This class trims the values entered by user in the user inputs
 * This class can parse the object recurssivley
 */
export class SanitizationHelper {
  trimValues = (data: any) => {
    try {
      if (data) {
        if (typeof data === 'string') {
          data = data.trim();
        } else {
          for (let key in data) {
            if (typeof data[key] === 'string') {
              data[key] = data[key].trim();
              continue;
            }
            if (Array.isArray(data[key])) {
              for (let index in data[key]) {
                this.trimValues(data[key][index]);
                continue;
              }
              continue;
            }
            if (typeof data[key] === 'object') {
              this.trimValues(data[key]);
            }
          }
        }
      }

      return data;
    } catch (error) {
      throw error;
    }
  };
}
