import { Box, Typography, Button } from '@mui/material';
import React from 'react';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import './offline.scss';

const Offline: React.FC<{}> = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    className="offlineBoxStyle"
  >
    <CloudOffIcon className="offlineStatusIcon" />
    <Typography>
      Your network is unavailable. Check your data or WIFI connection.
    </Typography>
    <Button
      color="primary"
      sx={{ padding: '12px', marginTop: '1rem' }}
      variant="contained"
      onClick={() => window.location.reload()}
    >
      Retry
    </Button>
  </Box>
);

export default Offline;
