import React from 'react';
import { Navigate } from 'react-router-dom';
interface PrivateRouterProps {
  children: any;
}

const PrivateRoute: React.FC<PrivateRouterProps> = ({ children, ...props }) => {
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');

  return isLoggedIn ? children : <Navigate to="/signin" replace />;
};

export default PrivateRoute;
