/**
 * Activity detector component.
 *
 * Checks if user is inactive for N seconds
 * Then shows modal with possible actions
 */
import React, { useState, ReactNode } from 'react';
import CountDownTimer from './CountDown';
import useDidMountEffect from '../../Hooks/useDidMountEffect';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import './ActivityDetector.scss';

export interface ActivityDetectorProps {
  children: ReactNode;
  timeout: number;
  autoDeclineTimeout: number;
  onLogout: () => void;
}

// TODO: think about moving this to a config
const ACTIVITY_EVENTS: Array<string> = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
];

const ActivityDetector: React.FC<ActivityDetectorProps> = ({
  children,
  timeout,
  autoDeclineTimeout,
  onLogout,
}) => {
  let activityTimer: number = 0;
  const [isInactive, setIsInactive] = useState<boolean>(false);
  const [eventsBound, setEventsBound] = useState<boolean>(false);

  const startActivityTimer = () => {
    activityTimer = window.setInterval(() => {
      setIsInactive(true);
    }, timeout * 1000);
  };

  const resetActivityTimer = () => {
    clearTimeout(activityTimer);
    startActivityTimer();
  };

  const bindEventListeners = () => {
    if (!eventsBound) {
      ACTIVITY_EVENTS.forEach((event) => {
        window.addEventListener(event, resetActivityTimer);
      });
      setEventsBound(true);
    }
  };

  const unbindEventListeners = (force: boolean = false) => {
    if (eventsBound || force) {
      ACTIVITY_EVENTS.forEach((event) => {
        window.removeEventListener(event, resetActivityTimer);
      });
      setEventsBound(false);
    }
  };

  /**
   * ComponentDidMount life cycle hook
   */
  useDidMountEffect(() => {
    startActivityTimer();
    bindEventListeners();
    return () => {
      clearTimeout(activityTimer);
      unbindEventListeners(true);
    };
  });

  const handleContinueAction = () => {
    setIsInactive(false);
  };

  const handleLogoutAction = () => {
    setIsInactive(false);
    clearTimeout(activityTimer);
    onLogout();
  };

  const renderConfirmationDialog = () => (
    <Box>
      <Dialog
        open={isInactive}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="UserActivityContainerStyle"
      >
        <DialogTitle id="alert-dialog-title">{'Action required'}</DialogTitle>
        <DialogContent>
          We didn't detect any activity from you in last{' '}
          {(timeout / 60)?.toFixed(2)} minutes.
          <Typography>Would you like to continue?</Typography>
          <Box className="CountDownTimerBoxStyle">
            <Typography>You will be automatically logged out in</Typography>
            <CountDownTimer
              seconds={autoDeclineTimeout}
              onFinish={handleLogoutAction}
            />
          </Box>
        </DialogContent>
        <DialogActions className="BottomActionBarStyle">
          <Button variant="contained" onClick={handleLogoutAction}>
            Logout
          </Button>
          <Button variant="contained" onClick={handleContinueAction}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

  return (
    <>
      {children}
      {isInactive && renderConfirmationDialog()}
    </>
  );
};

export default ActivityDetector;
