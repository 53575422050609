/**
 * Merchant Host tab
 */
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useStore } from 'lnox';
import React, { useEffect, useState } from 'react';
import useDidMountEffect from '../../../Hooks/useDidMountEffect';
import {
  AZURE_IMAGE_API,
  VGS_HOST_DESCRIPTION,
  TSYS_HOST_DESCRIPTION,
  FISERV_HOST_DESCRIPTION,
  SHAZAM_HOST_DESCRIPTION,
} from '../../../store/constants';
import FiservHostForm from './Hosts/FiservHostForm';
import ShazamHostForm from './Hosts/ShazamHostForm';
import TokenizationHostForm from './Hosts/TokenizationHostForm';
import TsysHostForm from './Hosts/TsysHostForm';
import './MerchantHostTab.scss';

interface MerchantHostTabProps {
  addNewHost: boolean;
  newHostSelected: boolean;
  isCancelClicked: boolean;
  onConfigChange: (values: any) => void;
  selectedMerchantId: string;
  isDisabled: boolean;
  setIsTokenizationFormValid?: (value: boolean) => void;
  setIsTsysFormValid?: (value: boolean) => void;
  setIsShazamFormValid?: (value: boolean) => void;
}
const MerchantHostTab: React.FC<MerchantHostTabProps> = ({
  addNewHost,
  newHostSelected,
  isCancelClicked,
  onConfigChange,
  selectedMerchantId,
  isDisabled = false,
  setIsTokenizationFormValid,
  setIsTsysFormValid,
  setIsShazamFormValid,
}) => {
  const noHostSelected = {
    isVgsSelected: false,
    isTsysSelected: false,
    isFiservSelected: false,
    isShazamSelected: false,
  };
  const [selectedNewHosts, setSelectedNewHosts] = useState<any>(noHostSelected);
  const [hostConfig, setHostConfig] = useState<any>({});

  useEffect(() => {
    if (isCancelClicked) {
      setSelectedNewHosts(noHostSelected);
    }
  }, [isCancelClicked]);
  const {
    dispatch,
    state: {
      client: { clients },
    },
  } = useStore();

  const {
    fetchClientApiKey: {
      isPending,
      isFulfilled,
      message: { ApiKey },
    },
  } = clients;

  // useDidMountEffect(() => {
  //   if (clientId) {
  //     dispatch(thunkFetchClientApiKeyAction(clientId));
  //   }
  // });

  const renderAddNewHostWizard = () => {
    return (
      <Box className="HostContainer">
        <Accordion
          sx={{
            '&:Before': {
              display: 'none',
            },
          }}
          className="AccordionStyle"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight="bold">Tokenization</Typography>
          </AccordionSummary>
          <AccordionDetails className="AccordionDetailsStyle">
            <Box className="HostCardStyle">
              <Box display="inline-flex">
                <Box className="HostCheckBoxStyle">
                  <Checkbox
                    onChange={(event: any) => {
                      setSelectedNewHosts((prevState: any) => ({
                        ...prevState,
                        isVgsSelected: event.target.checked,
                      }));
                    }}
                  ></Checkbox>
                </Box>
                <Box className="HostImageBoxStyle">
                  <img
                    alt={`${AZURE_IMAGE_API}/hosts/vgs.png`}
                    src={`${AZURE_IMAGE_API}/hosts/vgs.png`}
                    className="HostImageStyle"
                  />
                </Box>
                <Box>
                  <Typography>{VGS_HOST_DESCRIPTION}</Typography>
                </Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            '&:Before': {
              display: 'none',
            },
          }}
          className="AccordionStyle"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight="bold">Credit Processor</Typography>
          </AccordionSummary>
          <AccordionDetails className="AccordionDetailsStyle">
            <Box className="HostCardStyle">
              <Box display="inline-flex">
                <Box className="HostCheckBoxStyle">
                  <Checkbox
                    onChange={(event: any) => {
                      setSelectedNewHosts((prevState: any) => ({
                        ...prevState,
                        isTsysSelected: event.target.checked,
                      }));
                    }}
                  ></Checkbox>
                </Box>
                <Box className="HostImageBoxStyle">
                  <img
                    alt={`${AZURE_IMAGE_API}/hosts/tsys.png`}
                    src={`${AZURE_IMAGE_API}/hosts/tsys.png`}
                    className="HostImageStyle"
                  />
                </Box>
                <Box>
                  <Typography>{TSYS_HOST_DESCRIPTION}</Typography>
                </Box>
              </Box>
              <Box display="inline-flex" className="HostPanelBoxStyle">
                <Box className="HostCheckBoxStyle">
                  <Checkbox
                    onChange={(event: any) => {
                      setSelectedNewHosts((prevState: any) => ({
                        ...prevState,
                        isFiservSelected: event.target.checked,
                      }));
                    }}
                    disabled={true}
                  ></Checkbox>
                </Box>
                <Box className="HostImageBoxStyle">
                  <img
                    alt={`${AZURE_IMAGE_API}/hosts/fiserv.png`}
                    src={`${AZURE_IMAGE_API}/hosts/fiserv.png`}
                    className="HostImageStyle"
                  />
                </Box>
                <Box>
                  <Typography>{FISERV_HOST_DESCRIPTION}</Typography>
                </Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            '&:Before': {
              display: 'none',
            },
          }}
          className="AccordionStyle"
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight="bold">Debit Processor</Typography>
          </AccordionSummary>
          <AccordionDetails className="AccordionDetailsStyle">
            <Box className="HostCardStyle">
              <Box display="inline-flex">
                <Box className="HostCheckBoxStyle">
                  <Checkbox
                    onChange={(event: any) => {
                      setSelectedNewHosts((prevState: any) => ({
                        ...prevState,
                        isShazamSelected: event.target.checked,
                      }));
                    }}
                  ></Checkbox>
                </Box>
                <Box className="HostImageBoxStyle">
                  <img
                    alt={`${AZURE_IMAGE_API}/hosts/shazam.png`}
                    src={`${AZURE_IMAGE_API}/hosts/shazam.png`}
                    className="ShazamHostImageStyle"
                  />
                </Box>
                <Box>
                  <Typography>{SHAZAM_HOST_DESCRIPTION}</Typography>
                </Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  const renderHostViewDetails = () => {
    return (
      <Box className="NoHostContainer">
        No Host Confirgured for the merchant
      </Box>
    );
  };

  const renderAddHostDetails = () => {
    return (
      <Box>
        {selectedNewHosts.isVgsSelected && (
          <TokenizationHostForm
            values={{}}
            onConfigChange={(values: any) => {
              setHostConfig((prevState: any) => ({
                ...prevState,
                tokenization: {
                  ClientId: selectedMerchantId,
                  VgsHostConfigId: '',
                  hostConfigVgs: values,
                  IsActive:
                    values?.IsActive == undefined || values?.IsActive == null
                      ? true
                      : values?.IsActive,
                  IsChanged: values?.IsChanged,
                },
              }));
              onConfigChange && onConfigChange(hostConfig);
            }}
            isDisabled={isDisabled}
            setIsTokenizationFormValid={(value) => {
              setIsTokenizationFormValid && setIsTokenizationFormValid(value);
            }}
          ></TokenizationHostForm>
        )}
        {selectedNewHosts.isTsysSelected && (
          <TsysHostForm
            values={{}}
            onConfigChange={(values: any) => {
              values.PhoneNumber =
                values?.DescriptorPhone?.length > 10
                  ? values?.DescriptorPhone?.toString()?.slice(1)
                  : values?.DescriptorPhone;
              values.DescriptorPhone = values?.PhoneNumber;
              values.DescriptorCountry = '840';
              setHostConfig((prevState: any) => ({
                ...prevState,
                tsys: {
                  ClientId: selectedMerchantId,
                  tsysHostConfigId: '',
                  TsysHostDetails: values,
                  IsActive:
                    values?.IsActive == undefined || values?.IsActive == null
                      ? true
                      : values?.IsActive,
                  IsChanged: values?.IsChanged,
                },
              }));
              onConfigChange && onConfigChange(hostConfig);
            }}
            isDisabled={isDisabled}
            setIsTsysFormValid={(value) => {
              setIsTsysFormValid && setIsTsysFormValid(value);
            }}
          ></TsysHostForm>
        )}
        {selectedNewHosts.isFiservSelected && <FiservHostForm></FiservHostForm>}
        {selectedNewHosts.isShazamSelected && (
          <ShazamHostForm
            values={{}}
            onConfigChange={(values: any) => {
              setHostConfig((prevState: any) => ({
                ...prevState,
                shazam: {
                  ClientId: selectedMerchantId,
                  shazamHostConfigId: '',
                  ShazamHostDetails: values,
                  IsActive:
                    values?.IsActive == undefined || values?.IsActive == null
                      ? true
                      : values?.IsActive,
                  IsChanged: values?.IsChanged,
                },
              }));
              onConfigChange && onConfigChange(hostConfig);
            }}
            isDisabled={isDisabled}
            setIsShazamFormValid={(value) => {
              setIsShazamFormValid && setIsShazamFormValid(value);
            }}
          ></ShazamHostForm>
        )}
      </Box>
    );
  };

  return (
    <Box>
      {addNewHost && !newHostSelected && renderAddNewHostWizard()}
      {!addNewHost && renderHostViewDetails()}
      {newHostSelected && addNewHost && renderAddHostDetails()}
    </Box>
  );
};

export default MerchantHostTab;
