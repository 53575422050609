/**
 * Merchant APiI tab
 */
import { Box, CircularProgress, Typography } from '@mui/material';
import { useStore } from 'lnox';
import React from 'react';
import useDidMountEffect from '../../../Hooks/useDidMountEffect';
import { thunkFetchClientApiKeyAction } from '../../../store/actions/clientActions';
import SettingsRow from '../../Common/SettingsRow';
import './MerchantApiTab.scss';

interface MerchantApiTabProps {
  clientId: string;
}
const MerchantApiTab: React.FC<MerchantApiTabProps> = ({ clientId }) => {
  const {
    dispatch,
    state: {
      client: { clients },
    },
  } = useStore();

  const {
    fetchClientApiKey: {
      isPending,
      isFulfilled,
      message: { ApiKey },
    },
  } = clients;

  useDidMountEffect(() => {
    if (clientId) {
      dispatch(thunkFetchClientApiKeyAction(clientId));
    }
  });

  return (
    <Box className="MerchantApiTabContainer">
      {isPending ? (
        <CircularProgress
          sx={{ marginLeft: '45%', marginTop: '2%' }}
          size={24}
        ></CircularProgress>
      ) : (
        <Box className="ApiTabBoxStyle">
          <Typography className="APITabLabelStyle">API</Typography>
          <Box className="StatusContentBoxStyle">
            <SettingsRow title="Status">Active</SettingsRow>
          </Box>

          <Box className="ApikeyContentBoxStyle">
            <SettingsRow title="API Key Value">
              {ApiKey ? ApiKey : '-'}
            </SettingsRow>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MerchantApiTab;
