const creditCardTypesConfig = [
  {
    name: 'visa',
    label: 'Visa',
    patterns: [new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')],
  },
  {
    name: 'amex',
    label: 'American Express',
    patterns: [new RegExp('^3[47][0-9]{13}$')],
  },
  {
    name: 'master',
    label: 'Master Card',
    patterns: [
      new RegExp('^5[1-5][0-9]{14}$'),
      new RegExp('^2[2-7][0-9]{14}$'),
    ],
  },
  {
    name: 'discover',
    label: 'Discover',
    patterns: [
      new RegExp('^6011[0-9]{12}[0-9]*$'),
      new RegExp('^62[24568][0-9]{13}[0-9]*$'),
      new RegExp('^6[45][0-9]{14}[0-9]*$'),
    ],
  },
  {
    name: 'diners',
    label: 'Diners',
    patterns: [new RegExp('^3[0689][0-9]{12}[0-9]*$')],
  },
  {
    name: 'jcb',
    label: 'JCB',
    patterns: [new RegExp('^(?:2131|1800|35[0-9]{3})[0-9]{11}$')],
  },
];

export default (cardNumber: string) => {
  let detectedType = { name: '', label: '' };

  creditCardTypesConfig.forEach((cardConfig) => {
    const { patterns, name, label } = cardConfig;
    patterns?.forEach((pattern) => {
      if (pattern?.test(cardNumber.replace(/\s+/g, ''))) {
        detectedType = { label, name };
      }
    });
  });

  return detectedType;
};
