import React from 'react';
import { DateRangePicker } from 'materialui-daterange-picker';
import moment from 'moment';

interface DateRangePickerInputProps {
  showDateRangePicker: boolean;
  onSelect: (range: any) => void;
  selectedDateRange: any;
}

const DateRangePickerInput: React.FC<DateRangePickerInputProps> = ({
  showDateRangePicker = false,
  onSelect,
  selectedDateRange,
}) => {
  const [open, setOpen] = React.useState(showDateRangePicker);
  const [dateRange, setDateRange] = React.useState({});

  const toggle = () => setOpen(!showDateRangePicker);

  return (
    <DateRangePicker
      open={open}
      toggle={toggle}
      closeOnClickOutside
      onChange={(range) => {
        onSelect && onSelect(range);
        setDateRange(range);
        setOpen(!open);
      }}
      initialDateRange={selectedDateRange}
      definedRanges={[
        {
          startDate: moment().subtract(30, 'days').toDate(),
          endDate: moment().toDate(),
          label: 'Last 30 days',
        },
        {
          startDate: moment().subtract(7, 'days').toDate(),
          endDate: moment().toDate(),
          label: 'Last 7 days',
        },
        {
          startDate: moment().subtract(1, 'month').startOf('M').toDate(),
          endDate: moment().subtract(1, 'month').endOf('M').toDate(),
          label: 'Last Month',
        },
        {
          startDate: moment().subtract(3, 'month').startOf('M').toDate(),
          endDate: moment().subtract(1, 'month').endOf('M').toDate(),
          label: 'Last 3 Months',
        },
        {
          startDate: moment().startOf('year').toDate(),
          endDate: moment().toDate(),
          label: 'This Year',
        },
      ]}
    />
  );
};

export default DateRangePickerInput;
