/**
 * UIConfig provider class
 * used for adding the headers that are required by PCI in the request
 * like xss, csp, reffere
 * For doing this we use Helmet npm package
 */
import React, { createContext, ReactNode } from 'react';
import { Helmet } from 'react-helmet';

export const UiConfigContext = createContext<{ uiConfig: Record<string, any> }>(
  { uiConfig: {} }
);

export const UiConfigProvider: React.FC<{
  children: ReactNode;
  uiConfig: Record<string, any>;
}> = ({ children, uiConfig = {} }) => {
  // TODO: Remove config
  const title =
    process.env.REACT_APP_UI_CONFIG_PROJECT ||
    (window as any).REACT_APP_UI_CONFIG_PROJECT;

  return (
    <UiConfigContext.Provider value={{ uiConfig }}>
      <Helmet>
        <meta http-equiv="X-XSS-Protection" content="0" />
        <meta name="referrer" content="no-referrer" />
        <meta
          http-equiv="Content-Security-Policy"
          content="
          style-src 'self' https://fonts.googleapis.com/ https://fonts.cdnfonts.com/css/sf-pro-display 'unsafe-inline';
          child-src 'self';
          object-src 'none';
          img-src * data:;
          script-src 'nonce-rAnd0m' 'strict-dynamic';"
        />
        <title>{title}</title>
      </Helmet>
      {children}
    </UiConfigContext.Provider>
  );
};
