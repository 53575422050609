/**
 * App Action
 * Used for adding action for the application
 */
import { StatusMessageType } from '../../types/common';
import {
  APP_SET_SCREEN_TITLE,
  APP_SET_STATUS_MESSAGE,
  APP_CLEAR_STATUS_MESSAGES,
} from './types';

export const setScreenTitleAction = (title: string) => ({
  type: APP_SET_SCREEN_TITLE,
  payload: title,
});

export const setStatusMessageAction = (
  type: StatusMessageType,
  text: string
) => ({
  type: APP_SET_STATUS_MESSAGE,
  payload: { type, text },
});

export const clearStatusMessagesAction = () => ({
  type: APP_CLEAR_STATUS_MESSAGES,
});

export const setUserLoggedInAction = (isLoggedIn: boolean) => ({
  type: APP_SET_STATUS_MESSAGE,
  payload: { isLoggedIn: true },
});
