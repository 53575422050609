import { createTheme } from '@mui/material/styles';
import { colors } from '@mui/material';

export const appTheme = createTheme({
  typography: {
    fontFamily: 'SF Pro Display',
    fontWeightLight: 100,
    fontWeightRegular: 110,
    fontWeightMedium: 120,
    fontWeightBold: 130,
    fontSize: 14
  },
  palette: {
    primary: {
      main: '#2A50EE',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': {
          fontFamily: 'SF Pro Display'
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: { borderRadius: '10px' },
      },
    },
    MuiButton: {
      defaultProps: {
        sx: { borderRadius: '10px' },
      },
    },
    MuiLink: {
      defaultProps: {
        sx: {
          fontFamily: 'SF Pro Display',
          fontWeight: 400,
          color: "rgb(42, 80, 238)",
          fontSize: "13px"
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          height: 18,
          width: 18,
        },
        track: {
          height: 20,
          borderRadius: 10,
        },
        switchBase: {
          left: 4,
          top: 4,
        },
        root: {
          width: 65,
        },
      },
    },
  },
});
