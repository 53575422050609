import React, { useState, useMemo } from 'react';
import { Select, MenuItem, ListSubheader, TextField } from '@mui/material';
interface SelectFieldProps {
  id?: any;
  labelId?: any;
  options?: any;
  label: string;
  onChange?: any;
  value?: any;
  className?: any;
  disabled?: boolean;
  error?: boolean;
  onBlur?: any;
  name?: any;
}

const SelectField: React.FC<SelectFieldProps> = ({
  id,
  options,
  label,
  labelId,
  onChange,
  value,
  className,
  disabled = false,
  error = false,
  onBlur,
  name,
}) => {
  const [searchText, setSearchText] = useState('');

  const containsText = (text: any, searchText: string) => {
    return (
      text?.key?.toLowerCase()?.indexOf(searchText?.toLowerCase()) > -1 ||
      text?.value?.toLowerCase()?.indexOf(searchText?.toLowerCase()) > -1
    );
  };

  const displayedOptions = useMemo(
    () =>
      options.filter(
        (option: any) => options && containsText(option, searchText)
      ),
    [searchText]
  );

  return (
    <Select
      className={className}
      MenuProps={{ autoFocus: false }}
      disabled={disabled}
      labelId={labelId}
      id={id}
      name={name}
      value={value}
      label={label}
      onChange={(e) => onChange(e)}
      onBlur={onBlur}
      onClose={() => setSearchText('')}
      error={error}
      defaultValue=""
    >
      {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
      <ListSubheader>
        <TextField
          size="small"
          // Autofocus on textfield
          autoFocus
          fullWidth
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
        />
      </ListSubheader>
      {displayedOptions?.map((option: any, i: any) => (
        <MenuItem key={i} value={option.key}>
          {option.value}
        </MenuItem>
      ))}
    </Select>
  );
};
export default SelectField;
