/*
 Merchant table on Merchant tab under The Account page
*/
import React, { KeyboardEvent } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './MerchantTable.scss';
import '../Layout/Layout.scss';

import { useStore } from 'lnox';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import useDidMountEffect from '../../Hooks/useDidMountEffect';
import {
  resetClientListAction,
  thunkClientFetchListAction,
} from '../../store/actions/clientActions';
import SearchIcon from '@mui/icons-material/Search';
import { Close } from '@mui/icons-material';
import moment from 'moment';
import { DATETIME_FORMAT } from '../../store/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import SideRail from '../Common/SideRail';
import MerchantApiTab from '../MerchantTabs/MerchantApiTab';
import MerchantGeneralTab from '../MerchantTabs/MerchantGeneralTab';
import SaveIcon from '@mui/icons-material/Save';
import BusinessInfo from '../../pages/BusinessInfo';
import DocumentSection from '../MerchantTabs/DocumentSection';
import PrincipleInformation from '../PrincipleInformation/PrincipleInformation';
import MerchantHostTab from '../MerchantTabs/MerchantHostTab';
import AddIcon from '@mui/icons-material/Add';
import {
  thunkCreateShazamHostAction,
  thunkCreateTokenizationHostAction,
  thunkCreateTsysHostAction,
} from '../../store/actions/hostActions';
import { searchObjectForString } from '../../libs/helpers';

interface PayloadProps {
  skip?: number;
  take?: number;
  searchText?: string;
  sortOrder?: string;
  sortColumn?: string;
}


export enum MerchantDetailsTabIndex {
  General = 0,
  Hosts = 1,
  Api = 2,
}


function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MerchantTable: React.FC = () => {
  const {
    dispatch,
    state: {
      client: { clients },
    },
  } = useStore();

  const {
    fetch: {
      isPending,
      isFulfilled,
      message: { AllUsers: clientResults = [] },
    },
    host: {
      createTsysHost: {
        isPending: createTsysHostPending,
        isFulfilled: createTsysHostFulfilled,
      },
    },
  } = clients;

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [clientListToSkip, setClientListToSkip] = useState<number>(0);
  const [showMerchantSideRail, setShowMerchantSideRail] =
    useState<boolean>(false);
  const [merchantDetailsActiveTabIndex, setMerchantDetailsActiveTabIndex] =
    useState<number>(0);
  const [selectedMerchantDetails, setSelectedMerchantDetails] = useState<any>(
    {}
  );
  const [merchantEditIndex, setMerchantEditIndex] = useState<number>(0);
  const [newPrinciple, setNewPrinciple] = useState(false);
  const [addPrinciple, setAddPrinciple] = useState<any>([]);
  const [addNewHost, setAddNewHost] = useState<boolean>(false);
  const [newHostSelected, setNewHostSelected] = useState<boolean>(false);
  const [isCancelClicked, setIsCancelClicked] = useState<boolean>(false);
  const [hostConfig, setHostConfig] = useState<any>({});
  const [isTokenizationFormValid, setIsTokenizationFormValid] =
    useState<boolean>(true);
  const [isTsysFormValid, setIsTsysFormValid] = useState<boolean>(true);
  const [isShazamFormValid, setIsShazamFormValid] = useState<boolean>(true);

  const DEFAULT_CLIENT_PAYLOAD: PayloadProps = {
    take: 40,
    searchText: searchTerm,
    sortOrder: '-1',
    sortColumn: 'DateAdded',
  };


  useEffect(() => {
    renderMerchantDetailsGeneralTab();
  }, [merchantEditIndex]);

  // fetch client list
  const fetchClientsList = (search?: any) => {
    if (isPending === true) {
      return;
    }

    const queryParams = {
      ...DEFAULT_CLIENT_PAYLOAD,
    };
    if (search && search.skip) {
      queryParams.skip = clientListToSkip;
    }
    dispatch(
      thunkClientFetchListAction({
        ...queryParams,
      })
    );
  };

  const handleOnMoreClients = () => {
    fetchClientsList({ skip: clientListToSkip });
  };

  const handleSearchFilterChange = (e: ChangeEvent<any>) => {
    const {
      target: { value },
    } = e;
    setSearchTerm(value);
  };

  // render Merchant list filter
  const MerchantFilter = () => {
    return (
      <Box>
        <TextField
          placeholder="Search"
          className="SearchBox"

          onChange={handleSearchFilterChange}

          onKeyDown={(e: KeyboardEvent<any>) => {
            if (e.keyCode === 13) {
              fetchClientsList();
            }
          }}
          value={searchTerm}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="start">
                <IconButton>
                  <Close
                    color={'primary'}
                    onClick={() => {
                      setSearchTerm('');
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  };

  const renderTableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell className="MerchantTableHeader">Merchant DBA</TableCell>
        <TableCell className="MerchantTableHeader">Principal / Owner</TableCell>
        <TableCell className="MerchantTableHeader">Contact</TableCell>
        <TableCell className="MerchantTableHeader">Accepted Date</TableCell>
        <TableCell className="MerchantTableHeader">Client ID</TableCell>
      </TableRow>
    </TableHead>
  );

  const renderTableBody = () => (
    <TableBody>
      {clientResults && clientResults.length > 0 ? renderClientRows() : renderDefaultMessage()}
    </TableBody>
  );

  const renderDefaultMessage = () => (
    <TableRow>
      <TableCell colSpan={10} align="center">
        No Data To Show
      </TableCell>
    </TableRow>
  );

  const renderClientRows = () => {
    const searchWord = (searchTerm) ? searchTerm.trim() : "";

    let results: [] = [];

    if (searchWord === "") {
      results = clientResults;
    } else if (clientResults) {
      results = clientResults.filter((transRow: any) => {
        return searchObjectForString(searchWord, transRow);
      });
    }

    return (
      results.map((row: any) => {
        return (
          <TableRow
            key={Math.random()}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            className="MerchantTableRowStyle"
            onClick={() => {
              setAddNewHost(false);
              setNewHostSelected(false);
              setIsCancelClicked(true);
              handleOnViewDetailsClick(row);
            }}
          >
            <TableCell>{row?.Name || '-'}</TableCell>
            <TableCell>{`${row?.FirstName || ''} ${row?.LastName || ''
              }`}</TableCell>
            <TableCell>{row?.EmailAddress || '-'}</TableCell>
            <TableCell>
              {row?.DateAdded
                ? moment(row?.DateAdded).format(DATETIME_FORMAT)
                : '-'}
            </TableCell>
            <TableCell>{row?.Id || '-'}</TableCell>
          </TableRow>
        );
      })
    );
  };

  const handleOnViewDetailsClick = (row: any) => {
    setMerchantDetailsActiveTabIndex(0);
    setShowMerchantSideRail(true);
    setSelectedMerchantDetails(row);
  };

  const renderMerchantDetailsTitle = () => {
    return (
      <Box width="100%">
        <Box width="100%">
          <Typography
            fontSize="x-large"
            fontWeight="bold"
            className="SideRailTitleStyle"
          >
            {selectedMerchantDetails?.Name}
          </Typography>
        </Box>
        <Typography fontSize="medium" margin={{ top: 'xsmall' }}>
          {!newPrinciple ? `Client ID: ${selectedMerchantDetails.Id}` : ''}
        </Typography>
        <Box width="100%">
          <Tabs
            value={merchantDetailsActiveTabIndex}
            onChange={handleMerchantDetailsTabChange}
            aria-label="basic tabs example"
          >
            <Tab className="TabStyle" label="General" {...a11yProps(0)} />
            <Tab className="TabStyle" label="Hosts" {...a11yProps(1)} />
            <Tab className="TabStyle " label="API" {...a11yProps(2)} />
          </Tabs>
        </Box>
      </Box>
    );
  };

  const handleHostOnSave = () => {
    if (hostConfig?.values?.tsys?.IsChanged) {
      dispatch(thunkCreateTsysHostAction(hostConfig?.values?.tsys));
    }
    if (hostConfig?.values?.tokenization?.IsChanged) {
      dispatch(
        thunkCreateTokenizationHostAction(hostConfig?.values?.tokenization)
      );
    }
    if (hostConfig?.values?.shazam?.IsChanged) {
      dispatch(thunkCreateShazamHostAction(hostConfig?.values?.shazam));
    }
  };

  const renderHostTab = () => (
    <MerchantHostTab
      addNewHost={addNewHost}
      newHostSelected={newHostSelected}
      isCancelClicked={isCancelClicked}
      onConfigChange={(values: any) => {
        setHostConfig((prevState: any) => ({
          ...prevState,
          values,
        }));
      }}
      selectedMerchantId={selectedMerchantDetails?.Id}
      isDisabled={createTsysHostPending}
      setIsTokenizationFormValid={setIsTokenizationFormValid}
      setIsTsysFormValid={setIsTsysFormValid}
      setIsShazamFormValid={setIsShazamFormValid}
    ></MerchantHostTab>
  );

  const renderMerchantDetailsTabPanes = (index: MerchantDetailsTabIndex) => {
    switch (index) {
      case MerchantDetailsTabIndex.Hosts:
        return (
          <>
            {renderHostTab()}
          </>
        );
      case MerchantDetailsTabIndex.Api:
        return (
          <>
            {renderMerchantDetailsApiTab()}
          </>
        );

      default:
        return (
          <>
            {renderMerchantDetailsGeneralTab()}
          </>
        );
    }
  };

  const isEdit = (value: any) => {
    setNewPrinciple(value);
  };

  const createPrinciple = (e: any) => {
    e.preventDefault();
    setNewPrinciple(false);
  };

  const principalInfoData = (value: any) => {
    setAddPrinciple(value);
  };

  const handleMerchantDetailsTabChange = (
    event: React.SyntheticEvent,
    index: number
  ) => {
    setAddNewHost(false);
    setNewHostSelected(false);
    setIsCancelClicked(true);
    setMerchantDetailsActiveTabIndex(index);
    setMerchantEditIndex(0);
  };

  const renderMerchantDetailsApiTab = () => {
    return (
      <MerchantApiTab clientId={selectedMerchantDetails.Id}></MerchantApiTab>
    );
  };

  const handleMerchantEditIndexChange = (index: number) => {
    setMerchantEditIndex(index);
  };

  const renderMerchantDetailsGeneralTab = () => {
    switch (merchantEditIndex) {
      case 1:
        return <BusinessInfo></BusinessInfo>;
      case 2:
        return <PrincipleInformation></PrincipleInformation>;
      case 3:
        return <DocumentSection />;
      default:
        return (
          <MerchantGeneralTab
            merchantEditIndexChange={handleMerchantEditIndexChange}
          ></MerchantGeneralTab>
        );
    }
  };

  const renderMerchantDetailsFooter = () => {
    switch (merchantDetailsActiveTabIndex) {
      case MerchantDetailsTabIndex.Hosts:
        return (
          <Box className="footerClass EditButtonAlign">
            {!addNewHost && (
              <Button
                variant="outlined"
                className="SideRailfooterButtonStyle"
                disabled={false}
                onClick={() => {
                  setAddNewHost(true);
                }}
              >
                <AddIcon />
                New Host
              </Button>
            )}

            {addNewHost && !newHostSelected && (
              <Box>
                <Button
                  variant="outlined"
                  className="SideRailfooterButtonStyle"
                  disabled={false}
                  onClick={() => {
                    setAddNewHost(false);
                    setNewHostSelected(false);
                    setIsCancelClicked(true);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  className="SideRailfooterButtonStyle"
                  disabled={false}
                  onClick={() => {
                    setNewHostSelected(true);
                  }}
                >
                  Next
                </Button>
              </Box>
            )}
            {addNewHost && newHostSelected && (
              <Box>
                <Button
                  variant="outlined"
                  className="SideRailfooterButtonStyle"
                  disabled={false}
                  onClick={() => {
                    setAddNewHost(false);
                    setNewHostSelected(false);
                    setIsCancelClicked(true);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  className="SideRailfooterButtonStyle"
                  disabled={
                    !isTokenizationFormValid ||
                    !isTsysFormValid ||
                    !isShazamFormValid
                  }
                  onClick={handleHostOnSave}
                >
                  {createTsysHostPending && (
                    <CircularProgress
                      sx={{ marginRight: '15px' }}
                      size={24}
                    ></CircularProgress>
                  )}
                  Add
                </Button>
              </Box>
            )}
          </Box>
        );
      case MerchantDetailsTabIndex.Api:
        return '';

      default:
        return merchantEditIndex ? (
          <Box className="footerClass EditButtonAlign">
            <Button
              variant="outlined"
              className="SideRailfooterButtonStyle"
              disabled={false}
              onClick={() => {
                setMerchantEditIndex(0);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="SideRailfooterButtonStyle"
              onClick={() => {
                setMerchantEditIndex(0);
              }}
              disabled={false}
            >
              <SaveIcon />
              Save
            </Button>
          </Box>
        ) : (
          ''
        );
    }
  };

  const renderMerchantDetails = () => {
    return (
      <SideRail
        open={showMerchantSideRail}
        title={renderMerchantDetailsTitle()}
        onClose={() => {
          setShowMerchantSideRail(false);
        }}
        footer={renderMerchantDetailsFooter()}
      >
        <Box>
          {renderMerchantDetailsTabPanes(merchantDetailsActiveTabIndex)}
        </Box>
      </SideRail>
    );
  };

  return (

    <Grid container>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container direction={"row"}>
        <div className={"ReportingMainPageItemRowContent"}>
          {MerchantFilter()}
        </div>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item container direction={"row"}>
        <div className={"ReportingMainPageItemRowBottomContent"}>
          {isPending && (
            <CircularProgress
              sx={{ marginLeft: '45%', marginTop: '2%' }}
              size={24}
            ></CircularProgress>
          )}
          {(isPending === false) && (<>

            <InfiniteScroll
              dataLength={(clientResults) ? clientResults.length : 0}
              next={handleOnMoreClients}
              //To put endMessage and loader to the top.
              hasMore={false}
              loader={<></>}
              scrollableTarget="clientTableID"
            >
              <TableContainer
                id={'clientTableID'}
                className="MerchantTable"
                component={Paper}
                style={{
                  height: "calc(100vh - 330px)",
                  overflow: "auto"
                }}
              >
                <Table
                  stickyHeader
                  aria-label="simple table"
                >
                  {renderTableHeader()}
                  {renderTableBody()}
                </Table>
              </TableContainer>
            </InfiniteScroll>

          </>
          )}
          {renderMerchantDetails()}
        </div>
      </Grid>
    </Grid>

  );
};

export default MerchantTable;
