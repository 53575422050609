/**
 * Validation schema for User form
 * Validation is done using YUP
 */
import * as Yup from 'yup';
import {
  emailValidationRule,
  passwordValidationRule,
  requiredTextFieldValidationRule,
  reuiredSelectFieldValidationRule,
  fieldMatchValidationRuleCustomeMessage,
} from '../Forms/validationRules';

export const validationSchema = (requiredFields: any) =>
  Yup.object().shape({
    FirstName: Yup.lazy(() => {
      let validationSchema = requiredFields.includes('FirstName')
        ? requiredTextFieldValidationRule('First Name')
        : Yup.string();
      return validationSchema;
    }),
    LastName: Yup.lazy(() => {
      let validationSchema = requiredFields.includes('LastName')
        ? requiredTextFieldValidationRule('Last Name')
        : Yup.string();
      return validationSchema;
    }),
    Timezone: Yup.lazy(() => {
      let validationSchema = requiredFields.includes('Timezone')
        ? reuiredSelectFieldValidationRule('Timezone')
        : Yup.string();
      return validationSchema;
    }),
    Password: Yup.lazy(() => {
      let validationSchema = requiredFields.includes('Password')
        ? passwordValidationRule('Password')
        : Yup.string();
      return validationSchema;
    }),
    ConfirmPassword: Yup.lazy(() => {
      let validationSchema = requiredFields.includes('ConfirmPassword')
        ? fieldMatchValidationRuleCustomeMessage(
            'Password',
            'Confirm Password',
            'Password'
          )
        : Yup.string();
      return validationSchema;
    }),
    Email: Yup.lazy(() => {
      let validationSchema = requiredFields.includes('Email')
        ? emailValidationRule('Email', true)
        : Yup.string();
      return validationSchema;
    }),
    ConfirmEmail: Yup.lazy(() => {
      let validationSchema = requiredFields.includes('ConfirmEmail')
        ? fieldMatchValidationRuleCustomeMessage(
            'Email',
            'Confirm Email',
            'Email'
          )
        : Yup.string();
      return validationSchema;
    }),
    ConfirmPhone: Yup.lazy(() => {
      let validationSchema = requiredFields.includes('ConfirmPhone')
        ? fieldMatchValidationRuleCustomeMessage(
            'PhoneNumber',
            'Confirm Phone Number',
            'PhoneNumber'
          )
        : Yup.string();
      return validationSchema;
    }),
  });
