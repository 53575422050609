/**
 * Merchant General tab
 */
import { Box, Button, Link, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import SettingsRow from '../../Common/SettingsRow';
import EditIcon from '@mui/icons-material/Edit';
import './MerchantGeneralTab.scss';
import {
  DATE1_FORMAT,
  DATETIME_FORMAT,
  OWNERSHIP_TYPE_OPTIONS,
  PRINCIPAL_OWERNER_TYPE_OPTIONS,
} from '../../../store/constants';
import moment from 'moment';

interface MerchantGeneralTab {
  merchantEditIndexChange: (index: number) => void;
}

const MerchantGeneralTab: React.FC<MerchantGeneralTab> = ({
  merchantEditIndexChange,
}) => {
  let businessInfoData: any = {};
  let principalInfoData: any = [];
  let documentInfoData: any = [];

  const getFormattedPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber && phoneNumber.length) {
      phoneNumber = phoneNumber.replace('+1', '');
      return `(${phoneNumber.substring(1, 4)}) ${phoneNumber.substring(
        4,
        7
      )}- ${phoneNumber.substring(7, 11)}`;
    }
    return '-';
  };

  const getFormattedZipCode = (zipCode: string) => {
    if (zipCode && zipCode.length) {
      zipCode = zipCode.replace('-', '');
      return `${zipCode.substring(0, 5)} ${
        zipCode.length > 5 ? '-' + zipCode.substring(5, zipCode.length) : ''
      }`;
    }
    return '-';
  };

  const formatPhoneNumber = (val: any) => {
    if (val.includes('+1')) {
      return val.replace('+1', '');
    }
  };

  const renderBusinessInfoSection = () => {
    return (
      <Box className="SectoinStyle">
        <Box className="AccordianHeaderStyle">
          <Typography fontWeight="bold" width="100%">
            Business Information
          </Typography>
          <Box className="EditButtonBoxStyle">
            <Button
              variant="text"
              className="EditButtonStyle"
              onClick={() => {
                merchantEditIndexChange && merchantEditIndexChange(1);
              }}
            >
              <EditIcon fontSize="small" />
              EDIT
            </Button>
          </Box>
        </Box>
        <Box className="CardRow">
          <Box className="Width45">
            <SettingsRow title="Merchant Legal Name">
              <Tooltip
                title={
                  businessInfoData?.merchantLegalName
                    ? businessInfoData?.merchantLegalName
                    : '-'
                }
                arrow
              >
                <Typography noWrap>
                  {businessInfoData?.merchantLegalName
                    ? businessInfoData?.merchantLegalName
                    : '-'}
                </Typography>
              </Tooltip>
            </SettingsRow>
          </Box>
          <Box className="Width45">
            <SettingsRow title="Merchant Name (DBA or Trade Name)">
              <Tooltip
                title={
                  businessInfoData?.merchantName
                    ? businessInfoData?.merchantName
                    : '-'
                }
                arrow
              >
                <Typography noWrap>
                  {businessInfoData?.merchantName
                    ? businessInfoData?.merchantName
                    : '-'}
                </Typography>
              </Tooltip>
            </SettingsRow>
          </Box>
        </Box>
        <Box className="CardRow">
          <Box className="Width45">
            <SettingsRow title="Ownership Type">
              <Tooltip
                title={
                  businessInfoData?.ownershipType
                    ? OWNERSHIP_TYPE_OPTIONS.filter(
                        (x) => x.key == businessInfoData.ownershipType
                      )[0]?.value
                    : '-'
                }
                arrow
              >
                <Typography noWrap>
                  {businessInfoData.ownershipType
                    ? OWNERSHIP_TYPE_OPTIONS.filter(
                        (x) => x.key == businessInfoData.ownershipType
                      )[0]?.value
                    : '-'}
                </Typography>
              </Tooltip>
            </SettingsRow>
          </Box>
          <Box className="Width45">
            <SettingsRow title="Fedral Tax ID (TIN)">
              <Tooltip
                title={
                  businessInfoData?.fedTaxId ? businessInfoData?.fedTaxId : '-'
                }
                arrow
              >
                <Typography noWrap>
                  {businessInfoData?.fedTaxId
                    ? businessInfoData?.fedTaxId
                    : '-'}
                </Typography>
              </Tooltip>
            </SettingsRow>
          </Box>
        </Box>
        <Box className="CardRow">
          <Box className="Width45">
            <SettingsRow title="Number of Employees">
              <Tooltip
                title={
                  businessInfoData?.numberOfEmployee
                    ? businessInfoData?.numberOfEmployee
                    : '-'
                }
                arrow
              >
                <Typography noWrap>
                  {businessInfoData?.numberOfEmployee
                    ? businessInfoData?.numberOfEmployee
                    : '-'}
                </Typography>
              </Tooltip>
            </SettingsRow>
          </Box>
          <Box className="Width45">
            <SettingsRow title="Date Established">
              <Tooltip
                title={
                  businessInfoData?.dateEstablished
                    ? moment(businessInfoData?.dateEstablished).format(
                        DATETIME_FORMAT
                      )
                    : '-'
                }
                arrow
              >
                <Typography noWrap>
                  {businessInfoData?.dateEstablished
                    ? moment(businessInfoData?.dateEstablished).format(
                        DATETIME_FORMAT
                      )
                    : '-'}
                </Typography>
              </Tooltip>
            </SettingsRow>
          </Box>
        </Box>
        <Box className="CardRow">
          <Box className="Width45">
            <SettingsRow title="State of Incorporation">
              <Tooltip
                title={
                  businessInfoData?.stateIncorporation
                    ? businessInfoData?.stateIncorporation
                    : '-'
                }
                arrow
              >
                <Typography noWrap>
                  {businessInfoData?.stateIncorporation
                    ? businessInfoData?.stateIncorporation
                    : '-'}
                </Typography>
              </Tooltip>
            </SettingsRow>
          </Box>
          <Box className="Width45">
            <SettingsRow title="Company's Website">
              <Tooltip
                title={
                  businessInfoData?.companyWebsite
                    ? businessInfoData?.companyWebsite
                    : '-'
                }
                arrow
              >
                <Typography noWrap>
                  {businessInfoData?.companyWebsite
                    ? businessInfoData?.companyWebsite
                    : '-'}
                </Typography>
              </Tooltip>
            </SettingsRow>
          </Box>
        </Box>
        <Box className="CardRow">
          <Box className="Width45">
            <SettingsRow title="Business Phone">
              <Tooltip
                title={
                  businessInfoData?.bussinessPhone
                    ? getFormattedPhoneNumber(businessInfoData?.bussinessPhone)
                    : '-'
                }
                arrow
              >
                <Typography noWrap>
                  {businessInfoData?.bussinessPhone
                    ? getFormattedPhoneNumber(businessInfoData?.bussinessPhone)
                    : '-'}
                </Typography>
              </Tooltip>
            </SettingsRow>
          </Box>
        </Box>
        <Typography className="SectionTitle">Merchant Category Code</Typography>
        <Box className="CardRow">
          <Box className="Width45">
            <SettingsRow title="Industry Type">
              <Tooltip
                title={
                  businessInfoData?.mccIndustryType
                    ? businessInfoData?.mccIndustryType
                    : '-'
                }
                arrow
              >
                <Typography noWrap>
                  {businessInfoData?.mccIndustryType
                    ? businessInfoData?.mccIndustryType
                    : '-'}
                </Typography>
              </Tooltip>
            </SettingsRow>
          </Box>
          <Box className="Width45">
            <SettingsRow title="Merchant Type">
              <Tooltip
                title={
                  businessInfoData?.mccMerchantType
                    ? businessInfoData?.mccMerchantType
                    : '-'
                }
                arrow
              >
                <Typography noWrap>
                  {businessInfoData?.mccMerchantType
                    ? businessInfoData?.mccMerchantType
                    : '-'}
                </Typography>
              </Tooltip>
            </SettingsRow>
          </Box>
        </Box>
        <Box className="CardRow">
          <Box className="Width100">
            <SettingsRow title="Category Code">
              <Tooltip
                title={
                  businessInfoData?.categoryCode
                    ? businessInfoData?.categoryCode
                    : '-'
                }
                arrow
              >
                <Typography noWrap>
                  {businessInfoData?.categoryCode
                    ? businessInfoData?.categoryCode
                    : '-'}
                </Typography>
              </Tooltip>
            </SettingsRow>
          </Box>
        </Box>
        {businessInfoData &&
          businessInfoData?.bussinessAddress &&
          businessInfoData?.bussinessAddress?.map(
            (bussinessAddress: any, index: any) => (
              <Box>
                <Typography className="SectionTitle">
                  Business Address (No PO Box)
                </Typography>
                <Box className="CardRow">
                  <Box className="Width45">
                    <SettingsRow title="Street Address">
                      <Tooltip
                        title={
                          businessInfoData?.address1
                            ? businessInfoData?.address1
                            : '-'
                        }
                        arrow
                      >
                        <Typography noWrap>
                          {businessInfoData?.address1
                            ? businessInfoData?.address1
                            : '-'}
                        </Typography>
                      </Tooltip>
                    </SettingsRow>
                  </Box>
                  <Box className="Width45">
                    <SettingsRow title="Apt., Building, Suite, Floor">
                      <Tooltip
                        title={
                          businessInfoData?.address2
                            ? businessInfoData?.address2
                            : '-'
                        }
                        arrow
                      >
                        <Typography noWrap>
                          {businessInfoData?.address2
                            ? businessInfoData?.address2
                            : '-'}
                        </Typography>
                      </Tooltip>
                    </SettingsRow>
                  </Box>
                </Box>
                <Box className="CardRow">
                  <Box className="Width45">
                    <SettingsRow title="City">
                      <Tooltip
                        title={
                          businessInfoData?.city ? businessInfoData?.city : '-'
                        }
                        arrow
                      >
                        <Typography noWrap>
                          {businessInfoData?.city
                            ? businessInfoData?.city
                            : '-'}
                        </Typography>
                      </Tooltip>
                    </SettingsRow>
                  </Box>
                  <Box className="Width45">
                    <SettingsRow title="State">
                      <Tooltip
                        title={
                          businessInfoData?.state
                            ? businessInfoData?.state
                            : '-'
                        }
                        arrow
                      >
                        <Typography noWrap>
                          {businessInfoData?.state
                            ? businessInfoData?.state
                            : '-'}
                        </Typography>
                      </Tooltip>
                    </SettingsRow>
                  </Box>
                </Box>
                <Box className="CardRow">
                  <Box className="Width45">
                    <SettingsRow title="Zip Code">
                      <Tooltip
                        title={
                          businessInfoData?.zipCode
                            ? getFormattedZipCode(businessInfoData?.zipCode)
                            : '-'
                        }
                        arrow
                      >
                        <Typography noWrap>
                          {businessInfoData?.zipCode
                            ? getFormattedZipCode(businessInfoData?.zipCode)
                            : '-'}
                        </Typography>
                      </Tooltip>
                    </SettingsRow>
                  </Box>
                  <Box className="Width45">
                    <SettingsRow title="Country">
                      <Tooltip
                        title={
                          businessInfoData?.country
                            ? businessInfoData?.country
                            : '-'
                        }
                        arrow
                      >
                        <Typography noWrap>
                          {businessInfoData?.country
                            ? businessInfoData?.country
                            : '-'}
                        </Typography>
                      </Tooltip>
                    </SettingsRow>
                  </Box>
                </Box>
              </Box>
            )
          )}
      </Box>
    );
  };

  const renderPrincipalInfoSection = () => {
    return (
      <Box className="SectoinStyle">
        <Box className="AccordianHeaderStyle">
          <Typography fontWeight="bold" width="100%">
            Principal Information
          </Typography>
          <Box className="EditButtonBoxStyle">
            <Button
              variant="text"
              className="EditButtonStyle"
              onClick={() => {
                merchantEditIndexChange && merchantEditIndexChange(2);
              }}
            >
              <EditIcon fontSize="small" />
              EDIT
            </Button>
          </Box>
        </Box>
        {principalInfoData &&
          principalInfoData?.map((principalInfo: any, index: any) => (
            <Box>
              <Box className="CardRow">
                <Box className="Width45">
                  <SettingsRow title="Primary Contact Role">
                    <Tooltip
                      title={
                        principalInfoData[index]?.primaryContactRole
                          ? PRINCIPAL_OWERNER_TYPE_OPTIONS.filter(
                              (x) => x.key == principalInfo?.primaryContactRole
                            )[0]?.value
                          : '-'
                      }
                      arrow
                    >
                      <Typography noWrap>
                        {principalInfoData[index]?.primaryContactRole
                          ? PRINCIPAL_OWERNER_TYPE_OPTIONS.filter(
                              (x) => x.key == principalInfo?.primaryContactRole
                            )[0]?.value
                          : '-'}
                      </Typography>
                    </Tooltip>
                  </SettingsRow>
                </Box>
                <Box className="Width45">
                  <SettingsRow title="Percent Ownership">
                    <Tooltip
                      title={
                        principalInfoData[index]?.percentOwnership
                          ? principalInfoData[index]?.percentOwnership
                          : '-'
                      }
                      arrow
                    >
                      <Typography noWrap>
                        {principalInfoData[index]?.percentOwnership
                          ? principalInfoData[index]?.percentOwnership
                          : '-'}
                      </Typography>
                    </Tooltip>
                  </SettingsRow>
                </Box>
              </Box>
              <Box className="CardRow">
                <Box className="Width45">
                  <SettingsRow title="First Name">
                    <Tooltip
                      title={
                        principalInfoData[index]?.firstName
                          ? principalInfoData[index]?.firstName
                          : '-'
                      }
                      arrow
                    >
                      <Typography noWrap>
                        {principalInfoData[index]?.firstName
                          ? principalInfoData[index]?.firstName
                          : '-'}
                      </Typography>
                    </Tooltip>
                  </SettingsRow>
                </Box>
                <Box className="Width45">
                  <SettingsRow title="Last Name">
                    <Tooltip
                      title={
                        principalInfoData[index]?.lastName
                          ? principalInfoData[index]?.lastName
                          : '-'
                      }
                      arrow
                    >
                      <Typography noWrap>
                        {principalInfoData[index]?.lastName
                          ? principalInfoData[index]?.lastName
                          : '-'}
                      </Typography>
                    </Tooltip>
                  </SettingsRow>
                </Box>
              </Box>
              <Box className="CardRow">
                <Box className="Width45">
                  <SettingsRow title="Primary Contact Email">
                    <Tooltip
                      title={
                        principalInfoData[index]?.Email
                          ? principalInfoData[index]?.Email
                          : '-'
                      }
                      arrow
                    >
                      <Typography noWrap>
                        {principalInfoData[index]?.Email
                          ? principalInfoData[index]?.Email
                          : '-'}
                      </Typography>
                    </Tooltip>
                  </SettingsRow>
                </Box>
                <Box className="Width45">
                  <SettingsRow title="Primary Contact Phone Number">
                    <Tooltip
                      title={
                        principalInfoData?.primaryContactNumber
                          ? formatPhoneNumber(
                              principalInfoData?.primaryContactNumber
                            )
                          : '-'
                      }
                      arrow
                    >
                      <Typography noWrap>
                        {principalInfoData?.primaryContactNumber
                          ? formatPhoneNumber(
                              principalInfoData?.primaryContactNumber
                            )
                          : '-'}
                      </Typography>
                    </Tooltip>
                  </SettingsRow>
                </Box>
              </Box>
              <Box className="CardRow">
                <Box className="Width45">
                  <SettingsRow title="Date of Birth">
                    <Tooltip
                      title={
                        principalInfoData[index]?.dateOfBirth
                          ? moment(
                              principalInfoData[index]?.dateOfBirth
                            ).format(DATE1_FORMAT)
                          : '-'
                      }
                      arrow
                    >
                      <Typography noWrap>
                        {principalInfoData[index]?.dateOfBirth
                          ? moment(
                              principalInfoData[index]?.dateOfBirth
                            ).format(DATE1_FORMAT)
                          : '-'}
                      </Typography>
                    </Tooltip>
                  </SettingsRow>
                </Box>
                <Box className="Width45">
                  <SettingsRow title="Last 4 of Social Security Number (SSN)">
                    <Tooltip
                      title={
                        principalInfoData[index]?.ssn
                          ? '***-**-' +
                            principalInfo?.ssn?.substring(
                              principalInfo?.ssn?.length - 4
                            )
                          : '-'
                      }
                      arrow
                    >
                      <Typography noWrap>
                        {principalInfoData[index]?.ssn
                          ? '***-**-' +
                            principalInfo?.ssn?.substring(
                              principalInfo?.ssn?.length - 4
                            )
                          : '-'}
                      </Typography>
                    </Tooltip>
                  </SettingsRow>
                </Box>
              </Box>
              <Box>
                <Typography className="SectionTitle">
                  Principal Address {index + 1} (No PO Box)
                </Typography>
                <Box className="CardRow">
                  <Box className="Width45">
                    <SettingsRow title="Street Address">
                      <Tooltip
                        title={
                          principalInfoData[index]?.principleAddress1
                            ? principalInfoData[index]?.principleAddress1
                            : '-'
                        }
                        arrow
                      >
                        <Typography noWrap>
                          {principalInfoData[index]?.principleAddress1
                            ? principalInfoData[index]?.principleAddress1
                            : '-'}
                        </Typography>
                      </Tooltip>
                    </SettingsRow>
                  </Box>
                  <Box className="Width45">
                    <SettingsRow title="Apt., Building, Suite, Floor">
                      <Tooltip
                        title={
                          principalInfoData[index]?.principleAddress2
                            ? principalInfoData[index]?.principleAddress2
                            : '-'
                        }
                        arrow
                      >
                        <Typography noWrap>
                          {principalInfoData[index]?.principleAddress2
                            ? principalInfoData[index]?.principleAddress2
                            : '-'}
                        </Typography>
                      </Tooltip>
                    </SettingsRow>
                  </Box>
                </Box>
                <Box className="CardRow">
                  <Box className="Width45">
                    <SettingsRow title="City">
                      <Tooltip
                        title={
                          principalInfoData[index]?.principleCity
                            ? principalInfoData[index]?.principleCity
                            : '-'
                        }
                        arrow
                      >
                        <Typography noWrap>
                          {principalInfoData[index]?.principleCity
                            ? principalInfoData[index]?.principleCity
                            : '-'}
                        </Typography>
                      </Tooltip>
                    </SettingsRow>
                  </Box>
                  <Box className="Width45">
                    <SettingsRow title="State">
                      <Tooltip
                        title={
                          principalInfoData[index]?.principleState
                            ? principalInfoData[index]?.principleState
                            : '-'
                        }
                        arrow
                      >
                        <Typography noWrap>
                          {principalInfoData[index]?.principleState
                            ? principalInfoData[index]?.principleState
                            : '-'}
                        </Typography>
                      </Tooltip>
                    </SettingsRow>
                  </Box>
                </Box>
                <Box className="CardRow">
                  <Box className="Width45">
                    <SettingsRow title="Zip Code">
                      <Tooltip
                        title={
                          principalInfoData[index]?.principleZipCode
                            ? getFormattedZipCode(
                                principalInfoData[index]?.principleZipCode
                              )
                            : '-'
                        }
                        arrow
                      >
                        <Typography noWrap>
                          {principalInfoData[index]?.principleZipCode
                            ? getFormattedZipCode(
                                principalInfoData[index]?.principleZipCode
                              )
                            : '-'}
                        </Typography>
                      </Tooltip>
                    </SettingsRow>
                  </Box>
                  <Box className="Width45">
                    <SettingsRow title="Country">
                      <Tooltip
                        title={
                          principalInfoData[index]?.principleCountry
                            ? principalInfoData[index]?.principleCountry
                            : '-'
                        }
                        arrow
                      >
                        <Typography noWrap>
                          {principalInfoData[index]?.principleCountry
                            ? principalInfoData[index]?.principleCountry
                            : '-'}
                        </Typography>
                      </Tooltip>
                    </SettingsRow>
                  </Box>
                </Box>
                <Box className="CardRow">
                  <Box className="Width100">
                    <SettingsRow title="Did This Person File Personal Or Business Bankruptcy in The Past 10 Years?">
                      <Tooltip
                        title={principalInfo.isBankrupt ? 'Yes' : 'No'}
                        arrow
                      >
                        <Typography noWrap>
                          {principalInfo.isBankrupt ? 'Yes' : 'No'}
                        </Typography>
                      </Tooltip>
                    </SettingsRow>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    );
  };

  const renderDocumentInfoSection = () => {
    return (
      <Box className="SectoinStyle">
        <Box className="AccordianHeaderStyle">
          <Typography fontWeight="bold" width="100%">
            Documents
          </Typography>
          <Box className="EditButtonBoxStyle">
            <Button
              variant="text"
              className="EditButtonStyle"
              onClick={() => {
                merchantEditIndexChange && merchantEditIndexChange(3);
              }}
            >
              <EditIcon fontSize="small" />
              EDIT
            </Button>
          </Box>
        </Box>
        <Box className="CardRow">{renderDocumentList()}</Box>
        <Box className="CardRow">
          <Box className="Width45">
            <Typography fontWeight="bold">General Notes</Typography>
            <Typography noWrap>No general notes have been added</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderDocumentList = () => {
    return (
      <Box>
        {documentInfoData && documentInfoData.length ? (
          documentInfoData &&
          documentInfoData.map((documentInfo: any) => {
            return (
              <Box>
                {/* <Link href="#" style={{ width: "100%" }}
              onClick={async () => {
                if (documentInfo.documentKey) {
                  let response = await dispatch(thunkOnboardingFileDownloadAction(documentInfo.documentKey));
                  if (response && response.value && !response.value.error && documentInfo.fileName) {
                    const url = window.URL.createObjectURL(new Blob([response.value.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', documentInfo.fileName);
                    document.body.appendChild(link);
                    link.click();
                  }
                }
              }
              }  >{documentInfo.fileName}
              <Typography style={{ fontSize: "12px", fontWeight: 'bold', color: '#7C919D' }}>
                {`  (${(100000 / 1048576).toFixed(2)} MB)`}
              </Typography>
            </Link> */}
              </Box>
            );
          })
        ) : (
          <Box>No Documents have been added</Box>
        )}
      </Box>
    );
  };

  return (
    <Box className="MerchantGeneralTabContainer">
      {renderBusinessInfoSection()}
      {renderPrincipalInfoSection()}
      {renderDocumentInfoSection()}
    </Box>
  );
};

export default MerchantGeneralTab;
