/**
 * Validation schema for vgs host form
 * Validation is done using YUP
 */
import * as Yup from 'yup';
import {
  requiredFieldValidationRule,
  requiredTextFieldValidationRule,
} from '../../../../Forms/validationRules';

export const validationSchema = () =>
  Yup.object().shape({
    UserName: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Username');
      return validationSchema;
    }),
    Password: Yup.lazy((val: string) => {
      let validationSchema = requiredFieldValidationRule('Password');
      return validationSchema;
    }),
    Url: Yup.lazy((val: string) => {
      let validationSchema = requiredFieldValidationRule('URL');
      return validationSchema;
    }),
    VaultId: Yup.lazy((val: string) => {
      let validationSchema = requiredTextFieldValidationRule('Vault ID');
      return validationSchema;
    }),
  });
