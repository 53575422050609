/**
 * Reporting transactions
 */
import {
  USERS_FETCH_PENDING,
  USERS_FETCH_REJECTED,
  USERS_FETCH_FULFILLED,
  REPORTING_TRANSACTIONS_RESET,
} from '../../actions/types';

const initialState = {
  isPending: false,
  isFulfilled: false,
  isRejected: false,
  error: false,
  message: {
    count: 0,
    Results: [],  // very not cute that the API varies the casing of Results vs results.  grrrrr
    results: []
  },
};


const fetchAllUsersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case USERS_FETCH_PENDING:
      return {
        ...state,
        isPending: true,
      };
    case USERS_FETCH_FULFILLED:
      return {
        ...state,
        ...action.payload,
        isFulfilled: true,
        isPending: false,
      };
    case USERS_FETCH_REJECTED:
      return {
        ...state,
        ...action.payload,
        isRejected: true,
        isPending: false,
      };
    case REPORTING_TRANSACTIONS_RESET:
      return initialState;
    default:
      return state;
  }
};

export default fetchAllUsersReducer;
