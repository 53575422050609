import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import './SettingsRow.scss';

interface SettingsRowProps {
  title: any;
  children: ReactNode;
  border?: boolean;
}

const SettingsRow: React.FC<SettingsRowProps> = ({
  title,
  children = '',
  border = true,
}): JSX.Element => {
  return (
    <Box
      sx={{ borderBottom: border ? '1px solid #7C919D' : '' }}
      className="SettingRowMainStyle"
    >
      <Typography className="ChildBoxTitle">{title}</Typography>
      <Box
        className={
          title === 'Total' ? 'ChildBoxWithNoPadding' : 'ChildBoxStyle'
        }
      >
        {typeof children === 'string' ? (
          <Typography fontSize="medium">{children}</Typography>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

export default SettingsRow;
