/**
 * Validation schema for User form
 * Validation is done using YUP
 */
import * as Yup from 'yup';
import {
  merchantLegalNameValidationRule,
  urlValidationWithoutProtocolRule,
} from '../../components/Forms/validationRules';

export const validationSchema = () =>
  Yup.object().shape({
    MerchantLegalName: Yup.lazy(() => {
      let validationSchema = merchantLegalNameValidationRule(
        'Merchant Legal Name'
      );
      return validationSchema;
    }),
    Company_Website: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
      )
      .max(256, `Company Website can not be greater than 256 characters`)
      .nullable(),
  });
