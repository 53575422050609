/**
 * Auth Action
 * Used for adding action for the user authorisation flow
 */
import api from '../../libs/api';
import {
  AUTH_LOGIN_ACTION,
  AUTH_SEND_VERIFICATION_OTP,
  AUTH_SUCCESS,
  AUTH_SET_USER_CLIENT_ACTION,
  AUTH_LOGOUT_ACTION,
  AUTH_VERIFY_OTP_CODE,
  AUTH_UPDATE_PASSWORD,
  ADDITIONAL_VERIFICATION_REQUIRED,
  ADDITIONAL_VERIFICATION_REQUIRED_CHANGE_PASSWORD,
  USER_ACCOUNT_LOCKED,
  CLIENT_OBJECT_STORAGE,
} from './types';
import Cookies from 'universal-cookie';
import { clearStatusMessagesAction } from './appActions';
import { IS_LOCKED } from '../constants';
import { Client, Crypto } from '../../types/api';
import { swapDefaultKeysAndCrypto } from '../../libs/EncryptionHelper';
const cookies = new Cookies();
const NumberRegex = /^-?[\d.]+(?:e-?\d+)?$/;

export const thunkLoginAction =
  (login: string, password: string, ipAddress?: string) =>
    async (dispatch: any) => {
      if (NumberRegex.test(login)) {
        login = '+1' + login;
        localStorage.setItem('IsPhoneNumberLogin', 'true');
      } else {
        localStorage.setItem('IsPhoneNumberLogin', 'false');
      }
      localStorage.setItem('IsFinancialTransaction', 'false');
      let authResponse = await api.auth.login(login, password, ipAddress);
      const { error = false, data, message } = authResponse;
      localStorage.setItem('UserId', message?.Userid);
      if (!error) {

        const is2Factor = (message && message.User && message.User.Is2faEnabled !== undefined) ? message.User.Is2faEnabled : false;
        const client: Client | null = (is2Factor !== true) ? message.Clients[0] : null;
        const crypto: Crypto = (is2Factor !== true) ? message.Clients[0].Crypto : message.Crypto;

        // On login we go with the default ruoot clientuserrole relationship for this user
        if (is2Factor !== true) {
          localStorage.setItem(CLIENT_OBJECT_STORAGE, JSON.stringify(client));
          swapDefaultKeysAndCrypto(message, crypto);
        }



        dispatch(
          loginAction({
            error: error,
            userData: message,
            is2faEnabled: is2Factor,
            crypto: crypto,
            reason: is2Factor ? 'ADDITIONAL_AUTH_REQUIRED' : '',
            email: message?.User?.Email,
            phoneNumber: message?.User?.PhoneNumber,
            userId: message?.User.Id,
            clientId: (client) ? client.Id : null
          })
        );

        if (is2Factor === false) {
          dispatch(authSuccessAction());
        }
      }
      return authResponse;
    };

export const loginAction = (payload: any) => {
  let returnPayload = {
    type: AUTH_LOGIN_ACTION,
    payload,
  };

  localStorage.setItem('userPayload', JSON.stringify(payload));
  return returnPayload;
};

export const authSuccessAction = () => {
  const isLoggedIn = true;
  localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
  return {
    type: AUTH_SUCCESS,
    payload: { isLoggedIn },
  };
};

export const thunkSendVerificationOTP =
  (userid: string) => async (dispatch: any) => {
    dispatch({
      type: AUTH_SEND_VERIFICATION_OTP,
      payload: {
        verificationOTP: false,
      },
    });
    const response = await api.auth.sendVerificationOTP(userid);
    const { error = false, message } = response;
    if (!error) {
      dispatch({
        type: AUTH_SEND_VERIFICATION_OTP,
        payload: {
          verificationOTP: true,
          reason: message?.Status ? ADDITIONAL_VERIFICATION_REQUIRED : '',
        },
      });
    }
  };

export const thunkVerifyOTPCodeAction =
  ({
    ismobileinterface,
    userid,
    otp,
  }: {
    ismobileinterface?: boolean;
    userid: string;
    otp?: string;
  }) =>
    async (dispatch: any) => {
      const { error = false, message } = await api.auth.verifyOTPCode({
        ismobileinterface,
        userid,
        otp,
      });
      if (!error) {
        dispatch(clearStatusMessagesAction());

        const client: Client = (message && message.Clients && message.Clients.length > 0) ? message.Clients[0] : {};
        const crypto: Crypto = client.Crypto;
        const payload = {
          error: error,
          userData: message,
          is2faEnabled: message?.User?.Is2faEnabled,
          crypto: crypto,
          reason: '',
          email: message?.User?.Email,
          phoneNumber: message?.User?.PhoneNumber,
          userId: message?.User.Id,
          clientId: client.Id,
        };

        // On login we go with the default ruoot clientuserrole relationship for this user
        localStorage.setItem(CLIENT_OBJECT_STORAGE, JSON.stringify(client));
        localStorage.setItem('userPayload', JSON.stringify(payload));

        swapDefaultKeysAndCrypto(message, crypto);

        dispatch({
          type: AUTH_VERIFY_OTP_CODE,
          payload
        });

      }

      if (!error) {
        dispatch(authSuccessAction());
      } else if (error && message?.trim()?.toLowerCase()?.includes(IS_LOCKED)) {
        dispatch(clearStatusMessagesAction());
        dispatch({
          type: AUTH_VERIFY_OTP_CODE,
          payload: {
            reason: USER_ACCOUNT_LOCKED,
          },
        });
      }
    };

export const thunkChangePassword =
  (userid: string, otp: string, newPassword: string) =>
    async (dispatch: any) => {
      const response = await api.auth.changepassword(userid, otp, newPassword);

      const { error = false, message } = response;
      dispatch({
        type: AUTH_UPDATE_PASSWORD,
        payload: {
          userId: userid,
          verificationSendSuccess: true,
          codeVerificationSuccess: true,
          passwordUpdateSuccess: error === false,
          intermediaryToken: '',
          updatePasswordError: error ? message?.Status : '',
          reason: AUTH_UPDATE_PASSWORD,
        },
      });
    };

export const thunkSendVerificationOTPChangePassword =
  (userid: string) => async (dispatch: any) => {
    const response = await api.auth.sendVerificationForgotPasswordOTP(userid);

    dispatch({
      type: AUTH_UPDATE_PASSWORD,
      payload: {
        userId: userid,
        verificationSendSuccess: false,
        codeVerificationSuccess: false,
        passwordUpdateSuccess: false,
        reason: AUTH_UPDATE_PASSWORD,
        intermediaryToken: '',
        updatePasswordError: '',
      },
    });

    const { error = false, message } = response;
    if (!error) {
      dispatch({
        type: AUTH_UPDATE_PASSWORD,
        payload: {
          userId: userid,
          verificationSendSuccess: true,
          codeVerificationSuccess: false,
          passwordUpdateSuccess: false,
          intermediaryToken: '',
          updatePasswordError: message?.Status ? message?.Status : '',
          reason: AUTH_UPDATE_PASSWORD,
        },
      });
    }
  };

export const thunkVerifyOTPCodeActionChangePassword =
  ({
    ismobileinterface,
    userid,
    otp,
  }: {
    ismobileinterface: boolean;
    userid: string;
    otp: string;
  }) =>
    async (dispatch: any) => {
      const { error = false, message } = await api.auth.verifyOTPCode({
        ismobileinterface,
        userid,
        otp,
      });
      if (!error) {
        dispatch(clearStatusMessagesAction());
        dispatch({
          type: AUTH_UPDATE_PASSWORD,
          payload: {
            userId: userid,
            verificationSendSuccess: true,
            codeVerificationSuccess: true,
            passwordUpdateSuccess: false,
            intermediaryToken: otp,
            updatePasswordError: '',
            reason: AUTH_UPDATE_PASSWORD,
          },
        });
      }
      if (error && message?.trim()?.toLowerCase()?.includes(IS_LOCKED)) {
        dispatch(clearStatusMessagesAction());
        dispatch({
          type: AUTH_UPDATE_PASSWORD,
          payload: {
            userId: userid,
            verificationSendSuccess: true,
            codeVerificationSuccess: false,
            passwordUpdateSuccess: false,
            intermediaryToken: '',
            upupdatePasswordError: message?.Status,
            reason: USER_ACCOUNT_LOCKED,
            updatePasswordError: message?.Status ? message?.Status : '',
          },
        });
      }
    };

export const logoutAction = () => {
  localStorage.removeItem('userPayload');
  localStorage.removeItem(CLIENT_OBJECT_STORAGE);
  return {
    type: AUTH_LOGOUT_ACTION,
  };
};

export const thunkLogoutAction = () => async (dispatch: any) => {
  dispatch(clearStatusMessagesAction());
  localStorage.clear();
  cookies.remove('loggedInUserCrypto');
  cookies.remove('DKKeyValue');
  cookies.remove('TKKeyValue');
  cookies.remove('IVKeyValue');
  cookies.remove('ApiKeyValue');
  dispatch(logoutAction());
};

export const authSetUserClientAction = (client: Client) => {
  let returnPayload = {
    type: AUTH_SET_USER_CLIENT_ACTION,
    payload: client,
  };

  localStorage.setItem(CLIENT_OBJECT_STORAGE, JSON.stringify(client));

  return returnPayload;
};

export const thunkAuthSetUserClientAction =
  (userData: any, client: Client) => async (dispatch: any) => {
    swapDefaultKeysAndCrypto(userData, client.Crypto);
    dispatch(authSetUserClientAction(client));
  };
