import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Switch } from '@mui/material';
import './ToggleSwitch.scss';
import React from 'react';

export interface ToggleSwitchProps {
  leftLabel?: string;
  isChecked?: boolean;
  rightLabel?: string;
  handleOnChange: (value: any) => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  leftLabel = 'Inactive',
  isChecked = false,
  rightLabel = 'Active',
  handleOnChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleOnChange && handleOnChange(event.target.checked);
  };

  return (
    <Stack direction="row">
      <Typography className="ToggleLabelStyle">{leftLabel}</Typography>
      <Switch defaultChecked={isChecked} onChange={handleChange} />
      <Typography className="ToggleLabelStyle">{rightLabel}</Typography>
    </Stack>
  );
};

export default ToggleSwitch;
