/*
 Transaction table on trasnsaction tab under The reporting page
*/
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { dateFormatter } from '../../libs/helpers';
import '../../pages/Users/Users.scss';
import './UsersListTable.scss';
import { DATETIME_FORMAT } from '../../store/constants';
import { Hidden } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';

interface TransactionTabelProps {
  onViewDetailsClick?: (row: any) => void;
  users?: Array<any>;
}
const UserTable: React.FC<TransactionTabelProps> = ({
  onViewDetailsClick,
  users = [],
}) => {
  const renderDefaultMessage = () => (
    <TableRow>
      <TableCell colSpan={10} align="center">
        No Data To Show
      </TableCell>
    </TableRow>
  );

  const renderTableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell width="15%" className="UserTableHeader">
          Name
        </TableCell>
        <TableCell width="20%" className="UserTableHeader">
          Primary Email
        </TableCell>
        <TableCell width="10%" className="UserTableHeader">
          Status
        </TableCell>
        <TableCell width="20%" className="UserTableHeader">
          Merchant DBA
        </TableCell>
        <TableCell width="15%" className="UserTableHeader">
          Last Access
        </TableCell>
        <TableCell width="15%" className="UserTableHeader">
          Date Added
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const renderTableBody = () => (
    <TableBody>
      {users?.length > 0 ? renderUserRows() : renderDefaultMessage()}
    </TableBody>
  );
  const renderUserRows = () =>
    users?.map((row) => {
      return (
        <TableRow
          key={Math.random()}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          className="UserTableRowStyle"
          onClick={() => onViewDetailsClick && onViewDetailsClick(row)}
        >
          <TableCell>{row?.FirstName + ' ' + row?.LastName}</TableCell>
          <TableCell>{row?.Email ? row?.Email : '-'}</TableCell>
          <TableCell>{row?.IsActive ? 'Active' : 'Inactive'}</TableCell>
          <TableCell>{row?.ClientName ? row?.ClientName : '-'}</TableCell>
          <TableCell>
            {row?.LastAccess
              ? dateFormatter(row?.LastAccess, DATETIME_FORMAT)
              : '-'}
          </TableCell>
          <TableCell>
            {row?.DateAdded
              ? dateFormatter(row?.DateAdded, DATETIME_FORMAT)
              : '-'}
          </TableCell>
        </TableRow>
      );
    });

  return (
    <>
      <InfiniteScroll
        dataLength={(users) ? users.length : 0}
        next={()=>{}}
        //To put endMessage and loader to the top.
        hasMore={false}
        loader={<></>}
        scrollableTarget="clientTableID"
      >
        <TableContainer
          id={'clientTableID'}
          className="MerchantTable"
          component={Paper}
          style={{
            height: "calc(100vh - 330px)",
            overflow: "auto"
          }}
        >
          <Table stickyHeader aria-label="simple table">
            {renderTableHeader()}
            {renderTableBody()}
          </Table>
        </TableContainer>
      </InfiniteScroll>

    </>);
};

export default UserTable;
