/***
 * Payment information section on virtual terminal
 * get user payment instrument
 */
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CreditCardControl from '../../Common/CreditCardControl';
import '../../../pages/VirtualTerminal/VirtualTerminal.scss';
import {
  TERMINAL_TRANSACTIONS,
  CHECK_TRANSACTION_TYPES,
  CHILD_TRANSACTIONS_TYPES,
} from '../../../store/constants';
import {
  PaymentInfoFormValues,
  BankAccountProps,
} from '../../../types/PaymentInfoForm.types';
import './PaymentInformationForm.scss';

interface PaymentInformationFormProps {
  values?: any;
  onChange: (values: PaymentInfoFormValues) => void;
  disabled?: boolean;
  setIsPaymentInfoFormValid?: (value: boolean) => void;
  transactionTypeLabel: string;
}

const PaymentInformationForm: React.FC<PaymentInformationFormProps> = ({
  values,
  onChange,
  disabled = false,
  setIsPaymentInfoFormValid,
  transactionTypeLabel,
}) => {
  const [paymentInfoState, setPaymentInfoState] =
    useState<PaymentInfoFormValues>(values);
  const [bankAccountState, setBankAccountState] = useState<BankAccountProps>(
    values?.bankAccount || {
      accountNumber: '',
      routingNumber: '',
    }
  );

  useEffect(() => {
    setPaymentInfoState(values);
  }, [values]);

  useEffect(() => {
    if (!paymentInfoState.paymentMethod) {
      setPaymentInfoState((prevState) => ({
        ...prevState,
        paymentMethod: 'card',
      }));
    }
    if (!paymentInfoState.transactionType) {
      setPaymentInfoState((prevState) => ({
        ...prevState,
        transactionType:
          paymentInfoState?.paymentMethod.toLowerCase() === 'check'
            ? CHECK_TRANSACTION_TYPES[0].value
            : TERMINAL_TRANSACTIONS[0].value,
      }));
    }
  });

  const handleFormOnChange = () => {
    onChange && onChange(paymentInfoState);
  };

  const getTransactionTypeOptions = () => {
    const tranTypeOptions =
      paymentInfoState?.paymentMethod &&
      paymentInfoState?.paymentMethod.toLowerCase() === 'check'
        ? CHECK_TRANSACTION_TYPES
        : [
            { key: 'Sale', value: 'Sale' },
            { key: 'Auth', value: 'Authorize' },
            { key: 'Refund', value: 'Refund' },
            { key: 'Capture', value: 'Capture' },
          ];

    if (transactionTypeLabel && transactionTypeLabel === 'Reversal') {
      tranTypeOptions.push({ key: 'Reversal', value: 'Reversal' });
    } else {
      tranTypeOptions.push({ key: 'Void', value: 'Void' });
    }
    return tranTypeOptions;
  };

  return (
    <Box className="PaymentInfoBoxStyle" display="inline-flex">
      <FormControl className="PaymentMethodStyle">
        <InputLabel id="PaymentMethodLabel">Payment Method</InputLabel>
        <Select
          id="PaymentMethod"
          variant="outlined"
          label="Payment Method"
          labelId="PaymentMethodLabel"
          value={paymentInfoState?.paymentMethod}
          disabled={disabled}
          className="PaymentMethodInputStyle"
          onChange={(e: any) => {
            const {
              target: { value },
            } = e;
            setPaymentInfoState((prevState) => ({
              ...prevState,
              paymentMethod: value,
            }));
            setPaymentInfoState((prevState) => ({
              ...prevState,
              transactionType:
                value === 'check'
                  ? CHECK_TRANSACTION_TYPES[0]?.value
                  : TERMINAL_TRANSACTIONS[0]?.value,
            }));
            handleFormOnChange();
          }}
          onBlur={(e: any) => {
            const {
              target: { value },
            } = e;
            setPaymentInfoState((prevState) => ({
              ...prevState,
              paymentMethod: value,
            }));
            handleFormOnChange();
          }}
        >
          <MenuItem value="card" key="card">
            Card
          </MenuItem>
          {/* <MenuItem value="check" key="card">
            Check
          </MenuItem> */}
        </Select>
      </FormControl>
      <FormControl className="TransactionTypeStyle">
        <InputLabel id="TransactionTypeLabel">Transaction Type</InputLabel>
        <Select
          id="TransactionType"
          labelId="TransactionTypeLabel"
          variant="outlined"
          label="Transaction Type"
          value={
            transactionTypeLabel && transactionTypeLabel === 'Reversal'
              ? transactionTypeLabel
              : paymentInfoState?.transactionType
          }
          className="TransactionTypeInputStyle"
          disabled={disabled}
          onChange={(e: any) => {
            const {
              target: { value },
            } = e;
            setPaymentInfoState((prevState) => ({
              ...prevState,
              transactionType: value,
            }));
            handleFormOnChange();
          }}
          onBlur={(e: any) => {
            const {
              target: { value },
            } = e;
            setPaymentInfoState((prevState) => ({
              ...prevState,
              transactionType: value,
            }));
            handleFormOnChange();
          }}
        >
          {getTransactionTypeOptions()?.map((transaction) => {
            return (
              <MenuItem key={transaction.value} value={transaction.value}>
                {transaction.key}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {paymentInfoState?.paymentMethod &&
        paymentInfoState?.paymentMethod.toLowerCase() === 'check' && (
          <Box className="BankAccountDetailsBoxStyle" display="inline-flex">
            <Box className="AccountNumberStyle">
              <TextField
                label="Account Number"
                placeholder="Account Number"
                variant="outlined"
                id="AccountNumber"
                name="AccountNumber"
                className="AccountNumberInputStyle"
                fullWidth
                disabled={disabled}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  setBankAccountState((prevState) => ({
                    ...prevState,
                    accountNumber: value,
                  }));
                }}
                onBlur={(e: any) => {
                  setPaymentInfoState((prevState) => ({
                    ...prevState,
                    bankAccount: bankAccountState,
                  }));
                  handleFormOnChange();
                }}
              ></TextField>
            </Box>
            <Box className="RoutingNumberStyle">
              <TextField
                label="Routing Number"
                placeholder="Routing Number"
                variant="outlined"
                id="RoutingNumber"
                name="RoutingNumber"
                className="RoutingNumberInputStyle"
                fullWidth
                disabled={disabled}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  setBankAccountState((prevState) => ({
                    ...prevState,
                    routingNumber: value,
                  }));
                }}
                onBlur={(e: any) => {
                  setPaymentInfoState((prevState) => ({
                    ...prevState,
                    bankAccount: bankAccountState,
                  }));
                  handleFormOnChange();
                }}
              ></TextField>
            </Box>
          </Box>
        )}

      {paymentInfoState?.paymentMethod &&
        paymentInfoState?.paymentMethod?.toLocaleLowerCase() === 'card' &&
        (CHILD_TRANSACTIONS_TYPES.includes(
          paymentInfoState?.transactionType?.toLowerCase()
        ) ? (
          <Box className="TransactionIdStyle">
            <TextField
              label="Transaction Id"
              placeholder="Transaction Id"
              variant="outlined"
              id="TransactionId"
              name="TransactionId"
              fullWidth
              className="TransactionIdInputStyle"
              disabled={disabled}
              value={paymentInfoState?.transactionId}
              onChange={(e: any) => {
                const {
                  target: { value },
                } = e;
                setPaymentInfoState((prevState) => ({
                  ...prevState,
                  transactionId: value,
                }));
                setIsPaymentInfoFormValid &&
                  setIsPaymentInfoFormValid(
                    value && value.length ? true : false
                  );
              }}
              onBlur={(e: any) => {
                const {
                  target: { value },
                } = e;
                setPaymentInfoState((prevState) => ({
                  ...prevState,
                  transactionId: value,
                }));
                setIsPaymentInfoFormValid &&
                  setIsPaymentInfoFormValid(
                    value && value.length ? true : false
                  );
                handleFormOnChange();
              }}
            ></TextField>
          </Box>
        ) : (
          <CreditCardControl
            values={paymentInfoState?.cardInfo}
            disabled={disabled}
            onBlur={(data: any) => {
              setPaymentInfoState((prevState) => ({
                ...prevState,
                cardInfo: data,
              }));
              handleFormOnChange();
            }}
            onChange={(data: any) => {
              setPaymentInfoState((prevState) => ({
                ...prevState,
                cardInfo: data,
              }));
              handleFormOnChange();
            }}
            setIsPaymentInfoFormValid={setIsPaymentInfoFormValid}
          ></CreditCardControl>
        ))}
    </Box>
  );
};

export default PaymentInformationForm;
