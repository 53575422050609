/**
 * User Details SideRail
 */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import './AddUserForm.scss';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { STATIC_TIMEZONES } from '../../store/constants';
import { validationSchema } from './validationSchema';
import { useFormik } from 'formik';
import { useStore } from 'lnox';
import { isPossibleNumber } from 'libphonenumber-js';


interface AddUserProps {
  isEdit?: boolean;
  values?: any;
  onChange?: ((value: any) => void) | undefined;
  setIsUserFormValid?: ((value: any) => void) | undefined;
}

interface AddUserFormValues {
  FirstName: string;
  LastName: string;
  PhoneNumber: string;
  Email: string;
  Is2faEnabled: boolean;
  Password: string;
  Timezone: string;
}

const AddUser: React.FC<AddUserProps> = ({
  isEdit = false,
  values,
  onChange,
  setIsUserFormValid,
}) => {
  const {
    state: { user },
  } = useStore();

  const {
    user: {
      create: {
        error: createUserErrorStatus,
        isPending: createUserIsPending,
        isFulfilled: createUserIsFulfilled,
      },
      update: {
        error: updateUserErrorStatus,
        isPending: updateUserIsPending,
        isFulfilled: updateUserIsFulfilled,
      },
    },
  } = user;
  const initialValues: AddUserFormValues = values || {
    FirstName: '',
    LastName: '',
    PhoneNumber: '',
    Email: '',
    Is2faEnabled: false,
    Password: '',
    Timezone: '',
  };
  const [userInfoState, setUserInfoState] = useState<any>(values || {});

  const [passwordValues, setPasswordValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const [confirmPasswordValues, setConfirmPasswordValues] = React.useState({
    confirmPassword: '',
    showConfirmPassword: false,
  });
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>('');
  const [confirmPhoneNumberValue, setConfirmPhoneNumberValue] = useState<string>('');
  const [addressCountry, setAddressCountry] = useState<string>("");


  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleMouseDownConfirmPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setConfirmPasswordValues({
      ...confirmPasswordValues,
      showConfirmPassword: !confirmPasswordValues.showConfirmPassword,
    });
  };
  const requiredFields: any = !isEdit
    ? [
      'FirstName',
      'LastName',
      'PhoneNumber',
      'ConfirmPhone',
      'Email',
      'ConfirmEmail',
      'Is2faEnabled',
      'Password',
      'ConfirmPassword',
      'Timezone',
    ]
    : ['FirstName', 'LastName', 'Is2faEnabled', 'Timezone'];
  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema(requiredFields),
    onSubmit: () => { },
  });

  const submitFormValues = () => {
    onChange && onChange(userInfoState);
    setIsUserFormValid &&
      setIsUserFormValid(
        (formik.errors && Object.keys(formik.errors).length) || phoneNumberError
          ? true
          : false
      );
  };

  const formOnChageHandler = (e: any) => {
    e.preventDefault();
  };
  const fromOnSubmitHandler = (e: any) => {
    e.preventDefault();
  };

  const validatePhoneNumber = (value: any, country: any) => {
    if (isPossibleNumber(value, country)) {
      setPhoneNumberError(false)
    } else {
      setPhoneNumberError(true);
    }
  };

  const handlePhoneChange = (value: any, data: any,) => {
    if (value.charAt(0) != 1 && addressCountry == '' && value.length > 3) { value = "+1" + value }
    setPhoneNumberValue(value)
    setAddressCountry(data.countryCode.toUpperCase())
    formik.setFieldValue('PhoneNumber', value);
    formik.setFieldTouched('PhoneNumber');
    setUserInfoState((prevState: any) => ({
      ...prevState,
      PhoneNumber: value,
    }));
  };

  const handleConfirmPhoneChange = (value: any, data: any,) => {
    if (value.charAt(0) != 1 && addressCountry == '' && value.length > 3) { value = "+1" + value }
    setConfirmPhoneNumberValue(value)
    setAddressCountry(data.countryCode.toUpperCase())
    formik.setFieldValue('ConfirmPhone', value);
    formik.setFieldTouched('ConfirmPhone');
    setUserInfoState((prevState: any) => ({
      ...prevState,
      PhoneNumber: value,
    }));
  };


  const renderUserForm = () => (
    <Box>
      <form onChange={formOnChageHandler} onSubmit={fromOnSubmitHandler}>
        <Box className="SectionBox">
          <Typography
            fontWeight="bold"
            fontSize="large"
            className="HeadingText"
          >
            Account Information
          </Typography>
          <Box className="UserRow">
            <FormControl fullWidth>
              <TextField
                id={'FirstName'}
                name={'FirstName'}
                label="First Name"
                className="FirstNameStyle"
                disabled={false}
                value={userInfoState?.FirstName}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('FirstName', value);
                  setUserInfoState((prevState: any) => ({
                    ...prevState,
                    FirstName: value,
                  }));
                }}
                onBlur={(e: any) => {
                  formik.setFieldTouched('FirstName');
                  submitFormValues();
                }}
                error={
                  formik.touched.FirstName && formik.errors.FirstName
                    ? true
                    : false
                }
              />
              {formik.touched.FirstName && formik.errors.FirstName && (
                <FormHelperText className="FormHelperTextStyle">
                  {formik.touched.FirstName && formik.errors.FirstName
                    ? formik.errors.FirstName
                    : ''}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl id={'LastName'} fullWidth>
              <TextField
                id={'LastName'}
                name={'LastName'}
                label="Last Name"
                className="LastNameStyle"
                disabled={false}
                value={userInfoState?.LastName}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('LastName', value);
                  setUserInfoState((prevState: any) => ({
                    ...prevState,
                    LastName: value,
                  }));
                }}
                onBlur={(e: any) => {
                  formik.setFieldTouched('LastName');
                  submitFormValues();
                }}
                error={
                  formik.touched.LastName && formik.errors.LastName
                    ? true
                    : false
                }
              />
              {formik.touched.LastName && formik.errors.LastName && (
                <FormHelperText className="FormHelperTextStyleLastName">
                  {formik.touched.LastName && formik.errors.LastName
                    ? formik.errors.LastName
                    : ''}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          {!isEdit && (
            <Box className="UserRow">
              <FormControl
                id={'password'}
                fullWidth
                className="FieldLabelStyle"
              >
                <TextField
                  id={'password'}
                  name={'password'}
                  label="Password"
                  className="FirstNameStyle"
                  disabled={false}
                  value={userInfoState?.Password}
                  type={passwordValues?.showPassword ? 'text' : 'password'}
                  onChange={(e: any) => {
                    const {
                      target: { value },
                    } = e;
                    formik.setFieldValue('Password', value);
                    setUserInfoState((prevState: any) => ({
                      ...prevState,
                      Password: value,
                    }));
                  }}
                  onBlur={(e: any) => {
                    formik.setFieldTouched('Password');
                    submitFormValues();
                  }}
                  InputProps={{
                    autoComplete: 'new-password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {passwordValues?.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={
                    formik.touched.Password && formik.errors.Password
                      ? true
                      : false
                  }
                />
                {formik.touched.Password && formik.errors.Password && (
                  <FormHelperText className="FormHelperTextStyle">
                    {formik.touched.Password && formik.errors.Password
                      ? formik.errors.Password
                      : ''}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth className="FieldLabelStyle">
                <TextField
                  id="ConfirmPassword"
                  name="ConfirmPassword"
                  label="Confirm Password"
                  className="LastNameStyle"
                  disabled={false}
                  value={formik.values.ConfirmPassword}
                  type={
                    confirmPasswordValues?.showConfirmPassword
                      ? 'text'
                      : 'password'
                  }
                  onChange={(e: any) => {
                    const {
                      target: { value },
                    } = e;
                    formik.setFieldValue('ConfirmPassword', value);
                  }}
                  onBlur={(e: any) => {
                    formik.setFieldTouched('ConfirmPassword');
                    submitFormValues();
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                        >
                          {confirmPasswordValues.showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={
                    formik.touched.ConfirmPassword &&
                      formik.errors.ConfirmPassword
                      ? true
                      : false
                  }
                />
                {formik.touched.ConfirmPassword &&
                  formik.errors.ConfirmPassword && (
                    <FormHelperText className="FormHelperTextStyleLastName">
                      {formik.touched.ConfirmPassword &&
                        formik.errors.ConfirmPassword
                        ? formik.errors.ConfirmPassword
                        : ''}
                    </FormHelperText>
                  )}
              </FormControl>
            </Box>
          )}
          <Box className="UserRow">
            <FormControl fullWidth>
              <FormLabel className="SwitchFieldLabelStyle">
                Multi-Factor Authentication
              </FormLabel>
              <Box className="MultiFactor">
                <Stack direction="row" alignItems={'center'}>
                  <Typography>Off</Typography>
                  <Switch
                    defaultChecked={userInfoState.Is2faEnabled}
                    onChange={(e: any) => {
                      const {
                        target: { checked },
                      } = e;
                      formik.setFieldValue('Is2faEnabled', checked);
                      setUserInfoState((prevState: any) => ({
                        ...prevState,
                        Is2faEnabled: checked,
                      }));
                    }}
                  />
                  <Typography>On</Typography>
                </Stack>
              </Box>
              {formik.touched.Is2faEnabled && formik.errors.Is2faEnabled && (
                <FormHelperText className="FormHelperTextStyle">
                  {formik.touched.Is2faEnabled && formik.errors.Is2faEnabled
                    ? formik.errors.Is2faEnabled
                    : ''}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth className="TimeZone">
              <InputLabel id={'timeZone'}>Time Zone</InputLabel>
              <Select
                label={'Time Zone'}
                labelId={'Timezone'}
                id={'Timezone'}
                name={'Timezone'}
                value={userInfoState.Timezone}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('Timezone', value);
                  setUserInfoState((prevState: any) => ({
                    ...prevState,
                    Timezone: value,
                  }));
                }}
                onBlur={(e: any) => {
                  formik.setFieldTouched('Timezone');
                  submitFormValues();
                }}
                error={
                  formik.touched.Timezone && formik.errors.Timezone
                    ? true
                    : false
                }
              >
                {STATIC_TIMEZONES.map((item: any) => {
                  return (
                    <MenuItem key={item.name} value={item.name}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
              {formik.touched.Timezone && formik.errors.Timezone && (
                <FormHelperText className="FormHelperTextStyle">
                  {formik.touched.Timezone && formik.errors.Timezone
                    ? formik.errors.Timezone
                    : ''}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box className="MarginTop" display="none">
            <FormControl className="SwitchFieldFirstStyle">
              <FormLabel component="legend" className="SwitchFieldLabelStyle">
                User Status
              </FormLabel>
              <Stack
                direction="row"
                alignItems={'center'}
                className="SwitchControlField"
              >
                <Typography>Active</Typography>
                <Switch defaultChecked />
                <Typography>Inactive</Typography>
              </Stack>
            </FormControl>
            <FormControl className="SwitchFieldSecondStyle">
              <FormLabel component="legend" className="SwitchFieldLabelStyle">
                User Lock Status
              </FormLabel>
              <Stack
                direction="row"
                alignItems={'center'}
                className="SwitchControlField"
              >
                <Typography>Unlocked</Typography>
                <Switch defaultChecked />
                <Typography>Locked</Typography>
              </Stack>
            </FormControl>
          </Box>
        </Box>
        <Divider variant="fullWidth" />
        <Box className="SectionBox">
          <Typography
            fontWeight="bold"
            fontSize="large"
            className="HeadingText"
          >
            Contact Information
          </Typography>
          <Typography className="SubHeadingText">Emails</Typography>
          {isEdit ? (
            <Box>
              <Typography className="ValueLabel">{values.Email}</Typography>
              <Typography className="ValueStatusStyle">Primary</Typography>
            </Box>
          ) : (
            <Box>
              <Box className="UserRow">
                <FormControl fullWidth className="FieldLabelStyle">
                  <TextField
                    className="FirstNameStyle"
                    fullWidth
                    id={'Email'}
                    name={'Email'}
                    label="Email"
                    disabled={false}
                    value={userInfoState.Email}
                    onChange={(e: any) => {
                      const {
                        target: { value },
                      } = e;
                      formik.setFieldValue('Email', value);
                      setUserInfoState((prevState: any) => ({
                        ...prevState,
                        Email: value,
                      }));
                    }}
                    onBlur={(e: any) => {
                      formik.setFieldTouched('Email');
                      submitFormValues();
                    }}
                    error={
                      formik.touched.Email && formik.errors.Email ? true : false
                    }
                  />
                  {formik.touched.Email && formik.errors.Email && (
                    <FormHelperText className="FormHelperTextStyle">
                      {formik.touched.Email && formik.errors.Email
                        ? formik.errors.Email
                        : ''}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth className="FieldLabelStyle">
                  <TextField
                    className="LastNameStyle"
                    fullWidth
                    id={'ConfirmEmail'}
                    name={'ConfirmEmail'}
                    label="Confirm Email"
                    disabled={false}
                    value={formik.values.ConfirmEmail}
                    onChange={(e: any) => {
                      const {
                        target: { value },
                      } = e;
                      formik.setFieldValue('ConfirmEmail', value);
                    }}
                    onBlur={(e: any) => {
                      formik.setFieldTouched('ConfirmEmail');
                      submitFormValues();
                    }}
                    error={
                      formik.touched.ConfirmEmail && formik.errors.ConfirmEmail
                        ? true
                        : false
                    }
                  />
                  {formik.touched.ConfirmEmail &&
                    formik.errors.ConfirmEmail && (
                      <FormHelperText className="FormHelperTextStyleLastName">
                        {formik.touched.ConfirmEmail &&
                          formik.errors.ConfirmEmail
                          ? formik.errors.ConfirmEmail
                          : ''}
                      </FormHelperText>
                    )}
                </FormControl>
              </Box>
              <Box className="StyledButton" display="none">
                <Button variant="contained">ADD</Button>
              </Box>
            </Box>
          )}
          <Divider />
          <Typography className="SubHeadingText TopMargin">
            Phone Numbers
          </Typography>
          {isEdit ? (
            <Box>
              <Typography className="ValueLabel">
                {values?.PhoneNumber}
              </Typography>
              <Typography className="ValueStatusStyle">Primary</Typography>
            </Box>
          ) : (
            <Box>
              <Box className="UserRow">
                <FormControl fullWidth>
                  <PhoneInput
                    specialLabel="Phone Number"
                    inputProps={{
                      name: 'PhoneNumber',
                    }}
                    country={'us'}
                    placeholder={'Phone Number'}
                    value={phoneNumberValue}
                    isValid={!phoneNumberError}
                    countryCodeEditable={true}
                    onChange={handlePhoneChange}
                    onBlur={(phone) => {
                      validatePhoneNumber(phoneNumberValue, addressCountry);
                      submitFormValues();
                      formik.setFieldTouched('PhoneNumber');
                    }}
                    containerStyle={{
                      width: '101%',
                    }}
                    inputStyle={{
                      width: '96%',
                      height: '95%',
                      marginRight: '5%',
                      borderRadius: '10px',
                    }}
                  />
                  {phoneNumberError && (
                    <FormHelperText sx={{ color: '#d32f2f' }}>
                      {phoneNumberError ? 'Phone Number is not valid' : ''}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  <PhoneInput
                    specialLabel="Confirm Phone Number"
                    inputProps={{
                      name: 'ConfirmPhone',
                    }}
                    country={'us'}
                    countryCodeEditable={true}
                    placeholder={'Confirm Phone Number'}
                    value={confirmPhoneNumberValue}
                    onChange={handleConfirmPhoneChange}
                    onBlur={() => {
                      validatePhoneNumber(confirmPhoneNumberValue, addressCountry);
                      submitFormValues();
                      formik.setFieldTouched('ConfirmPhone');
                    }}
                    containerStyle={{
                      width: '102%',
                      marginLeft: '2.5%',
                    }}
                    inputStyle={{
                      width: '96%',
                      height: '95%',
                      marginRight: '2%',
                      borderRadius: '10px',
                    }}
                  />
                  {formik.touched.ConfirmPhone &&
                    formik.errors.ConfirmPhone && (
                      <FormHelperText className="FormHelperTextStyle">
                        {formik.touched.ConfirmPhone &&
                          formik.errors.ConfirmPhone
                          ? formik.errors.ConfirmPhone
                          : ''}
                      </FormHelperText>
                    )}
                </FormControl>
              </Box>
              <Box className="StyledAddPhoneButton" display="none">
                <Button variant="contained">ADD</Button>
              </Box>
            </Box>
          )}
        </Box>
        <Divider variant="fullWidth" />
        <Box className="SectionBox" display="none">
          <Box display="inline-flex" className="FullWidth">
            <Box className="RoleSection">
              <Typography
                fontWeight="bold"
                fontSize="large"
                className="HeadingText"
              >
                Roles and Accounts
              </Typography>
            </Box>
            <Box className="StyledAddRoleButton">
              <Button variant="outlined" className="ButtonText">
                <AddIcon />
                New Role
              </Button>
            </Box>
          </Box>
          <Box className="RoleMarginTop" display="inline-flex">
            <Box className="RoleFieldSection">
              <FormControl className="FieldLabelStyle">
                <InputLabel id={'role'} className="DropdownFieldLabel">
                  Role
                </InputLabel>
                <Select
                  label={'Role'}
                  labelId={'role'}
                  id={'role'}
                  name={'role'}
                  className="DropDownFieldStyle"
                ></Select>
              </FormControl>
              <Box>
                <FormControl className="SearchFieldLabelStyle">
                  <TextField
                    className="SearchBox"
                    placeholder="Search"
                    onKeyDown={(e) => { }}
                    onChange={(event) => { }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: { height: 40 },
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className="RoleAccountFieldSection">
              <InputLabel id={'accounts'}>Accounts</InputLabel>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="David Coffee Shop"
              />
            </Box>
          </Box>
          <Divider className="RoleDeviderStyle" />
        </Box>
      </form>
    </Box>
  );

  return (
    <Box>
      {(createUserIsPending || updateUserIsPending) &&
        (!createUserIsFulfilled || !updateUserIsFulfilled) ? (
        <CircularProgress
          sx={{ marginLeft: '45%', marginTop: '2%' }}
          size={24}
        ></CircularProgress>
      ) : (
        renderUserForm()
      )}
    </Box>
  );
};

export default AddUser;
