/***
 * Account locked class
 * Used to show the user is locked after un sucessfully attempts
 */
import { Box, Link, Typography } from '@mui/material';
import React, { MouseEvent } from 'react';

interface AccountLockedFormProps {
  onBackToLogin: (event: MouseEvent) => void;
}

const AccountLockedForm: React.FC<AccountLockedFormProps> = ({
  onBackToLogin,
}) => {
  /**
   * Render forgot password link.
   */
  const renderBackToLogin = () => (
    <Box mt={10}>
      <Link underline="hover" href="#" onClick={onBackToLogin}>
        Back to Login
      </Link>
    </Box>
  );

  /***
   * Render support link on account locked screen
   */
  const renderSupportLink = () => {
    return (
      <Link underline="hover" href="#" onClick={onBackToLogin}>
        support
      </Link>
    );
  };

  return (
    <Box className="AccountLockedBox">
      <Typography fontWeight="bolder" fontSize="large">
        Account Locked
      </Typography>
      <Typography display="block" color="#7C919D">
        Due to security reasons, your account has been locked, please contact{' '}
        {renderSupportLink()} for assistance.
      </Typography>
      {renderBackToLogin()}
    </Box>
  );
};

export default AccountLockedForm;
