const uiConfig = {
  projectName: process.env.REACT_APP_UI_CONFIG_PROJECT || 'pace',
  viking: {
    title: 'Viking',
    theme: 'main',
  },
  pace: {
    title: 'Pace',
    theme: 'main',
  },
};

export default uiConfig;
