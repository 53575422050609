/**
 * User Profile tab
 */
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useStore } from 'lnox';
import React, { useEffect, useState } from 'react';
import useDidMountEffect from '../../../Hooks/useDidMountEffect';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import './ProfileTab.scss';
import { useFormik } from 'formik';
import { STATIC_TIMEZONES } from '../../../store/constants';
import { validationSchema } from './validationSchema';
import {
  thunkUpdateUserAction,
  thunkUsersFetchByIdAction,
} from '../../../store/actions/userActions';

const ProfileTab: React.FC<{}> = () => {
  const {
    dispatch,
    state: { user },
  } = useStore();

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [userProfileState, setUserProfileState] = useState<any>({});

  const {
    user: {
      fetchById: {
        isPending: fetchByIdIsPending,
        isFulfilled: fetchByIdIsFulfilled,
        message: { UserDetails: userDetails = {} },
      },
      update: { isPending: updateUserIsPending },
    },
  } = user;

  const emails: any = [userProfileState?.Email];
  const phoneNumbers: any = [userProfileState?.PhoneNumber];

  let initialValues = userProfileState || {
    FirstName: '',
    LastName: '',
    Timezone: '',
  };

  useDidMountEffect(() => {
    if (localStorage.getItem('UserId')) {
      dispatch(
        thunkUsersFetchByIdAction({ id: localStorage.getItem('UserId') })
      );
    }
  });

  useEffect(() => {
    if (fetchByIdIsFulfilled) {
      setUserProfileState(userDetails);
      formik.setFieldValue('FirstName', userDetails.FirstName, false);
      formik.setFieldValue('LastName', userDetails.LastName, false);
      formik.setFieldValue('Timezone', userDetails.Timezone, false);
    }
  }, [fetchByIdIsFulfilled]);

  const formatPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber && phoneNumber?.trim() && phoneNumber?.length > 10) {
      phoneNumber = phoneNumber.replace('+', '');
      return `+${phoneNumber.substring(0, 1)} (${phoneNumber.substring(
        1,
        4
      )}) ${phoneNumber.substring(4, 7)} - ${phoneNumber.substring(7, 11)}`;
    } else {
      return '-';
    }
  };

  const renderProfileDataView = () => {
    return (
      <Box>
        <Box className="ViewProfileBoxStyle">
          <Typography className="HeadertextStyle">
            Account Information
          </Typography>
          <Box className="ViewBoxStyle">
            <Typography className="LabelTextStyle">Name</Typography>
            <Typography className="NameValueTextStyle">
              {userProfileState?.FirstName ? userProfileState?.FirstName : '-'}{' '}
              {userProfileState?.LastName ? userProfileState?.LastName : '-'}
            </Typography>
          </Box>
          <Box className="ViewBoxStyle">
            <Typography className="LabelTextStyle">Time Zone</Typography>
            <Typography className="ValueTextStyle">
              {userProfileState?.Timezone ? userProfileState?.Timezone : '-'}
            </Typography>
          </Box>
        </Box>
        <Box className="ViewProfileBoxStyle">
          <Typography className="HeadertextStyle">
            Contact Information
          </Typography>
          <Box className="ViewBoxStyle">
            <Typography className="LabelTextStyle">Emails</Typography>
            {emails?.map((email: any) => {
              return (
                <Typography
                  key={Math.random()}
                  className="DynamicValueTextStyle"
                >
                  {email}
                </Typography>
              );
            })}
          </Box>
          <Box className="ViewBoxStyle">
            <Typography className="LabelTextStyle">Phone Numbers</Typography>
            {phoneNumbers?.map((phoneNumber: any) => {
              return (
                <Typography
                  key={Math.random()}
                  className="DynamicValueTextStyle"
                >
                  {formatPhoneNumber(phoneNumber)}
                </Typography>
              );
            })}
          </Box>
        </Box>
        <Box className="ProfEditButtonAlign">
          <Button
            variant="contained"
            disabled={false}
            onClick={() => {
              setIsInEditMode(true);
            }}
          >
            <EditIcon />
            Edit
          </Button>
        </Box>
      </Box>
    );
  };

  const handleProfileSaveAction = async () => {
    let payload = {
      Id: localStorage.getItem('UserId'),
      FirstName: userProfileState?.FirstName || userDetails?.FirstName,
      LastName: userProfileState?.LastName || userDetails?.LastName,
      Timezone: userProfileState?.Timezone || userDetails?.Timezone,
    };
    await dispatch(thunkUpdateUserAction(payload));
    setIsInEditMode(false);
  };

  const formOnChageHandler = (e: any) => {
    e.preventDefault();
  };

  const formik: any = useFormik({
    initialValues: userProfileState,
    validationSchema: validationSchema,
    onSubmit: (fromValues: any) => {},
    validateOnBlur: true,
  });

  const renderProfileDataEdit = () => {
    return (
      <Box>
        <Box>
          <Typography className="HeadertextStyle">
            Account Information
          </Typography>
          <Box>
            <form onChange={formOnChageHandler} onSubmit={formik.handleSubmit}>
              <div>
                <Box className="AccountInfoStyle">
                  <Box className="MarginTop">
                    <FormControl className="ControlStyle">
                      <TextField
                        id={'FirstName'}
                        name={'FirstName'}
                        label="First Name"
                        value={userProfileState.FirstName}
                        onChange={(e: any) => {
                          const {
                            target: { value },
                          } = e;
                          formik.setFieldValue('FirstName', value, false);
                          setUserProfileState((prevState: any) => ({
                            ...prevState,
                            FirstName: value,
                          }));
                        }}
                        onBlur={(e: any) => {
                          const {
                            target: { value },
                          } = e;
                          formik.setFieldValue('FirstName', value);
                          formik.setFieldTouched('FirstName');
                        }}
                        error={
                          formik.touched.FirstName && formik.errors['FirstName']
                            ? true
                            : false
                        }
                        helperText={
                          formik.touched.FirstName && formik.errors['FirstName']
                            ? formik.errors['FirstName']
                            : ''
                        }
                      />
                    </FormControl>
                    <FormControl className="LastNameControlStyle">
                      <TextField
                        id={'LastName'}
                        name={'LastName'}
                        label="Last Name"
                        value={userProfileState.LastName}
                        onChange={(e: any) => {
                          const {
                            target: { value },
                          } = e;
                          formik.setFieldValue('LastName', value);
                          setUserProfileState((prevState: any) => ({
                            ...prevState,
                            LastName: value,
                          }));
                        }}
                        onBlur={(e: any) => {
                          const {
                            target: { value },
                          } = e;
                          formik.setFieldValue('LastName', value);
                          formik.setFieldTouched('LastName');
                        }}
                        error={
                          formik.touched.LastName && formik.errors['LastName']
                            ? true
                            : false
                        }
                        helperText={
                          formik.touched.LastName && formik.errors['LastName']
                            ? formik.errors['LastName']
                            : ''
                        }
                      />
                    </FormControl>
                  </Box>
                  <FormControl fullWidth className="TimeZoneMarginTop">
                    <InputLabel id={'timeZone'}>Time Zone</InputLabel>
                    <Select
                      label={'Time Zone'}
                      labelId={'Timezone'}
                      id={'Timezone'}
                      name={'Timezone'}
                      value={userProfileState.Timezone}
                      onChange={(e: any) => {
                        const {
                          target: { value },
                        } = e;
                        formik.setFieldValue('Timezone', value);
                        setUserProfileState((prevState: any) => ({
                          ...prevState,
                          Timezone: value,
                        }));
                      }}
                      onBlur={(e: any) => {
                        const {
                          target: { value },
                        } = e;
                        formik.setFieldValue('Timezone', value);
                        formik.setFieldTouched('Timezone');
                        setUserProfileState((prevState: any) => ({
                          ...prevState,
                          Timezone: value,
                        }));
                      }}
                      error={
                        formik.touched.Timezone && formik.errors.Timezone
                          ? true
                          : false
                      }
                    >
                      {STATIC_TIMEZONES.map((item: any) => {
                        return (
                          <MenuItem key={item.name} value={item.value}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {formik.touched.Timezone && formik.errors.Timezone && (
                      <FormHelperText className="FormHelperTextStyle">
                        {formik.touched.Timezone && formik.errors.Timezone
                          ? formik.errors.Timezone
                          : ''}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box className="ContactInfoStyle">
                  <Typography className="HeadertextStyle">
                    Contact Information
                  </Typography>
                  <Box>
                    <Box className="EditBoxStyle">
                      <Typography>Emails</Typography>
                      {emails?.map((email: any) => {
                        return (
                          <Typography
                            key={Math.random()}
                            className="EditValueTextStyle"
                          >
                            {email}
                          </Typography>
                        );
                      })}
                    </Box>
                    <Box className="EditBoxStyle">
                      <Typography>Phone Numbers</Typography>
                      {phoneNumbers?.map((phoneNumber: any) => {
                        return (
                          <Typography
                            key={Math.random()}
                            className="EditValueTextStyle"
                          >
                            {formatPhoneNumber(phoneNumber)}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>

                <Box className="ProfEditButtonAlign">
                  <Button
                    variant="outlined"
                    className="SideRailfooterButtonStyle"
                    disabled={false}
                    onClick={() => {
                      setIsInEditMode(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="SideRailfooterButtonStyle"
                    variant="contained"
                    type="submit"
                    disabled={!formik.isValid}
                    onClick={() => {
                      handleProfileSaveAction();
                    }}
                  >
                    {updateUserIsPending && (
                      <CircularProgress
                        sx={{ marginRight: '15px' }}
                        size={24}
                      ></CircularProgress>
                    )}
                    <SaveIcon />
                    Save
                  </Button>
                </Box>
              </div>
            </form>
          </Box>
        </Box>
        <Box></Box>
      </Box>
    );
  };

  return (
    <Box className="ProfileTabContainer">
      {fetchByIdIsPending ? (
        <CircularProgress
          sx={{ marginLeft: '45%', marginTop: '2%' }}
          size={24}
        ></CircularProgress>
      ) : (
        <Box className="ProfileTabBoxStyle">
          {isInEditMode ? renderProfileDataEdit() : renderProfileDataView()}
        </Box>
      )}
    </Box>
  );
};

export default ProfileTab;
