/**
 * Transaction Details SideRail
 */
import { ExpandMore, Done, Close } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardMedia,
  Typography,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import SettingsRow from '../../components/Common/SettingsRow';
import useCurrency from '../../Hooks/useCurrency';
import { AZURE_IMAGE_API, DATETIME_FORMAT } from '../../store/constants';
import './TransactionDetails.scss';

interface TransactionDetailsProps {
  selectedTransactionId: string;
  selectedTransactionInfo: any;
  isTransactionInfoPending?: any;
  isAccordionExpanded?: boolean;
}

const TransactionDetails: React.FC<TransactionDetailsProps> = ({
  selectedTransactionId,
  selectedTransactionInfo,
  isTransactionInfoPending,
  isAccordionExpanded = false,
}) => {
  const transactionDetails = selectedTransactionInfo[0] || {};
  const { toCurrency } = useCurrency();

  const formatPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber && phoneNumber?.trim() && phoneNumber?.length > 10) {
      phoneNumber = phoneNumber.replace('+', '');
      return `+${phoneNumber.substring(0, 1)} (${phoneNumber.substring(
        1,
        4
      )}) - ${phoneNumber.substring(4, 7)} ${phoneNumber.substring(7, 11)}`;
    } else {
      return '-';
    }
  };

  return !isTransactionInfoPending ? (
    <>
      <Accordion
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
        className={
          isAccordionExpanded ? 'AccordionStylePrint' : 'AccordionStyle'
        }
        defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight="bold">Transaction Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SettingsRow title="Total">
            <Box className="TotalAmountRow">
              {transactionDetails?.ResponseType &&
              transactionDetails?.ResponseType === 'Declined' ? (
                <Close className="DeclinedIcon" />
              ) : (
                <Done className="SuccessIcon" />
              )}
              <Typography fontSize="48px" fontWeight="400">
                {toCurrency(
                  Number(transactionDetails?.Amount).toFixed(2).toString(),
                  '.',
                  '$',
                  ','
                )}
              </Typography>
            </Box>
          </SettingsRow>
          <Box className="CardRow" marginTop="2%" width="100%">
            <Box width="65%">
              <SettingsRow title="Card Info" border={false}>
                <Card className="CreditCards">
                  <CardMedia
                    component="img"
                    image={`${AZURE_IMAGE_API}/cards/visa.svg`}
                    alt="green iguana"
                  />
                  <Box py={1} px={1} className="MiddleContent">
                    <Typography fontSize="17px" className="PanNumber">
                      {`**** **** **** ${transactionDetails?.CardLast4Digit}`}
                    </Typography>
                  </Box>
                  <Box py={1} px={1} className="LeftContent">
                    <Typography fontWeight="600" fontSize="11px" color="white">
                      NAME
                    </Typography>
                    <Typography fontWeight="400" fontSize="13px" color="white">
                      {transactionDetails?.Name
                        ? transactionDetails?.Name?.toUpperCase()
                        : '-'}
                    </Typography>
                  </Box>
                  <Box py={1} px={1} className="RightContent">
                    <Typography fontWeight="600" fontSize="11px" color="white">
                      EXPIRATION DATE
                    </Typography>
                    <Typography fontWeight="400" fontSize="13px" color="white">
                      {transactionDetails?.ExpirationDate
                        ? `${transactionDetails.ExpirationDate?.toString()?.substr(
                            0,
                            2
                          )}/${transactionDetails.ExpirationDate?.toString()?.substr(
                            2,
                            4
                          )}`
                        : '-'}
                    </Typography>
                  </Box>
                </Card>
              </SettingsRow>
            </Box>
            <Box width="30%">
              <SettingsRow title="Entry Mode" border={false}>
                <Card className="ResponseCard">
                  <Typography>
                    {transactionDetails?.PaymentMethod
                      ? transactionDetails?.PaymentMethod
                      : '-'}
                  </Typography>
                  <img
                    className="ResponseImg"
                    src={`${AZURE_IMAGE_API}/entrymode/keyentry.png`}
                    alt="green iguana"
                  />
                </Card>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="CardRow" marginTop="2%" width="100%">
            <Box width="30%">
              <SettingsRow title="Transaction Type">
                <Typography>
                  {transactionDetails?.TransactionType
                    ? transactionDetails?.TransactionType
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="30%">
              <SettingsRow title="Response">
                <Typography>
                  {transactionDetails?.ResponseType
                    ? transactionDetails?.ResponseType
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="30%">
              <SettingsRow title="Approved Total Amount">
                <Typography align="right">
                  {toCurrency(
                    Number(transactionDetails?.Amount)?.toFixed(2)?.toString(),
                    '.',
                    '$',
                    ','
                  )}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="CardRow" marginTop="2%" width="100%">
            <Box width="30%">
              <SettingsRow title="Merchant">
                <Typography>
                  {transactionDetails?.ClientName
                    ? transactionDetails?.ClientName
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="30%">
              <SettingsRow title="Issuer Name">
                <Typography>-</Typography>
              </SettingsRow>
            </Box>
            <Box width="30%">
              <SettingsRow title="Payment Method">
                <Typography>
                  {transactionDetails?.PaymentMethod
                    ? transactionDetails?.PaymentMethod
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="CardRow" marginTop="2%" width="100%">
            <Box width="30%">
              <SettingsRow title="Authorization Code">
                <Typography>
                  {transactionDetails?.AuthorizationCode &&
                  transactionDetails.AuthorizationCode?.trim()
                    ? transactionDetails.AuthorizationCode
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="30%">
              <SettingsRow title="Response Code">
                <Typography>
                  {transactionDetails?.ResponseCode
                    ? transactionDetails.ResponseCode
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="30%">
              <SettingsRow title="Response Reason">
                <Typography>
                  {transactionDetails?.ResponseReason
                    ? transactionDetails.ResponseReason
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
          <Box marginTop="4%" marginBottom="2%">
            <Typography fontWeight="bold">Batch Details</Typography>
          </Box>
          <Box className="CardRow" width="100%">
            <Box width="30%">
              <SettingsRow title="Opened At">
                <Typography fontSize="15px">
                  {transactionDetails?.OpenAt
                    ? moment(transactionDetails?.OpenAt).format(DATETIME_FORMAT)
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="30%">
              <SettingsRow title="Closed At">
                <Typography fontSize="15px">
                  {transactionDetails?.CloseAt
                    ? moment(transactionDetails?.CloseAt).format(
                        DATETIME_FORMAT
                      )
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="30%">
              <SettingsRow title="Settled At">
                <Typography fontSize="15px">
                  {transactionDetails?.SettledAt
                    ? moment(transactionDetails?.SettledAt).format(
                        DATETIME_FORMAT
                      )
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
        className="AccordionStyle"
        defaultExpanded={isAccordionExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight="bold">Token Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="CardRow" width="100%">
            <Box width="45%">
              <SettingsRow title="Token ID">
                <Typography>-</Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="Token Service Provider">
                <Typography>-</Typography>
              </SettingsRow>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
        className="AccordionStyle"
        defaultExpanded={isAccordionExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight="bold">Billing Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="CardRow" width="100%">
            <Box width="45%">
              <SettingsRow title="Street Address">
                <Typography>
                  {transactionDetails.BillToAddress1
                    ? transactionDetails.BillToAddress1
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="Apt.,Building,Suite,Floor">
                <Typography>
                  {transactionDetails?.BillToAddress2
                    ? transactionDetails.BillToAddress2
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="CardRow" marginTop="2%" width="100%">
            <Box width="45%">
              <SettingsRow title="City">
                <Typography>
                  {transactionDetails?.BillToCity
                    ? transactionDetails.BillToCity
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="State">
                <Typography>
                  {transactionDetails?.BillToState
                    ? transactionDetails.BillToState
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="CardRow" marginTop="2%" width="100%">
            <Box width="45%">
              <SettingsRow title="Zip Code">
                <Typography>
                  {transactionDetails?.BillToPostalCode
                    ? transactionDetails.BillToPostalCode
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="Country">
                <Typography>
                  {transactionDetails?.BillToCountry
                    ? transactionDetails.BillToCountry
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="CardRow" marginTop="2%" width="100%">
            <Box width="45%">
              <SettingsRow title="Email">
                <Typography>
                  {transactionDetails?.BillToEmailAddress
                    ? transactionDetails.BillToEmailAddress
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="Phone Number">
                <Typography>
                  {transactionDetails?.BillToPhone
                    ? formatPhoneNumber(transactionDetails.BillToPhone)
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
        className={
          isAccordionExpanded ? 'AccordionStylePrint' : 'AccordionStyle'
        }
        defaultExpanded={isAccordionExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight="bold">Shipping Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="CardRow" width="100%">
            <Box width="45%">
              <SettingsRow title="Street Address">
                <Typography>
                  {transactionDetails?.ShipToAddress1
                    ? transactionDetails.ShipToAddress1
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="Apt.,Building,Suite,Floor">
                <Typography>
                  {transactionDetails?.ShipToAddress2
                    ? transactionDetails.ShipToAddress2
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="CardRow" marginTop="2%" width="100%">
            <Box width="45%">
              <SettingsRow title="City">
                <Typography>
                  {transactionDetails?.ShipToCity
                    ? transactionDetails.ShipToCity
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="State">
                <Typography>
                  {transactionDetails?.ShipToState
                    ? transactionDetails.ShipToState
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="CardRow" marginTop="2%" width="100%">
            <Box width="45%">
              <SettingsRow title="Zip Code">
                <Typography>
                  {transactionDetails?.ShipToPostalCode
                    ? transactionDetails.ShipToPostalCode
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="Country">
                <Typography>
                  {transactionDetails?.ShipToCountry
                    ? transactionDetails.ShipToCountry
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="CardRow" marginTop="2%" width="100%">
            <Box width="45%">
              <SettingsRow title="Email">
                <Typography>
                  {transactionDetails?.ShipToEmailAddress
                    ? transactionDetails.ShipToEmailAddress
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="Phone Number">
                <Typography>
                  {transactionDetails?.ShipToPhone
                    ? formatPhoneNumber(transactionDetails.ShipToPhone)
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
        className="AccordionStyle"
        defaultExpanded={isAccordionExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight="bold">System</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="CardRow" width="100%">
            <Box width="45%">
              <SettingsRow title="Authorization Host">
                <Typography>-</Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="Settle Host">
                <Typography>-</Typography>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="CardRow" marginTop="2%" width="100%">
            <Box width="45%">
              <SettingsRow title="Processing Time">
                <Typography>
                  {transactionDetails?.ProcessingTime
                    ? `${transactionDetails.ProcessingTime}ms`
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="Source IP">
                <Typography>
                  {transactionDetails?.IpAddress
                    ? transactionDetails.IpAddress
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  ) : (
    <CircularProgress
      sx={{ marginLeft: '45%', marginTop: '2%' }}
      size={24}
    ></CircularProgress>
  );
};

export default TransactionDetails;
