/*
 Transaction table on trasnsaction tab under The reporting page
*/
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { dateFormatter } from '../../libs/helpers';
import '../../pages/Users/Users.scss';
import './UserRolesListTable.scss';
import { DATETIME_FORMAT } from '../../store/constants';
import { Hidden } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';

interface UserRolesTabelProps {
   // for the future conann
   roles: any[];
}


const UserRolesListTable: React.FC<UserRolesTabelProps> = ({
  roles
}) => {

 
 
  const renderDefaultMessage = () => (
    <TableRow>
      <TableCell colSpan={10} align="center">
        No Data To Show
      </TableCell>
    </TableRow>
  );

  const renderTableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell width="15%" className="UserTableHeader">
          Id
        </TableCell>
        <TableCell width="20%" className="UserTableHeader">
          Name
        </TableCell>
        <TableCell width="20%" className="UserTableHeader">
          Description
        </TableCell>
    </TableRow>
    </TableHead>
  );

  const renderTableBody = () => (
    <TableBody>
      {roles?.length > 0 ? renderUserRows() : renderDefaultMessage()}
    </TableBody>
  );
  const renderUserRows = () =>
    roles?.map((row) => {
      return (
        <TableRow
          key={Math.random()}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          className="UserTableRowStyle"
          onClick={() => { }}
        >
          <TableCell>{row?.id}</TableCell>
          <TableCell>{row?.name}</TableCell>
          <TableCell>{row?.description}</TableCell>
        </TableRow>
      );
    });

  return (
    <>
      <InfiniteScroll
        dataLength={(roles) ? roles.length : 0}
        next={()=>{}}
        //To put endMessage and loader to the top.
        hasMore={false}
        loader={<></>}
        scrollableTarget="userRolesTableID"
      >
        <TableContainer
          id={'userRolesTableID'}
          className="MerchantTable"
          component={Paper}
          style={{
            height: "calc(100vh - 330px)",
            overflow: "auto"
          }}
        >
          <Table stickyHeader aria-label="simple table">
            {renderTableHeader()}
            {renderTableBody()}
          </Table>
        </TableContainer>
      </InfiniteScroll>

    </>);
};

export default UserRolesListTable;
