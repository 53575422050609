import React, { ReactChild, ReactNode, useEffect, useState } from 'react';
import './SideRail.scss';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

interface SideRailProps {
  children: ReactNode;
  title?: ReactChild;
  description?: ReactNode;
  open?: boolean;
  onClose?: () => void;
  dimBackground?: boolean;
  width?: string;
  footer?: ReactChild;
  hideCrossIcon?: boolean;
}

const SideRail: React.FC<SideRailProps> = ({
  children,
  open = false,
  title = undefined,
  onClose = undefined,
  description = undefined,
  dimBackground = false,
  footer = undefined,
  hideCrossIcon = undefined,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleSideRailClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const renderSideRailHeader = () => (
    <Box
      textAlign="left"
      display="inline-flex"
      width="100%"
      className="SideRailHeaderStyle"
    >
      {title && typeof title === 'string' ? (
        <Box width="100%">
          <Typography fontSize="x-large" fontWeight="bold">
            {title}
          </Typography>
          {description && typeof description === 'string' ? (
            <Typography fontSize="medium">{description}</Typography>
          ) : (
            description
          )}
        </Box>
      ) : (
        <Box width="100%">{title}</Box>
      )}
      {hideCrossIcon ? (
        ''
      ) : (
        <Box textAlign="end" justifyContent="end">
          <IconButton
            className="IconButtonClass"
            onClick={() => {
              handleSideRailClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
      )}
    </Box>
  );

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={isOpen}
      className="SideRailClass"
      onClose={handleSideRailClose}
    >
      {renderSideRailHeader()}
      <Box className="SideRailBodyStyle">
        <div style={{ minWidth: "650px" }}>
          {children}
        </div>
      </Box>
      {footer && <Box className="SideRailFooterStyle">
      <div style={{ minWidth: "280px" }}>
          {footer}
        </div>
       </Box>}
    </Drawer>
  );
};

export default SideRail;
