import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { usStatesSelectOptions } from '../../libs/helpers';
import {
  CountryOptions,
  PRINCIPAL_OWERNER_TYPE_OPTIONS,
} from '../../store/constants';
import SelectField from '../Common/SelectInput/SelectField';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import './PrincipleInformationForm.scss';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React from 'react';

interface AddPrincipleValues {
  primaryContactRole: string;
  percentOwnership: string;
  firstName: string;
  lastName: string;
  Email: string;
  PhoneNumber: string;
  principleState: string;
  principleZipCode: string;
  principleCountry: string;
  principleAddress1: string;
  principleAddress2: string;
  principleCity: string;
  date: string;
  isPrimary: boolean;
}

interface PrincipleDetailsProps {
  createPrinciple?: ((value: any) => void) | undefined;
  principalInfoData?: ((value: any) => void) | undefined;
}

const PrincipleInformation: React.FC<PrincipleDetailsProps> = ({
  createPrinciple,
  principalInfoData,
}) => {
  const initialData: any = [
    {
      primaryContactRole: '',
      percentOwnership: '',
      firstName: '',
      lastName: '',
      Email: '',
      PhoneNumber: '',
      principleState: '',
      principleZipCode: '',
      principleCountry: '',
      principleAddress1: '',
      principleAddress2: '',
      principleCity: '',
      dateOfBirth: '',
      isPrimary: true,
      ssn: '',
    },
  ];

  const [principleInfoState, setPrincipleInfoState] =
    useState<any>(initialData);
  const [creditCardNumber, setCreditCardNumber] = useState<any>([]);
  const [ownershipNumber, setOwnershipNumber] = useState<any>([]);
  const [maskedCreditCardNumber, setMaskedCreditCardNumber] = useState<any>([]);
  const [showMaskedValue, setShowMaskedValue] = useState<boolean>(false);
  const [stateList, setStateList] = useState<Array<object>>(
    usStatesSelectOptions()
  );

  const initialValues = { principleInfoState: initialData };

  let principalFormValues: any = {};

  const formik: any = useFormik({
    initialValues: initialValues,
    onSubmit: (fromValues: any) => {},
  });

  useEffect(() => {
    principalInfoData && principalInfoData(principleInfoState);
  });

  const handleAddPrinciple = () => {
    let data = principleInfoState;
    setPrincipleInfoState([
      ...data,
      {
        primaryContactRole: '',
        percentOwnership: '',
        firstName: '',
        lastName: '',
        Email: '',
        PhoneNumber: '',
        principleState: '',
        principleZipCode: '',
        principleCountry: '',
        principleAddress1: '',
        principleAddress2: '',
        principleCity: '',
        dateOfBirth: '',
        ssn: '',
      },
    ]);
  };

  const handleRemovePrinciple = (index: any) => {
    let data = [...principleInfoState];
    data.splice(index, 1);
    setPrincipleInfoState(data);
  };

  const handleOwnershipValue = (index: any, e: any) => {
    let { value } = e.target;
    // handleFieldChange(e);
    if (value == value.replace(/[^\d ]/g, '') && value.length < 20) {
      let cardNumber = value
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{5})/g, '$1-')
        .trim();
      let newArr = [...ownershipNumber];
      newArr[index] = cardNumber;
      setOwnershipNumber(newArr);
      let data = [...principleInfoState];
      data[index][e.target.name] = value;
      setPrincipleInfoState(data);
    }
  };

  const handleSSNValueChange = (index: any, e: any) => {
    let { value } = e.target;
    // handleFieldChange(e);
    if (value == value.replace(/[^\d ]/g, '') && value.length < 11) {
      let cardNumber = value
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();
      let newArr = [...creditCardNumber];
      newArr[index] = cardNumber;
      setCreditCardNumber(newArr);
      let data = [...principleInfoState];
      data[index][e.target.name] = value;
      setPrincipleInfoState(data);

      // setPrincipleInfoState((prevState : any) => ({
      //   ...prevState,
      //   number: value,
      // }));
    }
  };

  const handleSSNValueBlur = (index: any, e: any) => {
    let { value } = e.target;
    const ccNumberLength = value.length;
    const maskedPart = value
      .substring(0, ccNumberLength - 4)
      .replace(/\d/g, '*');

    const maskedValue = `${maskedPart.trim()}${value.substring(
      maskedPart.length - 1
    )}`;
    let data = [...maskedCreditCardNumber];
    data[index] = maskedValue;
    setMaskedCreditCardNumber(data);
    setShowMaskedValue(true);
    // if (!creditCardMod10Validator(value)) {
    //   if (!validationErrors.includes('Card number is not valid')) {
    //     setValidationErrors([...validationErrors, 'Card number is not valid']);
    //   }
    // } else {
    //   setValidationErrors([
    //     ...validationErrors.filter(
    //       (error) => error !== 'Card number is not valid'
    //     ),
    //   ]);
    // }
    // handleFieldBlur(e);
  };

  const handleClickShowPassword = () => {
    setShowMaskedValue(!showMaskedValue);
  };

  const handleFormChange = (
    index: any,
    propertyName: string,
    propertyValue: String
  ) => {
    let data = [...principleInfoState];
    data[index][propertyName] = propertyValue;
    setPrincipleInfoState(data);
  };

  const renderPrincipleHeader = () => {
    return (
      <Box className="SectionBox">
        <Box display="inline-flex" className="FullWidth">
          <Box className="PrincipleRoleSection">
            <Typography fontSize="large" fontWeight="bold">
              Principal Information
            </Typography>
            <Typography variant="body2">
              List All Owners With 25% Or More Ownership
            </Typography>
          </Box>
          <Box className="StyledAddRoleButton">
            <Button
              variant="outlined"
              className="ButtonText"
              onClick={handleAddPrinciple}
            >
              <AddIcon />
              New Principle
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderPrincipleForm = (principleInfoState: any, index: any) => (
    <Box key={index}>
      {principleInfoState.isPrimary ? '' : <Divider variant="fullWidth" />}
      <Box className="SectionBox">
        <Typography fontWeight="bold" fontSize="large" className="HeadingText">
          {`Principal ${index + 1} ${
            principleInfoState.isPrimary ? '(Primary contact)' : ''
          }`}
        </Typography>
        <Box className="PrincipleRow">
          <FormControl fullWidth>
            <InputLabel>Primary Contact Role</InputLabel>
            <Select
              id={'primaryContactRole'}
              variant="outlined"
              name={`primaryContactRole`}
              label="Primary Contact Role"
              labelId="PaymentMethodLabel"
              className="FirstNameStyle"
              value={principleInfoState[index]?.primaryContactRole}
              onChange={(event: any) => {
                handleFormChange(index, event.target.name, event.target.value);
                formik.setFieldValue(`primaryContactRole`, event.target.value);
              }}
            >
              {PRINCIPAL_OWERNER_TYPE_OPTIONS.map((item: any) => {
                return (
                  <MenuItem key={item.key} value={item.key}>
                    {item.value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl id={'LastName'} fullWidth>
            <TextField
              id={'percentOwnership'}
              name={`percentOwnership`}
              label="Percent OwnerShip"
              className="LastNameStyle"
              value={ownershipNumber[index]}
              onChange={(event: any) => {
                // handleFormChange(index,event.target.name,event.target.value)
                handleOwnershipValue(index, event);
                formik.setFieldValue(`percentOwnership`, event.target.value);
              }}
            />
          </FormControl>
        </Box>
        <Box className="PrincipleRow">
          <FormControl fullWidth>
            <TextField
              id={`firstName`}
              name={`firstName`}
              label="First Name"
              className="FirstNameStyle"
              value={principleInfoState[index]?.firstName}
              onChange={(event: any) => {
                handleFormChange(index, event.target.name, event.target.value);
                formik.setFieldValue(`firstName`, event.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              id="lastName"
              name={`lastName`}
              label="Last Name"
              className="LastNameStyle"
              value={principleInfoState[index]?.lastName}
              onChange={(event: any) => {
                handleFormChange(index, event.target.name, event.target.value);
                formik.setFieldValue(`lastName`, event.target.value);
              }}
            />
          </FormControl>
        </Box>
        <Box className="PrincipleRow">
          <FormControl fullWidth>
            <TextField
              id={'Email'}
              name={`Email`}
              label="Email"
              className="FirstNameStyle"
              value={principleInfoState[index]?.Email}
              onChange={(event: any) => {
                handleFormChange(index, event.target.name, event.target.value);
                formik.setFieldValue(`Email`, event.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <PhoneInput
              specialLabel="Phone Number"
              inputProps={{
                name: `PhoneNumber`,
              }}
              country={'us'}
              placeholder={'Phone Number'}
              value={principleInfoState[index]?.PhoneNumber}
              countryCodeEditable={false}
              onChange={(phone) => {
                handleFormChange(index, `PhoneNumber`, phone);
                // setPrincipleInfoState([...principleInfoState, `${principleInfoState}${[index]}?.PhoneNumber`,phone]);
                // setPrincipleInfoState([{`principleInfoState${[index]}?.PhoneNumber`:phone}])
              }}
              containerStyle={{
                width: '102%',
                marginLeft: '2.5%',
              }}
              inputStyle={{
                width: '96%',
                height: '95%',
                marginRight: '2%',
                borderRadius: '10px',
              }}
            />
          </FormControl>
        </Box>
        <Box className="PrincipleRow">
          <FormControl fullWidth>
            <TextField
              id="dateOfBirth"
              name={`dateOfBirth`}
              label="Date Of Birth"
              type="date"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              className="FirstNameStyle"
              value={principleInfoState[index]?.dateOfBirth}
              onChange={(event: any) => {
                handleFormChange(index, event.target.name, event.target.value);
                formik.setFieldValue(`dateOfBirth`, event.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              id={'ssn'}
              name={`ssn`}
              label="Last 4 of Social Security Number(ssn)"
              className="ssnNameStyle"
              disabled={false}
              value={
                showMaskedValue
                  ? maskedCreditCardNumber[index]
                  : creditCardNumber[index]
              }
              onChange={(event: any) => {
                handleSSNValueChange(index, event);
              }}
              onBlur={(event: any) => {
                handleSSNValueBlur(index, event);
              }}
              onFocus={() => {
                setShowMaskedValue(false);
              }}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showMaskedValue ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Box>
      </Box>
      <Divider variant="fullWidth" />
      <Box className="SectionBox">
        <Typography fontWeight="bold" fontSize="large" className="HeadingText">
          Principle Address(No PO Box)
        </Typography>
        <Box className="PrincipleRow">
          <FormControl fullWidth>
            <TextField
              id={'principleAddress1'}
              name={`principleAddress1`}
              label="Street Address"
              className="FirstNameStyle"
              value={principleInfoState[index]?.principleAddress1}
              onChange={(event: any) => {
                handleFormChange(index, event.target.name, event.target.value);
                formik.setFieldValue(`principleAddress1`, event.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              id="principleAddress2"
              name={`principleAddress2`}
              label="Apt.Build.Suit.Floor"
              className="LastNameStyle"
              value={principleInfoState[index]?.principleAddress2}
              onChange={(event: any) => {
                handleFormChange(index, event.target.name, event.target.value);
                formik.setFieldValue(`principleAddress2`, event.target.value);
              }}
            />
          </FormControl>
        </Box>
        <Box className="PrincipleRow">
          <FormControl fullWidth>
            <TextField
              id={'principleCity'}
              name={`principleCity`}
              label="City"
              className="FirstNameStyle"
              value={principleInfoState[index]?.principleCity}
              onChange={(event: any) => {
                handleFormChange(index, event.target.name, event.target.value);
                formik.setFieldValue(`principleCity`, event.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>State</InputLabel>
            <SelectField
              id={'principleState'}
              label="State"
              name={`principleState`}
              className="LastNameStyle"
              options={stateList}
              value={principleInfoState[index]?.principleState}
              onChange={(event: any) => {
                handleFormChange(index, event.target.name, event.target.value);
                formik.setFieldValue(`principleState`, event.target.value);
              }}
            />
          </FormControl>
        </Box>
        <Box className="PrincipleRow">
          <FormControl fullWidth>
            <TextField
              id={'principleZipCode'}
              name={`principleZipCode`}
              label="Zip Code"
              className="FirstNameStyle"
              value={principleInfoState[index]?.principleZipCode}
              onChange={(event: any) => {
                handleFormChange(index, event.target.name, event.target.value);
                formik.setFieldValue(`principleZipCode`, event.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Country</InputLabel>
            <Select
              id={'principleCountry'}
              variant="outlined"
              name={`principleCountry`}
              label="Country"
              className="LastNameStyle"
              value={principleInfoState[index]?.principleCountry}
              onChange={(event: any) => {
                handleFormChange(index, event.target.name, event.target.value);
                formik.setFieldValue(`principleCountry`, event.target.value);
              }}
            >
              {CountryOptions.length > 0 &&
                CountryOptions.map((item: any) => {
                  return (
                    <MenuItem key={item.key} value={item.key}>
                      {item.value}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Divider variant="fullWidth" />
      <Box className="SectionBox">
        <Typography>
          Did this file personal or business bankruptcy in ths past 10 years?
        </Typography>
        <Box>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked disabled={false} />}
              label="No"
            />
            <FormControlLabel
              control={<Checkbox disabled={false} />}
              label="Yes"
            />
          </FormGroup>
        </Box>
      </Box>
      {index > 0 && (
        <Box className="StyledAddRoleButton">
          <Button
            variant="text"
            className="RemoveButtonText"
            onClick={() => {
              handleRemovePrinciple(index);
            }}
          >
            <RemoveCircleOutlineIcon className="RemoveIconText" />
            Remove
          </Button>
        </Box>
      )}
    </Box>
  );

  return (
    <Box>
      <form onSubmit={createPrinciple}>
        {renderPrincipleHeader()}
        {principleInfoState.map((principleInfoState: any, index: any) => {
          return renderPrincipleForm(principleInfoState, index);
        })}
        {/* <Button onClick={submit}>
        Submit
      </Button> */}
      </form>
    </Box>
  );
};
export default PrincipleInformation;
