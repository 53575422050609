/**
 * Virtual Terminal Page
 * Used to do the financial transactions
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import MoneyInput from '../../components/Common/MoneyInput';
import './VirtualTerminal.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VirtualTerminalData } from '../../types/VirtualTerminalPage.types';
import { TERMINAL_TRANSACTIONS, VT_DEFAULT_DATA } from '../../store/constants';
import AddressForm from '../../components/Common/AddressForm';
import TransactionBottomBar from '../../components/TransactionBottomBar';
import TransactionCheck from '../../components/TransactionCheck';
import PaymentInformationForm from '../../components/Forms/PaymentInformation';
import { PaymentInfoFormValues } from '../../types/PaymentInfoForm.types';
import { useStore } from 'lnox';
import {
  thunkSaleTransactionAction,
  thunkAuthoriseTransactionAction,
  thunkRefundTransactionAction,
  thunkCaptureTransactionAction,
  thunkVoidTransactionAction,
  terminalResetSaleAction,
  terminalResetAuthoriseAction,
  terminalResetRefundAction,
  terminalResetCaptureAction,
  terminalResetVoidAction,
  thunkTerminalFetchTransactionsAction,
  terminalResetFetchTransactionAction,
} from '../../store/actions/virtualTerminalActions';
import { useParams } from 'react-router-dom';
import { DEFAULT_TRANSACTIONS_PAYLOAD } from '../Reporting/Reporting';
import useCurrency from '../../Hooks/useCurrency';
import { useNavigate } from 'react-router-dom';
import { toTitleCase } from '../../libs/helpers';

const VirtualTerminal: React.FC<{}> = () => {
  const [terminalData, setTerminalData] =
    useState<VirtualTerminalData>(VT_DEFAULT_DATA);
  const {
    dispatch,
    state: { virtualTerminal },
  } = useStore();
  const [transactionResponseData, setTransactionResponseData] = useState<any>(
    {}
  );
  const [isAddressFormValid, setIsAddressFormValid] = useState<boolean>(true);
  const [isPaymentInfoFormValid, setIsPaymentInfoFormValid] =
    useState<boolean>(true);
  const [isTerminalSubmitted, setIsTerminalSubmitted] = useState(false);
  const [componentRef, setComponentRef] = useState<any>({});
  const [parentTransactionData, setParentTransactionData] = useState<any>({});
  const [isParentDataRequested, setIsParentDataRequested] =
    useState<boolean>(false);
  const [childTrxnId, setChildTrxnId] = useState<string>('');
  const [childTrxnAction, setChildTrxnAction] = useState<string>('');
  const [isResetButtonClicked, setIsResetButtonClicked] =
    useState<boolean>(false);
  const [transactionTypeLabel, setTransactionTypeLabel] = useState<string>('');
  const { toCurrency } = useCurrency();
  const navigate = useNavigate();
  const DEFAULT_TIP_AMOUNT = 0;
  const CHILD_TRANSACTIONS = ['void', 'capture', 'refund'];
  let { transactionId = '', action = '' } = useParams();

  useEffect(() => {
    const {
      paymentInfo: { transactionType },
    } = terminalData;
    let data = virtualTerminal[transactionType?.toLowerCase()];
    if (data?.isFulfilled) {
      setTransactionResponseData(data?.message || {});
      setIsTerminalSubmitted(false);
    }
    if (data?.isFulfilled || data?.isRejected) {
      localStorage.setItem('IsFinancialTransaction', 'false');
    }
    // set the parent transaction data fetched
    if (
      (transactionId || childTrxnId) &&
      virtualTerminal?.transaction &&
      virtualTerminal?.transaction?.isFulfilled
    ) {
      if (virtualTerminal?.transaction?.message?.Results) {
        setParentTransactionData(
          virtualTerminal?.transaction?.message?.Results[0]
        );
      }
    }
    if (virtualTerminal?.transaction?.error) {
      setIsParentDataRequested(false);
    }
  }, [virtualTerminal]);

  /**
   * this useeffect is used to get the parent transaction
   * data for subsequeent transaction when initiated from transaction details
   *  */
  useEffect(() => {
    if (transactionId) {
      fetchTransactionsDetails(transactionId);
      dispatch(terminalResetSaleAction());
      dispatch(terminalResetAuthoriseAction());
      dispatch(terminalResetRefundAction());
      dispatch(terminalResetCaptureAction());
      dispatch(terminalResetVoidAction());
      dispatch(terminalResetFetchTransactionAction());
    }
  }, [transactionId, dispatch]);

  /**
   * this useeffect is used to get the parent transaction
   * data for subsequeent transaction when initiated from terminal page
   *  */
  useEffect(() => {
    if (childTrxnId) {
      fetchTransactionsDetails(childTrxnId);
      dispatch(terminalResetSaleAction());
      dispatch(terminalResetAuthoriseAction());
      dispatch(terminalResetRefundAction());
      dispatch(terminalResetCaptureAction());
      dispatch(terminalResetVoidAction());
      dispatch(terminalResetFetchTransactionAction());
    }
  }, [childTrxnId]);

  /***
   * this useeffect is to set parent transaction data from db to
   * terminal transaction data format (VT_DEFAULT_DATA)
   */
  useEffect(() => {
    const { Amount = 0, ApprovedAmount = 0 } = parentTransactionData;
    if (parentTransactionData && parentTransactionData.TransactionId) {
      let parentTerminalData = {
        money: {
          ...terminalData.money,
          value: toCurrency(
            Number(Amount || ApprovedAmount).toFixed(2),
            '.',
            '$',
            ','
          ),
        },
        originalAmount: Number(Amount || ApprovedAmount).toFixed(2),
        paymentInfo: {
          ...terminalData?.paymentInfo,
          transactionType: toTitleCase(action || childTrxnAction),
          paymentMethod: 'card',
          cardInfo: {
            number: `**** **** **** ${parentTransactionData?.CardLast4Digit}`,
            expDate: `${parentTransactionData?.ExpirationDate?.slice(
              0,
              2
            )}/${parentTransactionData?.ExpirationDate?.slice(2)}`,
            cvv: '***',
          },
          transactionId: parentTransactionData?.TransactionId,
        },
        billingInfo: {
          billingEmail: parentTransactionData?.BillToEmailAddress || '',
          billingPhone: parentTransactionData?.BillToPhone || '',
          billingFullName: parentTransactionData?.BillToName || '',
          billingAddress1: parentTransactionData?.BillToAddress1 || '',
          billingAddress2: parentTransactionData?.BillToAddress2 || '',
          billingCountry: 'United States',
          billingState: parentTransactionData?.BillToState || '',
          billingZipCode: parentTransactionData?.BillToPostalCode || '',
          billingCity: parentTransactionData?.BillToCity || '',
        },
        shippingInfo: {
          shippingAddress1: parentTransactionData?.ShipToAddress1 || '',
          shippingAddress2: parentTransactionData?.ShipToAddress2 || '',
          shippingCountry: 'United States',
          shippingEmail: parentTransactionData?.ShipToEmailAddress || '',
          shippingZipCode: parentTransactionData?.ShipToPostalCode || '',
          shippingState: parentTransactionData?.ShipToState || '',
          shippingPhone: parentTransactionData?.ShipToPhone || '',
          shippingFullName: parentTransactionData?.ShipToName || '',
          shippingCity: parentTransactionData?.ShipToCity || '',
        },
      };
      setTerminalData(parentTerminalData);
      setIsParentDataRequested(false);
    }
  }, [parentTransactionData, transactionId, childTrxnId]);

  useEffect(() => {
    const {
      paymentInfo: { transactionType },
    } = terminalData;
    if (!transactionType) {
      setTerminalData((prevState) => ({
        ...prevState,
        paymentInfo: {
          transactionType: TERMINAL_TRANSACTIONS[0].value,
          paymentMethod: '',
          cardInfo: { number: '', expDate: '', cvv: '', trackData: '' },
          bankAccount: { accountNumber: '', routingNumber: '' },
          transactionId: '',
        },
      }));
      setTerminalData((prevState) => ({
        ...prevState,
        money: {
          value: '',
          currency: {
            name: 'usd',
            label: 'USD',
            symbol: '$',
            decimalSeparator: '.',
            thousandSeparator: ',',
          },
        },
      }));
      setParentTransactionData({});
      setIsResetButtonClicked(false);
    }
  }, [terminalData]);

  useEffect(() => {
    setTransactionTypeLabel(terminalData?.paymentInfo?.transactionType);
    if (parentTransactionData && parentTransactionData.TransactionId) {
      const type =
        parentTransactionData?.TransactionType?.toLowerCase() === 'auth' &&
          terminalData?.paymentInfo?.transactionType?.toLowerCase() === 'void'
          ? 'Reversal'
          : terminalData?.paymentInfo?.transactionType;
      setTransactionTypeLabel(type);
    }
  }, [parentTransactionData, terminalData]);

  const fetchTransactionsDetails = (transactionId: string) => {
    setIsParentDataRequested(true);
    setParentTransactionData({});
    dispatch(
      thunkTerminalFetchTransactionsAction({
        ...DEFAULT_TRANSACTIONS_PAYLOAD,
        term: `transactionId=${transactionId}`,
      })
    );
  };

  const handleMoneyInputChange = (e: ChangeEvent<any>) => {
    const {
      target: { value },
    } = e;
    setTerminalData({
      ...terminalData,
      money: value,
    });
  };

  const handlePaymentInfoChange = (paymentInfo: PaymentInfoFormValues) => {
    setChildTrxnId('');
    setChildTrxnAction('');
    if (
      paymentInfo?.transactionId &&
      CHILD_TRANSACTIONS.includes(paymentInfo?.transactionType?.toLowerCase())
    ) {
      setChildTrxnId(paymentInfo?.transactionId);
      setChildTrxnAction(paymentInfo?.transactionType);
    }
    setTerminalData({
      ...terminalData,
      paymentInfo: paymentInfo,
    });
  };

  const resetTerminalData = () => {
    setTerminalData(VT_DEFAULT_DATA);
    setTransactionResponseData({});
    setParentTransactionData({});
    transactionId = '';
    action = '';
    setChildTrxnId('');
    setIsResetButtonClicked(true);
    dispatch(terminalResetSaleAction());
    dispatch(terminalResetAuthoriseAction());
    dispatch(terminalResetRefundAction());
    dispatch(terminalResetCaptureAction());
    dispatch(terminalResetVoidAction());
    dispatch(terminalResetFetchTransactionAction());
  };

  const handleTerminalReset = () => {
    resetTerminalData();
    navigate('/terminal');
  };

  const createBillToRequestPart = () => {
    const {
      billingInfo: {
        billingEmail,
        billingAddress1,
        billingAddress2,
        billingZipCode,
        billingPhone,
        billingFullName,
        billingCity,
        billingState,
        billingCountry,
      },
    } = terminalData;

    const [firstName = '', lastName = ''] = billingFullName
      ? billingFullName?.trim()?.split(' ')
      : [];

    return {
      firstName: firstName,
      lastName: lastName,
      address: {
        address1: billingAddress1,
        address2: billingAddress2,
        city: billingCity,
        state: billingState,
        postalCode: billingZipCode,
        country: billingCountry,
      },
      phoneNumber: billingPhone?.replace(/\D+/g, ''),
      emailAddress: billingEmail,
    };
  };

  const createShipToRequestPart = () => {
    const {
      shippingInfo: {
        shippingEmail,
        shippingAddress1,
        shippingAddress2,
        shippingZipCode,
        shippingPhone,
        shippingFullName,
        shippingCity,
        shippingState,
        shippingCountry,
      },
    } = terminalData;

    const [firstName = '', lastName = ''] = shippingFullName
      ? shippingFullName?.trim()?.split(' ')
      : [];

    return {
      firstName: firstName,
      lastName: lastName,
      address: {
        address1: shippingAddress1,
        address2: shippingAddress2,
        city: shippingCity,
        state: shippingState,
        postalCode: shippingZipCode,
        country: shippingCountry,
      },
      phoneNumber: shippingPhone?.replace(/\D+/g, ''),
      emailAddress: shippingEmail,
    };
  };

  const createTransactionRequest = () => {
    const {
      money: { value },
      paymentInfo: {
        cardInfo: { number, expDate, cvv },
      },
    } = terminalData;

    return {
      ShipTo: createShipToRequestPart(),
      AmountTotal: Number(value.replace(/[^0-9.]+/g, '')).toFixed(2),
      InstrumentType: 0,
      Account: {
        Pan: number?.replace(/\s/g, ''),
        Cvv: cvv,
        ExpirationDate: expDate?.replace('/', ''),
        BillTo: createBillToRequestPart(),
      },
      CurrencyCode: 'USD',
    };
  };

  // handle sale transaction
  const handleSaleAction = async () => {
    let request = createTransactionRequest();
    await dispatch(thunkSaleTransactionAction(request));
  };

  // handle authorisation transaction
  const handleAuthorizeAction = async () => {
    let request = createTransactionRequest();
    await dispatch(thunkAuthoriseTransactionAction(request));
  };

  // handle refund transaction
  const handleRefundAction = async () => {
    const {
      money: { value },
    } = terminalData;
    let request = {
      AmountTotal: Number(value?.replace(/[^0-9.]+/g, ''))?.toFixed(2),
      IsOnlineRefund: DEFAULT_TIP_AMOUNT,
      TransactionId: terminalData?.paymentInfo?.transactionId,
    };
    await dispatch(thunkRefundTransactionAction(request));
  };

  // handle void transaction
  const handleVoidAction = async () => {
    await dispatch(
      thunkVoidTransactionAction({
        TransactionId: terminalData?.paymentInfo?.transactionId,
      })
    );
  };

  // handle capture transaction
  const handleCaptureAction = async () => {
    const {
      money: { value },
    } = terminalData;
    let request = {
      AmountTotal: Number(value?.replace(/[^0-9.]+/g, '')).toFixed(2),
      AmountTip: DEFAULT_TIP_AMOUNT,
      TransactionId: terminalData?.paymentInfo?.transactionId,
    };
    await dispatch(thunkCaptureTransactionAction(request));
  };

  const handleTransactionAction = () => {
    const {
      paymentInfo: { transactionType },
    } = terminalData;
    setIsTerminalSubmitted(true);
    localStorage.setItem('IsFinancialTransaction', 'true');
    const transactionTypeMap: { [key: string]: any } = {
      Sale: handleSaleAction,
      Authorize: handleAuthorizeAction,
      Refund: handleRefundAction,
      Void: handleVoidAction,
      Capture: handleCaptureAction,
    };
    return transactionTypeMap[transactionType]();
  };

  // render bottom bar buttons
  const renderBottonBar = () => {
    let paymentAllowedConditions = [
      terminalData?.paymentInfo?.transactionId,
      terminalData?.paymentInfo?.referenceId,
      terminalData?.paymentInfo?.cardInfo?.number &&
      terminalData?.paymentInfo?.cardInfo?.expDate,
      terminalData?.paymentInfo?.cardInfo?.trackData,
      terminalData?.paymentInfo?.bankAccount?.accountNumber &&
      terminalData?.paymentInfo?.bankAccount?.routingNumber,
    ];
    const moneyValue =
      transactionResponseData && transactionResponseData.id
        ? transactionResponseData.approvedAmount
        : terminalData?.paymentInfo?.transactionType?.toLowerCase() === 'void'
          ? 1
          : terminalData?.money?.value?.replace(/[^0-9]/g, '');

    const buttonDisabledConditions = [
      Number(moneyValue) === 0,
      !isAddressFormValid,
      !paymentAllowedConditions.some(Boolean),
      !isPaymentInfoFormValid,
    ];
    const type =
      parentTransactionData?.TransactionType?.toLowerCase() === 'auth' &&
        terminalData?.paymentInfo?.transactionType?.toLowerCase() === 'void'
        ? 'Reversal'
        : terminalData?.paymentInfo?.transactionType;

    return (
      <TransactionBottomBar
        transactionTypeLabel={type}
        transactionAmount={terminalData?.money?.value}
        handleTerminalReset={handleTerminalReset}
        handleTransactionAction={handleTransactionAction}
        disabled={buttonDisabledConditions.some(Boolean)}
        transactionStatus={
          transactionResponseData &&
            transactionResponseData?.TerminalTransactionId
            ? true
            : false
        }
        isTerminalSubmitted={isTerminalSubmitted}
        componentToPrint={componentRef}
      ></TransactionBottomBar>
    );
  };

  const renderCheckComponent = () => {
    return (
      <TransactionCheck
        transactionRequest={terminalData}
        transactionResponse={transactionResponseData}
        transactionTypeLabel={transactionTypeLabel}
      ></TransactionCheck>
    );
  };

  return (
    <Box className="VirtualTerminalPageContainer">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isParentDataRequested}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid display="inline-flex" className="TerminalContainerStyle">

        <Box className="TerminalInputBoxStyle">
          <Box>
            <MoneyInput
              value={terminalData?.money}
              onChange={handleMoneyInputChange}
              disabled={isTerminalSubmitted}
              transactionType={terminalData?.paymentInfo?.transactionType}
            ></MoneyInput>
          </Box>
          <Box>
            <Typography fontSize="large" fontWeight="bolder">
              Payment Information
            </Typography>
            <PaymentInformationForm
              values={terminalData?.paymentInfo}
              onChange={handlePaymentInfoChange}
              disabled={
                isTerminalSubmitted ||
                (parentTransactionData && parentTransactionData.TransactionId
                  ? true
                  : false)
              }
              setIsPaymentInfoFormValid={setIsPaymentInfoFormValid}
              transactionTypeLabel={transactionTypeLabel}
            ></PaymentInformationForm>
          </Box>

          <Accordion className="BillingInfoBoxStyle" defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="NoPadding"
            >
              <Typography fontWeight="bold" fontSize="large">
                Billing Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="NoPadding">
              <AddressForm
                namePrefix="billing"
                onChange={(values: any) => {
                  setTerminalData({
                    ...terminalData,
                    billingInfo: values,
                  });
                }}
                values={terminalData?.billingInfo}
                setIsAddressFormValid={(value) => {
                  setIsAddressFormValid(value);
                }}
                requiredFields={[]}
                disabled={isTerminalSubmitted}
                isResetButtonClicked={isResetButtonClicked}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion className="BillingInfoBoxStyle">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="NoPadding"
            >
              <Typography fontWeight="bold" fontSize="large">
                Shipping Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="NoPadding">
              <AddressForm
                namePrefix="shipping"
                onChange={(values: any) => {
                  setTerminalData({
                    ...terminalData,
                    shippingInfo: values,
                  });
                }}
                values={terminalData?.shippingInfo}
                setIsAddressFormValid={(value) => {
                  setIsAddressFormValid(value);
                }}
                requiredFields={[]}
                disabled={isTerminalSubmitted}
                isResetButtonClicked={isResetButtonClicked}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="TerminalDisplayBoxStyle">
          <Box ref={(el) => setComponentRef(el)}>{renderCheckComponent()}</Box>
        </Box>


      </Grid>
      {renderBottonBar()}
    </Box>
  );
};

export default VirtualTerminal;
