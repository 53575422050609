/**
 * Main App Class
 * with all Context,state, theme and store providers
 */
import React, { ReactNode } from 'react';
import { ThemeProvider } from '@mui/material';
import { appTheme } from '../../themes/appTheme';
import rootReducer from '../../store/reducers';
import { createStore, StoreProvider } from 'lnox';
import { ApiProvider, AuthProvider, UiConfigProvider } from '../Providers';
import uiConfig from '../../ui-config';
import Layout from '../Layout/Layout';

const App: React.FC<{ children: ReactNode }> = ({ children }) => (
  <StoreProvider reducer={rootReducer} initialState={createStore(rootReducer)}>
    <AuthProvider>
      <ApiProvider>
        <UiConfigProvider uiConfig={uiConfig}>
          <ThemeProvider theme={appTheme}>
            <div  style={{ width: '100vw', height: '100vh', overflow: 'hidden'}}>
            <Layout>{children}</Layout>
            </div>
          </ThemeProvider>
        </UiConfigProvider>
      </ApiProvider>
    </AuthProvider>
  </StoreProvider>
);
export default App;
