import {
  Box,
  BoxProps,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, { ChangeEvent, useState, KeyboardEvent, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TransactionCheckBoxFilter from '../../components/TransactionCheckBoxFilter';
import { ReactComponent as GroupIcon } from '../../assets/Group.svg';
import { ReactComponent as VerticalGroupIcon } from '../../assets/VerticalGroup.svg';
import Close from '@mui/icons-material/Close';

interface SearchProps {
  value: any;
  width?: string;
  label?: string;
  filter?: boolean;
  advancedSearch?: boolean;
  onChange: (e: ChangeEvent<any>) => void;
  onSearch?: (searchTerm: string) => void;
  onReset?: () => void;
  onSetFilter?: () => void;
  tranTypeFilter?: any;
  cardTypeFilter?: any;
  statusFilter?: any;
  getListOfTranTypeChecked?: (value: any) => void;
  getListOfCardChecked?: (value: any) => void;
  getListOfStatusChecked?: (value: any) => void;
}

const TransactionSearchBox: React.FC<SearchProps & BoxProps> = ({
  onSearch,
  onChange,
  onReset,
  value,
  onSetFilter,
  tranTypeFilter,
  cardTypeFilter,
  statusFilter,
  getListOfTranTypeChecked,
  getListOfCardChecked,
  getListOfStatusChecked,
}) => {
  const [trxFilterIsOpen, setTrxFilterIsOpen] = useState(false);
  const [showClose, setShowClose] = useState<boolean>(false);

  // handle filter popover filter close and open
  const handleTrxFilterPopOverClick = () => {
    setTrxFilterIsOpen((prev) => !prev);
  };

  // handle filter popover filter close
  const handleTrxFilterPopOverClose = () => {
    setTrxFilterIsOpen(false);
  };

  const handleChange = () => {
    setShowClose(true);
  };

  useEffect(() => {
    if (showClose && value === '') {
      onReset !== undefined && onReset();
      setShowClose(false);
      // onFilterClick(false);
    }
  }, [onReset, showClose, value]);

  return (
    <Box width="100%">
      <TextField
        placeholder="Search Trans Type; Customer; Network; Last 4; Response; Amount; Merchant DBA"
        className="TransactionSearchBox"
        value={value}
        onChange={(e) => {
          onChange(e);
          handleChange();
        }}
        onKeyDown={(e: KeyboardEvent<any>) => {
          if (e.keyCode === 13 && onSearch !== undefined) {
            // setSearchApplied(Boolean(value));
            onSearch(value);
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              {showClose && (
                <IconButton
                  onClick={() => {
                    setShowClose(false);
                    onReset && onReset();
                  }}
                >
                  <Close color={'primary'} />
                </IconButton>
              )}
              <ClickAwayListener onClickAway={handleTrxFilterPopOverClose}>
                <Box sx={{ position: 'relative' }}>
                  <IconButton onClick={handleTrxFilterPopOverClick}>
                    {trxFilterIsOpen ? <VerticalGroupIcon /> : <GroupIcon />}
                  </IconButton>
                  {trxFilterIsOpen ? (
                    <TransactionCheckBoxFilter
                      tranTypeFilter={tranTypeFilter}
                      cardTypeFilter={cardTypeFilter}
                      statusFilter={statusFilter}
                      getListOfTranTypeChecked={getListOfTranTypeChecked}
                      getListOfCardChecked={getListOfCardChecked}
                      getListOfStatusChecked={getListOfStatusChecked}
                    />
                  ) : null}
                </Box>
              </ClickAwayListener>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default TransactionSearchBox;
