import React, { MouseEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useStore } from 'lnox';
import { thunkSendVerificationOTP } from '../../../store/actions/authActions';
import { useFormik } from 'formik';
import {
  validationSchema1,
  validationSchema2,
} from '../TwoFactorAuthForm/validationSchema';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

interface TwoFactorAuthFormProps {
  onForgotPassword: (event: MouseEvent) => void;
  userEmail: string;
  userPhoneNumber: string;
}

const TwoFactorAuthForm: React.FC<TwoFactorAuthFormProps> = ({
  onForgotPassword,
  userEmail,
  userPhoneNumber,
}) => {
  const { dispatch } = useStore();
  const [shownEmailSelectInput, setShownEmailSelectInput] =
    useState<boolean>(false);
  const [shownPhoneSelectInput, setShownPhoneSelectInput] =
    useState<boolean>(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState<boolean>(false);
  const [isPhoneNumberDisabled, setIsPhoneNumberDisabled] =
    useState<boolean>(false);

  useEffect(() => {
    if (userEmail && !userPhoneNumber) {
      setShownEmailSelectInput(true);
      setIsPhoneNumberDisabled(true);
    }
    if (!userEmail && userPhoneNumber) {
      setShownPhoneSelectInput(true);
      setIsEmailDisabled(true);
    }
    if (userEmail) {
      setShownEmailSelectInput(true);
    }
  }, [userEmail, userPhoneNumber]);

  const initialValues = {
    email: userEmail || '',
    phoneNumber: userPhoneNumber || '',
  };

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const renderEmailAndPhoneInput = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        p: 1,
        m: 1,
        bgcolor: 'background.paper',
        borderRadius: 1,
      }}
    >
      <Box
        sx={{ height: '50px', width: '50px', margin: 'auto' }}
        onClick={isEmailDisabled ? () => {} : handleEmailVerifySelection}
      >
        <Box
          component="img"
          sx={{
            marginLeft: '7px',
            cursor: 'pointer',
          }}
          src={shownEmailSelectInput ? '/emailIcon.svg' : '/Email_Gray_SVG.svg'}
          color="disabled"
        />
        <Typography
          fontWeight="500"
          color={shownEmailSelectInput ? '' : '#7C919D'}
        >
          EMAIL
        </Typography>
      </Box>
      <Box
        sx={{ height: '50px', width: '50px', margin: 'auto' }}
        onClick={
          isPhoneNumberDisabled ? () => {} : handlePhoneNumberVerifySelection
        }
      >
        <Box
          component="img"
          sx={{
            marginLeft: '12px',
            cursor: 'pointer',
          }}
          src={shownPhoneSelectInput ? '/PhoneIcon.svg' : '/Phone_Gray_SVG.svg'}
          color="pink"
        />
        <Typography
          fontWeight="500"
          color={shownPhoneSelectInput ? '' : '#7C919D'}
        >
          PHONE
        </Typography>
      </Box>
    </Box>
  );

  const handleSendVerification = async (formValues: any) => {
    setIsSubmitting(true);
    if (formValues?.phoneNumber) {
      formValues.phoneNumber = '+' + formValues.phoneNumber;
    }
    await dispatch(
      thunkSendVerificationOTP(formValues?.email || formValues?.phoneNumber)
    );
    setIsSubmitting(false);
  };

  const handleEmailVerifySelection = () => {
    setShownEmailSelectInput(true);
    setShownPhoneSelectInput(false);
  };

  const handlePhoneNumberVerifySelection = () => {
    setShownPhoneSelectInput(true);
    setShownEmailSelectInput(false);
  };

  const renderForgotPassword = () => (
    <Box mt={1}>
      <Link
        className="StyleLink"
        underline="hover"
        href="#"
        onClick={onForgotPassword}
      >
        Back to Login
      </Link>
    </Box>
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: shownEmailSelectInput
      ? validationSchema1
      : validationSchema2,
    onSubmit: (values) => {
      handleSendVerification(values);
    },
  });

  return (
    <Box className="TwofactAuthBox">
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h6" fontWeight="500">
          Verification Code
        </Typography>
        <Typography color={'#7C919D'}>
          Choose how to receive your verification code.
        </Typography>
        {renderEmailAndPhoneInput()}
        <div>
          {shownEmailSelectInput ? (
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={userEmail}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          ) : (
            ''
          )}
          {shownPhoneSelectInput ? (
            <PhoneInput
              specialLabel="Phone Number"
              inputProps={{
                name: 'phoneNumber',
              }}
              country={'us'}
              placeholder={'Phone'}
              value={formik.values.phoneNumber}
              onChange={(phone) => formik.setFieldValue('phoneNumber', phone)}
              inputStyle={{
                borderColor:
                  formik.touched && formik.errors.phoneNumber && 'red',
                width: '100%',
              }}
              disabled
            />
          ) : (
            ''
          )}
          {formik.touched && formik.errors && shownPhoneSelectInput && (
            <Typography
              style={{ color: 'red', padding: '0px', fontSize: '14px' }}
            >
              {formik.errors.phoneNumber}
            </Typography>
          )}
        </div>
        {renderForgotPassword()}
        <div className="PasswordBox">
          <Button
            color="primary"
            sx={{ padding: '12px' }}
            disabled={isSubmitting}
            variant="contained"
            fullWidth
            type="submit"
          >
            {isSubmitting ? (
              <CircularProgress size={24}></CircularProgress>
            ) : (
              'SEND CODE'
            )}
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default TwoFactorAuthForm;
