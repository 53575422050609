import api from '../../libs/api';
import { StatusMessageType } from '../../types/common';
import { setStatusMessageAction } from './appActions';
import { CLIENT_APPLICATION_ID, CLIENT_CREATE } from './types';

export const thunkCreatMerchantApplication =
  (params?: object) => async (dispatch: any) => {
    let response = await dispatch({
      type: CLIENT_CREATE,
      payload: api.clients.create(params),
    });
    if (!response?.value?.error) {
      await dispatch(
        setStatusMessageAction(
          StatusMessageType.SUCCESS,
          `Merchant Application Created Successfully.`
        )
      );
    }
    return response;
  };

export const thunkCreatMerchantApplicationID = () => async (dispatch: any) => {
  return dispatch({
    type: CLIENT_APPLICATION_ID,
    payload: api.clients.createApplicationID(),
  });
};
