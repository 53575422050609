import { Alert, Snackbar } from '@mui/material';
import { useStore } from 'lnox';
import React from 'react';
import { clearStatusMessagesAction } from '../../../store/actions/appActions';
import './SnackBar.scss';
interface SnackBarInputProps {
  message: string;
  severity: any;
}

const SnackBarInput: React.FC<SnackBarInputProps> = ({
  message,
  severity = 'info',
}) => {
  const [showSnackBar, setshowSnackBar] = React.useState<boolean>(true);
  const { dispatch } = useStore();

  const handleClose = () => {
    setshowSnackBar(false);
    dispatch(clearStatusMessagesAction());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      key={Math.random()}
      open={showSnackBar}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        sx={{ size: 50 }}
        className="AlertStyle"
        onClose={handleClose}
        severity={severity}
        key={Math.random()}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarInput;
