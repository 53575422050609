import { combineReducers } from '../../index';
import fetchClients from './fetchClients';
import fetchClientApiKey from './fetchClientApiKey';
import createClient from './createClient';
import fetchApplicationID from './fetchApplicationID';
import createTsysHost from './createTsysHost';
import createTokenizationHost from './createTokenizationHost';
import createShazamHost from './createShazamHost';

export default combineReducers({
  clients: combineReducers({
    fetch: fetchClients,
    fetchClientApiKey: fetchClientApiKey,
    create: createClient,
    fetchApplicationID: fetchApplicationID,
    host: combineReducers({
      createTsysHost: createTsysHost,
      createTokenizationHost: createTokenizationHost,
      createShazamHost: createShazamHost,
    }),
  }),
});
