/**
 * User Details SideRail
 */
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import React from 'react';
import SettingsRow from '../../components/Common/SettingsRow';
import { dateFormatter } from '../../libs/helpers';
import '../../pages/Users/Users.scss';
import { DATE1_FORMAT } from '../../store/constants';

interface UserDetailsProps {
  selectedUserId: string;
  selectedUserInfo: any;
}

const ViewUserDetails: React.FC<UserDetailsProps> = ({
  selectedUserId,
  selectedUserInfo,
}) => {
  const {
    FirstName,
    LastName,
    Timezone,
    IsActive,
    Email,
    PhoneNumber,
    DateAdded,
    Is2faEnabled,
    Locked,
    PasswordExpiryAt,
  } = selectedUserInfo || {};
  return selectedUserInfo && Object.keys(selectedUserInfo).length ? (
    <>
      <Accordion
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
        className="AccordionStyle"
        defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight="bold">Account Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="CardRow" width="100%">
            <Box width="45%">
              <SettingsRow title="First Name">
                <Typography>{FirstName ? FirstName : '-'}</Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="Last Name">
                <Typography>{LastName ? LastName : '-'}</Typography>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="CardRow" width="100%" marginTop="2%">
            <Box width="45%">
              <SettingsRow title="Multi-Factor Authentication">
                <Typography>{Is2faEnabled ? 'On' : 'Off'}</Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="Timezone">
                <Typography>{Timezone ? Timezone : '-'}</Typography>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="CardRow" width="100%" marginTop="2%">
            <Box width="45%">
              <SettingsRow title="User Status">
                <Typography>{IsActive ? 'Active' : 'Inactive'}</Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="User Lock Status">
                <Typography>{Locked ? 'Locked' : 'Unlocked'}</Typography>
              </SettingsRow>
            </Box>
          </Box>
          <Box className="CardRow" width="100%" marginTop="2%">
            <Box width="45%">
              <SettingsRow title="User Created">
                <Typography>
                  {DateAdded ? dateFormatter(DateAdded, DATE1_FORMAT) : '-'}
                </Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="Password Expiration">
                <Typography>
                  {PasswordExpiryAt
                    ? dateFormatter(PasswordExpiryAt, DATE1_FORMAT)
                    : '-'}
                </Typography>
              </SettingsRow>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
        className="AccordionStyle"
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight="bold">Contact Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="CardRow" width="100%">
            <Box width="45%">
              <SettingsRow title="Email">
                <Typography>{Email ? Email : '-'}</Typography>
              </SettingsRow>
            </Box>
            <Box width="45%">
              <SettingsRow title="Phone Number">
                <Typography>{PhoneNumber ? PhoneNumber : '-'}</Typography>
              </SettingsRow>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          display: 'none',
        }}
        className="AccordionStyle"
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight="bold">Roles and Accounts</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="CardRow" width="100%">
            <Typography>Roles and Accounts</Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  ) : (
    <CircularProgress
      sx={{ marginLeft: '45%', marginTop: '2%' }}
      size={24}
    ></CircularProgress>
  );
};

export default ViewUserDetails;
