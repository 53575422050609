const MIN_PAD_LENGTH = 3;

const getDigitsFromValue = (value: string = '') =>
  value?.replace(/(-(?!\d))|[^0-9|-]/g, '') || '';

const padDigits = (digits: string) => {
  const actualLength = digits.length;

  if (actualLength >= MIN_PAD_LENGTH) {
    return digits;
  }

  const amountToAdd = MIN_PAD_LENGTH - actualLength;
  const padding = '0'.repeat(amountToAdd);

  return `${padding}${digits}`;
};

const removeLeadingZeros = (value: string) =>
  value?.replace(/^0+([0-9]+)/, '$1');

const addDecimalSeparator = (
  value: string,
  separator: string,
  currencySymbol: string,
  thousandSeparator: string
) => {
  const centsStartingPosition = value.length - 2;
  const amount = removeLeadingZeros(
    value.substring(0, centsStartingPosition)
  )?.replace(/(\d)(?=(\d{3})+$)/g, `$1${thousandSeparator}`);
  const cents = value.substring(centsStartingPosition);
  return `${currencySymbol}${amount}${separator}${cents}`;
};

const toCurrency = (
  value: string,
  separator: string = '.',
  currencySymbol: string = '$',
  thousandSeparator: string = ','
) => {
  const digits = getDigitsFromValue(value);
  const digitsWithPadding = padDigits(digits);
  return addDecimalSeparator(
    digitsWithPadding,
    separator,
    currencySymbol,
    thousandSeparator
  );
};

const toNumber = (
  value: string,
  currencySymbol: string = '$',
  thousandSeparator: string = ','
) => {
  return Number(
    value?.replace(currencySymbol, '')?.replace(thousandSeparator, '')
  );
};

export default () => ({
  toCurrency,
  toNumber,
});
