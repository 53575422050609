import { Reducer } from 'react';
import { STORE_INIT } from './actions/types';

/**
 * Combine all the reducers to the one rootReducer
 * Similar to Redux's combine reducers function
 */
export const combineReducers = (reducers: { [key: string]: any }) => {
  const reducersKeys = Object.keys(reducers);
  return (
    state: { [key: string]: any } = {},
    action: { type: string; payload?: any }
  ) => {
    const nextState: { [key: string]: any } = {};
    for (let i = 0; i < reducersKeys.length; i++) {
      const key = reducersKeys[i];
      const reducer = reducers[key];
      const prevKeyState = state[key];
      const nextKeyState = reducer(prevKeyState, action);
      nextState[key] = nextKeyState;
    }
    return nextState;
  };
};

/**
 * Simple create store function.
 * Initialize Application global state
 *
 * @param rootReducer
 */
export const createStore = (rootReducer: Reducer<any, any>) => {
  return rootReducer({}, { type: STORE_INIT });
};
