import { TRANSACTION_STATUSES, TRANSACTION_TYPES } from '../store/constants';
export class TransactionHelper {
  public getSubsequentTransactions = (
    transactionStatus?: string,
    transactionType?: string
  ) => {
    try {
      let isRefundAllowed,
        isVoidAllowed,
        isCaptureAllowed = false;
      if (transactionStatus && transactionType) {
        transactionType = transactionType?.trim().toLocaleLowerCase();
        transactionStatus = transactionStatus?.trim().toLocaleLowerCase();
        switch (true) {
          case transactionType === TRANSACTION_TYPES.Sale:
            isVoidAllowed =
              transactionStatus === TRANSACTION_STATUSES.Initiated;
            isRefundAllowed =
              transactionStatus === TRANSACTION_STATUSES.Settled;
            break;
          case transactionType === TRANSACTION_TYPES.Auth:
            isVoidAllowed =
              transactionStatus === TRANSACTION_STATUSES.Initiated;
            isCaptureAllowed =
              transactionStatus === TRANSACTION_STATUSES.Initiated;
            break;
          case transactionType === TRANSACTION_TYPES.Capture:
            isRefundAllowed =
              transactionStatus === TRANSACTION_STATUSES.Settled;
            isVoidAllowed =
              transactionStatus === TRANSACTION_STATUSES.Initiated;
            break;
          case transactionType === TRANSACTION_TYPES.Refund:
            isVoidAllowed =
              transactionStatus === TRANSACTION_STATUSES.Initiated;
            break;
          default:
            break;
        }
      }
      return [isRefundAllowed, isVoidAllowed, isCaptureAllowed];
    } catch (error) {
      throw error;
    }
  };
}
