import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import './SettingsBlock.scss';

interface SettingsBlockProps {
  title: string;
  children: ReactNode;
  border?: boolean;
}

const SettingsBlock: React.FC<SettingsBlockProps> = ({
  title,
  children,
  border = true,
}) : JSX.Element => {
  return (
    <Box
      sx={{ borderBottom: border ? '1px solid #7C919D' : '' }}
      className="SettingBlockMainStyle"
    >
      <Typography className="SettingBlocktitleStyle">{title}</Typography>
      <Box>
        {children && typeof children === 'string' ? (
          <Typography>{children}</Typography>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
};

export default SettingsBlock;
