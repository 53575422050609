/**
 * Encryption and Decryption helper class
 * AES 256 alogorith is used for encryption and decryption
 * The key used is passed in env variables
 */
import CryptoJS from 'crypto-js';
import Cookies from 'universal-cookie';
import { LOCALENCRYPTIONKEY } from '../store/constants';

const cookiesAreSecure: boolean = process.env.REACT_APP_COOKIES_ARE_SECURE
  ? JSON.parse(process.env.REACT_APP_COOKIES_ARE_SECURE)
  : true;

const cookies = new Cookies();
let parsedKey: any;
let parsedIv: any;

const generateKeys = () => {
  if (localStorage.getItem('IsFinancialTransaction') === 'true') {
    parsedKey = CryptoJS.enc.Base64.parse(getTKValue());
  } else {
    parsedKey = CryptoJS.enc.Base64.parse(getDKValue());
  }
  parsedIv = CryptoJS.enc.Base64.parse(getIVValue());
};

export const Encrypt = (message: any) => {
  generateKeys();
  return CryptoJS.AES.encrypt(JSON.stringify(message), parsedKey, {
    iv: parsedIv,
  }).toString();
};

export const Decrypt = (message: any, iv: string, ek: string) => {
  let response: any;
  try {
    let decryptParsedKey = CryptoJS.enc.Base64.parse(getEKValue(ek));
    let decryptParsedIv = CryptoJS.enc.Base64.parse(iv);
    let bytes = CryptoJS.AES.decrypt(message, decryptParsedKey, {
      iv: decryptParsedIv,
    });
    response = JSON.parse(bytes?.toString(CryptoJS.enc.Utf8));
  } catch (e) {
    // in catch the message is decrypted by default keys if any decryptin error occurs
    let defaultEkvalue: any;
    if (localStorage.getItem('IsFinancialTransaction') === 'true') {
      defaultEkvalue = CryptoJS.enc.Base64.parse(getDefaultTKValue());
    } else {
      defaultEkvalue = CryptoJS.enc.Base64.parse(getDefaultDKValue());
    }
    let defaultIvvalue = getDefaultIVValue();
    let decryptParsedIv = CryptoJS.enc.Base64.parse(defaultIvvalue);
    let bytes = CryptoJS.AES.decrypt(message, defaultEkvalue, {
      iv: decryptParsedIv,
    });
    response = JSON.parse(bytes?.toString(CryptoJS.enc.Utf8));
  }
  return response;
};

export const getApiKeyValue = () => {
  let ApiKeyValue = cookies.get('ApiKeyValue');
  return CryptoJS.AES.decrypt(ApiKeyValue, LOCALENCRYPTIONKEY)?.toString(
    CryptoJS.enc.Utf8
  );
};

export const getIVValue = () => {
  let IVKeyValue = cookies.get('IVKeyValue');
  return CryptoJS.AES.decrypt(IVKeyValue, LOCALENCRYPTIONKEY)?.toString(
    CryptoJS.enc.Utf8
  );
};

export const getTKValue = () => {
  let TKKeyValue = cookies.get('TKKeyValue');
  return CryptoJS.AES.decrypt(TKKeyValue, LOCALENCRYPTIONKEY)?.toString(
    CryptoJS.enc.Utf8
  );
};


export const getJWTToken = () => { 
  const userPayloadString: string | null = localStorage.getItem('userPayload');
  let userPayload =
    userPayloadString && userPayloadString !== ''
      ? JSON.parse(userPayloadString)
      : {};

  const returnVal = (userPayload && userPayload.userData && userPayload.userData.JwtToken) ? userPayload.userData.JwtToken : "";
  return returnVal;
}
export const getDKValue = () => {
  let DKKeyValue = cookies.get('DKKeyValue');
  if (!DKKeyValue) {
    DKKeyValue = getDefaultDKValue();
  }
  return CryptoJS.AES.decrypt(DKKeyValue, LOCALENCRYPTIONKEY)?.toString(
    CryptoJS.enc.Utf8
  );
};

export const getDefaultApiKeyValue = () => {
  let ApiKeyKeyValue = cookies.get('DefaultApiKeyValue');
  return CryptoJS.AES.decrypt(ApiKeyKeyValue, LOCALENCRYPTIONKEY)?.toString(
    CryptoJS.enc.Utf8
  );
};

export const getDefaultIVValue = () => {
  let IVKeyValue = cookies.get('DefaultIVKeyValue');
  return CryptoJS.AES.decrypt(IVKeyValue, LOCALENCRYPTIONKEY)?.toString(
    CryptoJS.enc.Utf8
  );
};

export const getDefaultTKValue = () => {
  let TKKeyValue = cookies.get('DefaultTKKeyValue');
  return CryptoJS.AES.decrypt(TKKeyValue, LOCALENCRYPTIONKEY)?.toString(
    CryptoJS.enc.Utf8
  );
};

export const getDefaultDKValue = () => {
  let DKKeyValue = cookies.get('DefaultDKKeyValue');
  return CryptoJS.AES.decrypt(DKKeyValue, LOCALENCRYPTIONKEY)?.toString(
    CryptoJS.enc.Utf8
  );
};

export const swapDefaultKeysAndCrypto = (data: any, message: any) => {
  cookies.set('loggedInUserCrypto', data, {
    path: '/',
    secure: cookiesAreSecure,
    sameSite: 'strict',
  });
  setKeysInCookies(message.ApiKey, message.IV, message.TK, message.DK);
};

export const getDefaultKeys = async (config: any) => {
  localStorage.setItem(
    'REACT_APP_ORCHESTRATION_URL',
    process.env.REACT_APP_ORCHESTRATION_URL ||
      config?.REACT_APP_ORCHESTRATION_URL
  );
  // default keys are added in case of isdefault: true in api response header
  setDefaultKeysInCookies(
    process.env.REACT_APP_DEFAULT_API_KEY || config?.REACT_APP_DEFAULT_API_KEY,
    process.env.REACT_APP_DEFAULT_IV || config?.REACT_APP_DEFAULT_IV,
    process.env.REACT_APP_DEFAULT_TK || config?.REACT_APP_DEFAULT_TK,
    process.env.REACT_APP_DEFAULT_DK || config?.REACT_APP_DEFAULT_DK
  );
  setKeysInCookies(
    process.env.REACT_APP_DEFAULT_API_KEY || config?.REACT_APP_DEFAULT_API_KEY,
    process.env.REACT_APP_DEFAULT_IV || config?.REACT_APP_DEFAULT_IV,
    process.env.REACT_APP_DEFAULT_TK || config?.REACT_APP_DEFAULT_TK,
    process.env.REACT_APP_DEFAULT_DK || config?.REACT_APP_DEFAULT_DK
  );
};

// set rotating crypto keys in cookies
export const setKeysInCookies = (
  ApiKeyValue: string,
  IVKeyValue: string,
  TKKeyValue: string,
  DKKeyValue: string
) => {
  cookies.set(
    'ApiKeyValue',
    CryptoJS.AES.encrypt(ApiKeyValue, LOCALENCRYPTIONKEY).toString(),
    {
      path: '/',
      secure: cookiesAreSecure,
      sameSite: 'strict',
    }
  );
  cookies.set(
    'IVKeyValue',
    CryptoJS.AES.encrypt(IVKeyValue, LOCALENCRYPTIONKEY).toString(),
    {
      path: '/',
      secure: cookiesAreSecure,
      sameSite: 'strict',
    }
  );
  cookies.set(
    'TKKeyValue',
    CryptoJS.AES.encrypt(TKKeyValue, LOCALENCRYPTIONKEY).toString(),
    {
      path: '/',
      secure: cookiesAreSecure,
      sameSite: 'strict',
    }
  );
  cookies.set(
    'DKKeyValue',
    CryptoJS.AES.encrypt(DKKeyValue, LOCALENCRYPTIONKEY).toString(),
    {
      path: '/',
      secure: cookiesAreSecure,
      sameSite: 'strict',
    }
  );
};

// set default crypto keys in cookies
export const setDefaultKeysInCookies = (
  ApiKeyValue: string,
  IVKeyValue: string,
  TKKeyValue: string,
  DKKeyValue: string
) => {
  cookies.set(
    'DefaultApiKeyValue',
    CryptoJS.AES.encrypt(ApiKeyValue, LOCALENCRYPTIONKEY).toString(),
    {
      path: '/',
      secure: cookiesAreSecure,
      sameSite: 'strict',
    }
  );
  cookies.set(
    'DefaultIVKeyValue',
    CryptoJS.AES.encrypt(IVKeyValue, LOCALENCRYPTIONKEY).toString(),
    {
      path: '/',
      secure: cookiesAreSecure,
      sameSite: 'strict',
    }
  );
  cookies.set(
    'DefaultTKKeyValue',
    CryptoJS.AES.encrypt(TKKeyValue, LOCALENCRYPTIONKEY).toString(),
    {
      path: '/',
      secure: cookiesAreSecure,
      sameSite: 'strict',
    }
  );
  cookies.set(
    'DefaultDKKeyValue',
    CryptoJS.AES.encrypt(DKKeyValue, LOCALENCRYPTIONKEY).toString(),
    {
      path: '/',
      secure: cookiesAreSecure,
      sameSite: 'strict',
    }
  );
};

export const getEKValue = (ekValue: string) => {
  return CryptoJS.AES.decrypt(ekValue, LOCALENCRYPTIONKEY)?.toString(
    CryptoJS.enc.Utf8
  );
};
