import React, { MouseEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useStore } from 'lnox';
import { thunkSendVerificationOTPChangePassword } from '../../../store/actions/authActions';
import { useFormik } from 'formik';
import {
  validationSchema1,
  validationSchema2,
} from '../TwoFactorAuthForm/validationSchema';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

interface TwoFactorAuthChangePasswordFormProps {
  onForgotPassword: (event: MouseEvent) => void;
}

const TwoFactorAuthChangePasswordForm: React.FC<
  TwoFactorAuthChangePasswordFormProps
> = ({ onForgotPassword }) => {
  const { dispatch } = useStore();
  const [userEmail, setUserEmail] = useState<string>('');

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const renderEmailAndPhoneInput = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        p: 1,
        m: 1,
        bgcolor: 'background.paper',
        borderRadius: 1,
      }}
    >
      <Box sx={{ height: '50px', width: '50px', margin: 'auto' }}>
        <Box
          component="img"
          sx={{
            marginLeft: '7px',
            cursor: 'pointer',
          }}
          src={'/emailIcon.svg'}
          color="disabled"
        />
        <Typography fontWeight="500" color={''}>
          EMAIL
        </Typography>
      </Box>
    </Box>
  );

  const handleSendVerification = async (formValues: any) => {
    setIsSubmitting(true);
    await dispatch(thunkSendVerificationOTPChangePassword(userEmail));
    setIsSubmitting(false);
  };

  const renderForgotPassword = () => (
    <Box mt={1}>
      <Link
        className="StyleLink"
        underline="hover"
        href="#"
        onClick={onForgotPassword}
      >
        Back to Login
      </Link>
    </Box>
  );

  const formik = useFormik({
    initialValues: { email: userEmail },
    validationSchema: validationSchema1,
    onSubmit: (values) => {
      handleSendVerification(values);
    },
  });

  return (
    <Box className="TwofactAuthBox">
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h6" fontWeight="500">
          Verification Code
        </Typography>
        <Typography color={'#7C919D'}>
          Choose how to receive your verification code.
        </Typography>
        {renderEmailAndPhoneInput()}
        <div>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={userEmail}
            onChange={(e) => {
              setUserEmail(e.target.value);
              formik.handleChange(e);
            }}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </div>
        {renderForgotPassword()}
        <div className="PasswordBox">
          <Button
            color="primary"
            sx={{ padding: '12px' }}
            disabled={isSubmitting}
            variant="contained"
            fullWidth
            type="submit"
          >
            {isSubmitting ? (
              <CircularProgress size={24}></CircularProgress>
            ) : (
              'SEND CODE'
            )}
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default TwoFactorAuthChangePasswordForm;
