import { Box, Button, CircularProgress, Link, Typography } from '@mui/material';
import { useStore } from 'lnox';
import React, { useEffect, useState } from 'react';
import {
  thunkSendVerificationOTPChangePassword,
  thunkVerifyOTPCodeActionChangePassword,
} from '../../../store/actions/authActions';
import OTPInput from '../../OTPInput';
import './AuthVerification.scss';

interface AuthVerificationFormChangePasswordProps {
  userId: string;
  onBackToLogin: (event: any) => void;
}

const AuthVerificationFormChangePassword: React.FC<
  AuthVerificationFormChangePasswordProps
> = ({ userId, onBackToLogin }) => {
  const { dispatch, state: { app: { statusMessages = [] } = {} } = {} } =
    useStore();
  const [otp, setOtp] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isResend, setIsResend] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (statusMessages) {
      statusMessages.map((message: any, index: number) => {
        return setErrorMessage(message.text);
      });
    }
  });

  const renderForgotPassword = () => (
    <Box mt={1}>
      <Link
        className="StyleLink"
        underline="hover"
        href="#"
        onClick={onBackToLogin}
      >
        Back to Login
      </Link>
    </Box>
  );

  let ismobileinterface = false;
  let maskedEmail = '';
  if (userId) {
    let firstChar = userId[0];
    let regex = /[A-Z,a-z,0-9]/gi;
    let encryptedEmail = userId?.split('@')[0]?.replaceAll(regex, '*');
    maskedEmail = `${firstChar}${encryptedEmail?.slice(1)}@${
      userId?.split('@')[1]
    }`;
  }

  const handleOnResend = async () => {
    setIsResend(true);
    await dispatch(thunkSendVerificationOTPChangePassword(userId));
    setIsResend(false);
  };

  const renderOtpResend = () => (
    <Box>
      <Box
        mt={1}
        sx={{
          display: 'flex',
          flex: 'grow',
          gap: '3px',
          alignItems: 'center',
          flexDirection: 'row',
          bgcolor: 'background.paper',
        }}
      >
        <Typography fontSize={'12px'}>Didn't receive a code?</Typography>
        <Link
          className="Link"
          underline="hover"
          href="#"
          onClick={handleOnResend}
        >
          Resend
        </Link>
      </Box>
      {isResend && (
        <CircularProgress
          sx={{ marginLeft: '45%' }}
          size={24}
        ></CircularProgress>
      )}
    </Box>
  );

  const handleVerifyOtp = async () => {
    setIsSubmitting(true);
    await dispatch(
      thunkVerifyOTPCodeActionChangePassword({
        ismobileinterface,
        userid: userId,
        otp,
      })
    );
    setIsSubmitting(false);
  };

  return (
    <Box className="TwofactAuthBox">
      {!errorMessage && (
        <Box>
          <Typography variant="h6" className="OtpHeader">
            Verification Code
          </Typography>
          <Typography color={'#7C919D'}>
            Enter the code that was sent to
          </Typography>
          <Typography>{maskedEmail}</Typography>
        </Box>
      )}
      {errorMessage && (
        <Box>
          <Typography variant="h6" className="OtpHeader">
            Invalid Code
          </Typography>
          <Typography color={'#7C919D'}>Please Try Again</Typography>
        </Box>
      )}
      <Box className="otpBox">
        <OTPInput
          autoFocus
          length={6}
          className="otpContainer"
          inputClassName={errorMessage ? 'InvalidOtpInput' : 'otpInput'}
          onChangeOTP={(otp) => {
            setOtp(otp);
          }}
          onPaste={(otp) => {
            setOtp(otp);
          }}
        />
      </Box>
      
      {renderOtpResend()}

      {renderForgotPassword()}
      <div className="PasswordBox">
        <Button
          color="primary"
          sx={{
            padding: '16px',
            marginTop: errorMessage ? '40px' : '30px',
            fontSize: '18px',
            fontWeight: 600,
          }}
          disabled={isSubmitting}
          variant="contained"
          fullWidth
          onClick={handleVerifyOtp}
        >
          {isSubmitting ? (
            <CircularProgress size={26}></CircularProgress>
          ) : errorMessage ? (
            'Try Again'
          ) : (
            'VERIFY'
          )}
        </Button>
      </div>
    </Box>
  );
};

export default AuthVerificationFormChangePassword;
