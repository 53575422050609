/**
 * Transaction Check Details
 */
import { Close, Done } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useCreditCardTypeDetection from '../../Hooks/useCreditCardTypeDetection';
import { DATE1_FORMAT, TIME_FORMAT } from '../../store/constants';
import { VirtualTerminalData } from '../../types/VirtualTerminalPage.types';
import { toTitleCase } from '../../libs/helpers';
import './TransactionCheck.scss';

interface TransactionCheckProps {
  transactionRequest: VirtualTerminalData;
  transactionResponse?: any;
  transactionTypeLabel: string;
}

const TransactionCheck: React.FC<TransactionCheckProps> = ({
  transactionRequest,
  transactionResponse,
  transactionTypeLabel,
}) => {
  const [scrollPosition, setScrollPosition] = useState<boolean>(false);
  const {
    cardInfo: { number },
    transactionType,
  } = transactionRequest?.paymentInfo;

  const { billingFullName } = transactionRequest?.billingInfo;
  const { label: creditCardLabel } = useCreditCardTypeDetection(number || '');
  const transactionApproved = transactionResponse?.Approved === true;
  const transactionDeclined = transactionResponse?.Approved === false;
  let maskedCardNumber = '';
  if (number) {
    const ccNumberLength = number.length;
    let maskedPart = number
      .substring(0, ccNumberLength - 4)
      .replace(/\d/g, '*');
    maskedCardNumber = `${maskedPart.trim()}${number.substring(
      maskedPart.length - 1
    )}`;
  }

  // Bellow two Effects are for scroll Position
  // when transaction is approved or decline it showed to bottom that's why it is implemented that way
  useEffect(() => {
    if (transactionResponse) {
      if (transactionDeclined || transactionApproved) {
        setScrollPosition(true);
      } else {
        setScrollPosition(false);
      }
    }
    return () => {
      setScrollPosition(false);
    };
  }, [transactionDeclined, transactionApproved, transactionResponse]);

  useEffect(() => {
    if (scrollPosition) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [scrollPosition]);

  return (
    <Box className="TransactionCheckContainer">
      <Box className="SectionBoxStyle">
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">
              Merchant Name:
            </Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small"></Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">
              Merchant Address:
            </Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small"></Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">
              Merchant City, State, Zip Code:
            </Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small"></Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">
              Merchant Phone:
            </Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small"></Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="SectionBoxStyle">
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">
              Transaction Date:
            </Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small">
                {transactionResponse && transactionResponse?.Timestamp
                  ? moment(transactionResponse?.Timestamp).format(DATE1_FORMAT)
                  : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">
              Transaction Time:
            </Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small">
                {transactionResponse && transactionResponse?.Timestamp
                  ? moment(transactionResponse?.Timestamp).format(TIME_FORMAT)
                  : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">
              Transaction ID:
            </Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small">
                {transactionResponse &&
                transactionResponse?.TerminalTransactionId
                  ? transactionResponse?.TerminalTransactionId
                  : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="SectionBoxStyle">
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">
              Card Brand:
            </Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small">
                {number ? creditCardLabel : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">
              Transaction Type:
            </Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small">
                {transactionTypeLabel ? toTitleCase(transactionTypeLabel) : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">
              Entry Mode:
            </Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small">
                {transactionResponse?.EntryMethod
                  ? toTitleCase(transactionResponse?.EntryMethod)
                  : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="SectionBoxStyle" flexDirection="row">
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">
              Cardholder Name:
            </Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small">
                {billingFullName ? billingFullName : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">
              Account #:
            </Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small">
                {maskedCardNumber ? maskedCardNumber : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">
              Authorization code:
            </Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small">
                {transactionResponse?.AuthCode
                  ? transactionResponse?.AuthCode
                  : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">
              Response code:
            </Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small">
                {transactionResponse?.ResponseCode
                  ? transactionResponse?.ResponseCode
                  : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">AVS:</Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small">
                {transactionResponse?.AVSResultCode
                  ? transactionResponse?.AVSResultCode
                  : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="inline-flex" width="100%">
            <Typography className="TransactionCheckLabelStyle">CVV:</Typography>
            <Box textAlign="end" justifySelf="end" width="100%">
              <Typography fontSize="small">
                {transactionResponse?.CVVResultCode
                  ? transactionResponse?.CVVResultCode
                  : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="AmountSectionBoxStyle">
        <Typography fontSize="small" className="TransactionCheckLabelStyle">
          Amount:
        </Typography>
        <Box textAlign="center">
          <Typography fontSize="xx-large">
            {transactionRequest?.money?.value
              ? transactionRequest?.money?.value
              : transactionType && transactionType === 'Void'
              ? `$${Number(transactionResponse?.RequestedAmount)?.toFixed(2)}`
              : '$0.00'}
          </Typography>
        </Box>
      </Box>

      {transactionResponse && transactionResponse?.Approved && (
        <Box
          textAlign="center"
          display="-webkit-inline-flex"
          justifyContent="center"
          width="100%"
          sx={{ marginTop: '2rem' }}
        >
          <Done className="ApprovedIconStyle" />
          <Typography className="ApprovedTextStyle">Approved</Typography>
        </Box>
      )}

      {transactionResponse &&
        !transactionResponse?.Approved &&
        transactionResponse?.TerminalTransactionId && (
          <Box
            textAlign="center"
            display="-webkit-inline-flex"
            justifyContent="center"
            width="100%"
            sx={{ marginTop: '2rem' }}
          >
            <Close className="DeclinedIconStyle" />
            <Typography className="DeclinedTextStyle">Declined</Typography>
          </Box>
        )}
    </Box>
  );
};

export default TransactionCheck;
