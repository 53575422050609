import {
  Box,
  BoxProps,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Close } from '@mui/icons-material';
import { ChangeEvent, useState, KeyboardEvent, useEffect } from 'react';

interface SearchProps {
  value: any;
  width?: string;
  label?: string;
  filter?: boolean;
  advancedSearch?: boolean;
  onChange: (e: ChangeEvent<any>) => void;
  onSearch?: (searchTerm: string) => void;
  onReset?: () => void;
}

const BatchSearchBox: React.FC<SearchProps & BoxProps> = ({
  onSearch,
  onChange,
  onReset,
  value,
}) => {
  const [showClose, setShowClose] = useState<boolean>(false);

  const handleChange = () => {
    setShowClose(true);
  };

  useEffect(() => {
    if (showClose && value === '') {
      onReset !== undefined && onReset();
      setShowClose(false);
    }
  }, [onReset, showClose, value]);

  return (
    <Box width="100%">
      <TextField
        placeholder="Search"
        className="TransactionSearchBox"
        onChange={(e) => {
          onChange(e);
          handleChange();
        }}
        value={value}
        onKeyDown={(e: KeyboardEvent<any>) => {
          if (e.keyCode === 13 && onSearch !== undefined) {
            // setSearchApplied(Boolean(value));
            onSearch(value);
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              {showClose && (
                <IconButton
                  onClick={() => {
                    setShowClose(false);
                    onReset && onReset();
                  }}
                >
                  <Close color={'primary'} />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default BatchSearchBox;
