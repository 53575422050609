import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import validationSchema from './validationSchema';
import { isPossibleNumber } from 'libphonenumber-js';


interface MerchantApplicationFormValues {
  Name: string;
  FirstName: string;
  LastName: string;
  PhoneNumber: string;
  EmailAddress: string;
}

interface MerchantApplicationFormProps {
  onChange: (values: MerchantApplicationFormValues) => void;
  onMerchantAppTitleChange: (title: string) => void;
  setIsMerchantAppFormValid?: (value: boolean) => void;
}

const MerchantApplicationForm: React.FC<MerchantApplicationFormProps> = ({
  onChange,
  onMerchantAppTitleChange,
  setIsMerchantAppFormValid,
}) => {
  const initialValues: MerchantApplicationFormValues = {
    Name: '',
    FirstName: '',
    LastName: '',
    PhoneNumber: '',
    EmailAddress: '',
  };
  const [merchantApplicationvalues, setMerchantApplicationvalues] =
    useState<MerchantApplicationFormValues>(initialValues);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>('+1');
  const [formValueChanged, setFormValueChanged] = useState<boolean>(false);
  const [addressCountry, setAddressCountry] = useState<string>("");

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (fromValues: any) => { },
    validateOnChange: true,
  });

  useEffect(() => {
    if (formValueChanged) {
      setIsMerchantAppFormValid &&
        setIsMerchantAppFormValid(
          (formik.errors && Object.keys(formik.errors).length) ||
            phoneNumberError
            ? false
            : true
        );
    }
  }, [formik.errors, phoneNumberError, formValueChanged]);

  const handleFormOnChange = () => {
    onChange && onChange(merchantApplicationvalues);
    setFormValueChanged(true);
  };

  const handlePhoneChange = (value: any, data: any,) => {
    if (value.charAt(0) != 1 && addressCountry == '' && value.length > 3) { value = "+1" + value }
    setPhoneNumberValue(value)
    setAddressCountry(data.countryCode.toUpperCase())
    formik.setFieldValue('PhoneNumber', value);
    setMerchantApplicationvalues((prevState) => ({
      ...prevState,
      PhoneNumber: value,
    }));
    handleFormOnChange();
  };

  const validatePhoneNumber = (value: any, country: any) => {
    if (isPossibleNumber(value, country)) {
      setPhoneNumberError(false)
    } else {
      setPhoneNumberError(true);
    }
  };

  return (
    <Box>
      <form>
        <Box className="SectionBox">
          <Typography
            fontWeight="bold"
            fontSize="large"
            className="HeadingText"
          >
            Start Application
          </Typography>
          <Box>
            <Typography fontSize="12px" color="#7C919D">
              Please complete the fields below to submit your application.
            </Typography>
          </Box>
          <Box className="FirstRow">
            <FormControl fullWidth className="MerchantFormField">
              <TextField
                id={'Name'}
                name={'Name'}
                label="Merchant Name (DBA or Trade Name)"
                disabled={false}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('Name', value);
                  onMerchantAppTitleChange && onMerchantAppTitleChange(value);
                  setMerchantApplicationvalues((prevState) => ({
                    ...prevState,
                    Name: value,
                  }));
                  handleFormOnChange();
                }}
                onBlur={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('Name', value);
                  formik.setFieldTouched('Name');
                  onMerchantAppTitleChange && onMerchantAppTitleChange(value);
                  setMerchantApplicationvalues((prevState) => ({
                    ...prevState,
                    Name: value,
                  }));
                  handleFormOnChange();
                }}
                error={formik.touched.Name && formik.errors.Name ? true : false}
              />
              {formik.touched.Name && formik.errors.Name && (
                <FormHelperText className="FormHelperTextStyle">
                  {formik.touched.Name && formik.errors.Name
                    ? formik.errors.Name
                    : ''}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box className="UserRow">
            <FormControl fullWidth>
              <TextField
                id={'FirstName'}
                name={'FirstName'}
                label="First Name"
                className="FirstNameStyle"
                disabled={false}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('FirstName', value);
                  setMerchantApplicationvalues((prevState) => ({
                    ...prevState,
                    FirstName: value,
                  }));
                  handleFormOnChange();
                }}
                onBlur={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('FirstName', value);
                  formik.setFieldTouched('FirstName');
                  setMerchantApplicationvalues((prevState) => ({
                    ...prevState,
                    FirstName: value,
                  }));
                  handleFormOnChange();
                }}
                error={
                  formik.touched.FirstName && formik.errors.FirstName
                    ? true
                    : false
                }
              />
              {formik.touched.FirstName && formik.errors.FirstName && (
                <FormHelperText className="FormHelperTextStyle">
                  {formik.touched.FirstName && formik.errors.FirstName
                    ? formik.errors.FirstName
                    : ''}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl id={'LastName'} fullWidth>
              <TextField
                id={'LastName'}
                name={'LastName'}
                label="Last Name"
                className="LastNameStyle"
                disabled={false}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('LastName', value);
                  setMerchantApplicationvalues((prevState) => ({
                    ...prevState,
                    LastName: value,
                  }));
                  handleFormOnChange();
                }}
                onBlur={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('LastName', value);
                  formik.setFieldTouched('LastName');
                  setMerchantApplicationvalues((prevState) => ({
                    ...prevState,
                    LastName: value,
                  }));
                  handleFormOnChange();
                }}
                error={
                  formik.touched.LastName && formik.errors.LastName
                    ? true
                    : false
                }
              />
              {formik.touched.LastName && formik.errors.LastName && (
                <FormHelperText className="FormHelperTextStyleLastName">
                  {formik.touched.LastName && formik.errors.LastName
                    ? formik.errors.LastName
                    : ''}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box className="UserRow">
            <FormControl fullWidth className="FieldLabelStyle">
              <TextField
                className="FirstNameStyle"
                fullWidth
                id={'EmailAddress'}
                name={'EmailAddress'}
                label="Primary Contact Email"
                disabled={false}
                onChange={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('EmailAddress', value);
                  setMerchantApplicationvalues((prevState) => ({
                    ...prevState,
                    EmailAddress: value,
                  }));
                  handleFormOnChange();
                }}
                onBlur={(e: any) => {
                  const {
                    target: { value },
                  } = e;
                  formik.setFieldValue('EmailAddress', value);
                  formik.setFieldTouched('EmailAddress');
                  setMerchantApplicationvalues((prevState) => ({
                    ...prevState,
                    EmailAddress: value,
                  }));
                  handleFormOnChange();
                }}
                error={
                  formik.touched.EmailAddress && formik.errors.EmailAddress
                    ? true
                    : false
                }
              />
              {formik.touched.EmailAddress && formik.errors.EmailAddress && (
                <FormHelperText className="FormHelperTextStyle">
                  {formik.touched.EmailAddress && formik.errors.EmailAddress
                    ? formik.errors.EmailAddress
                    : ''}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth className="FieldLabelStyle">
              <PhoneInput
                specialLabel="Phone Number"
                inputProps={{
                  name: 'PhoneNumber',
                }}
                country={'us'}
                placeholder={'Phone Number'}
                value={phoneNumberValue}
                isValid={!phoneNumberError}
                onChange={handlePhoneChange}
                inputStyle={{
                  borderColor:
                    formik.touched && formik.errors.PhoneNumber && 'red',
                  width: '96%',
                  height: '95%',
                  marginRight: '0px',
                  borderRadius: '10px',
                }}
                onBlur={() => {
                  validatePhoneNumber(phoneNumberValue, addressCountry);
                }}
              />
              {phoneNumberError && (
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {phoneNumberError ? 'Phone Number is not valid' : ''}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <FormControlLabel
            sx={{ color: '#36454F' }}
            control={<Checkbox className="CheckBox" size="small" />}
            onChange={() => { }}
            label={'Gateway Client'}
            // name={value}
            checked={true}
          />
        </Box>
      </form>
    </Box>
  );
};

export default MerchantApplicationForm;
