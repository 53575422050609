/**
 * Reducer class for app
 */
import { ReducerAction, ReducerState } from '../../../types/common';
import {
  APP_SET_USER,
  APP_SIDEBAR_TOGGLE,
  APP_SET_SCREEN_TITLE,
  APP_SET_STATUS_MESSAGE,
  APP_UPDATE_USER_SETTINGS,
  APP_CLEAR_STATUS_MESSAGES,
  APP_REMOVE_STATUS_MESSAGE,
  APP_SET_USER_LOGGED_IN,
} from '../../actions/types';

const defaultState = {
  statusMessages: [],
  screenTitle: '',
  compactSidebar: false,
  user: {},
  isLoggedIn: false,
};

/**
 * Application reducer
 */
const AppReducer = (
  state: ReducerState = defaultState,
  action: ReducerAction
) => {
  const { type, payload } = action;
  switch (type) {
    case APP_SET_SCREEN_TITLE:
      return { ...state, screenTitle: payload };
    case APP_SIDEBAR_TOGGLE:
      return { ...state, compactSidebar: payload };
    case APP_SET_USER:
      return { ...state, user: payload };
    case APP_UPDATE_USER_SETTINGS:
      return {
        ...state,
        user: { settings: { ...state.user.settings, ...payload } },
      };
    case APP_SET_STATUS_MESSAGE:
      return { ...state, statusMessages: [...state.statusMessages, payload] };
    case APP_CLEAR_STATUS_MESSAGES:
      return { ...state, statusMessages: [] };
    case APP_REMOVE_STATUS_MESSAGE: {
      const filteredMessages = state.statusMessages.filter(
        (message: any) => message.text.indexOf(payload) < 0
      );
      return { ...state, statusMessages: filteredMessages };
    }
    case APP_SET_USER_LOGGED_IN:
      return { ...state, isLoggedIn: payload.isLoggedIn };
    default:
      return state;
  }
};

export default AppReducer;
